<template>
  <div class="roulettes" :key="getCount" :class="{'prepare': status === 'prepare'}" :style="{'grid-template-columns': `repeat(${getCount}, minmax(0,1fr))`}">

      <UnauthorizationCase v-if="!$root.isAuth" />
      <InfuncientInfo v-else-if="infunsientBalance && status === 'wait'" />
      <RouletteInstance v-for="roulette in getCount" :index="roulette" :key="roulette" />
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {

  computed: {
    ...mapGetters({
      count: 'open/count',
      status: 'open/status',
      caseInstance: 'open/caseInstance'
    }),
    infunsientBalance () {
      return parseFloat(this.$root.user.balance) < parseFloat(this.caseInstance.price)
    },
    getCount () {
      if (this.status === 'prepare') {
        return this.count
      }

      return 1
    }
  }
}
</script>
<style scoped lang="scss">
  .roulettes {
    background: #141518;
    border-radius: 6px;
    margin-bottom: 15px;
    max-width: 100%;
    display: grid;
    position: relative;
    grid-gap: 30px;
    @media (max-width: 1400px) {
      grid-gap: 20px;
    }
    @media (max-width: 1200px) {
      grid-gap: 5px;
    }
    @media (max-width: 768px) {
      &.prepare {
        grid-template-columns: repeat(1, minmax(0,1fr)) !important;
      }
    }

  }
</style>
<script setup>
import RouletteInstance from "@/components/open-case/RouletteInstance.vue";
import InfuncientInfo from "@/components/open-case/InfuncientInfo.vue";
import UnauthorizationCase from "@/components/open-case/UnauthorizationCase.vue";
</script>