<template>
  <div class="inventory-item" :class="[hexToColor(skin.color)]">
    <div class="inventory-item__body">

      <div class="price" v-if="!hidePrice">${{ parseFloat(skin.price).toFixed(2) }}</div>
      <div class="image">
        <img :src="skin.getImage + '/142fx106f'" alt="">
      </div>
      <div class="info" v-if="!hideInfo">
        <strong>{{ getNameSkin(skin.name) }}</strong>
        <span><span class="inventory-item__quality" v-if="skin.quality">({{ skin.quality }})</span> {{ getQualitySkin(skin.name) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import hexToColor from "@/filters/hexToColor";
import getNameSkin from "../helpers/getNameSkin";
import getQualitySkin from "../helpers/getQualitySkin";
export default {
  methods: {getNameSkin, getQualitySkin},
  data () {
    return {
      hexToColor
    }
  },
  props: {
    hideInfo: {
      type: Boolean,
      default: false
    },
    hidePrice: {
      type: Boolean,
      default: false
    },
    skin: {
      type: Object,
      required: true
    },
  },
}
</script>
<style scoped lang="scss">
.inventory-item {
  height: 199px;
  background: url(@/assets/images/inventory-bg.png);
  background-size: 80% 90%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  .inventory-item__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .price {
      position: absolute;
      top: 14px;
      right: 14px;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      color: #00B876;
      z-index: 3;
    }
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 106px;
      width: 100%;
      padding: 0px 24px;
      margin-top: 32px;
      img {
        width: 90px;
        position: relative;
        z-index: 2;
        @media (max-width: 769px) {
          width: 84px;
          height: auto;
        }
      }
      &::after {
        width: 0px;
        height: 0px;
        content: "";
        display: block;
        position: absolute;
        top: 40%;
        left: calc(50% - 5px);
        border-radius: 100%;
        background: transparent;
        box-shadow: 0px 0px 27px 16px rgb(235 76 76 / 70%);
      }
    }
    .info {
      margin-top: 10px;

      strong, span {
        width: 100%;
        text-align: center;
        display: block;
      }
      strong {
        position: relative;
        z-index: 3;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #8847FF;
      }
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        color: #b1b1b1;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .inventory-item__quality {
          display: contents;
          color: #646465;
        }
      }
    }
    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: calc(50% - 10px);
      background: #8847FF;
    }
  }
  &.pink {
    .inventory-item__body {
      &::after {
        background: #D32CE6;
      }
      background: linear-gradient(360deg, rgba(211, 44, 230, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      .info {
        strong {
          color: #D32CE6
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(211, 44, 230, 0.7);
        }
      }
    }
  }
  &.grey {
    .inventory-item__body {
      background: linear-gradient(360deg, rgba(176, 194, 217, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      &::after {
        background: #B0C2D9;
      }
      .info {
        strong {
          color: #B0C3D9
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(176, 195, 217, 0.7);
        }
      }
    }
  }
  &.light-blue {
    .inventory-item__body {
      background: linear-gradient(360deg, rgba(114, 200, 216, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      &::after {
        background: #5E98D9;
      }
      .info {
        strong {
          color: #72C8D8
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(114, 200, 216, 0.7);
        }
      }
    }
  }
  &.red {
    .inventory-item__body {
      background: linear-gradient(360deg, rgba(235, 76, 76, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      &::after {
        background: #EB4C4C;
      }
      .info {
        strong {
          color: #EB4C4C
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(235, 76, 76, 0.7);
        }
      }
    }

  }
  &.yellow {
    .inventory-item__body {
      background: linear-gradient(360deg, rgba(199, 172, 76, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      &::after {
        background: #C7AC4C;
      }
      .info {
        strong {
          color: #C7AC4C
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(199, 172, 76, 0.7);
        }
      }
    }

  }
  &.blue {
    .inventory-item__body {
      background: linear-gradient(360deg, rgba(94, 152, 217, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      &::after {
        background: #5E98D9;
      }
      .info {
        strong {
          color: #5E98D9
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(94, 152, 217, 0.7);
        }
      }
    }

  }
  &.purple {
    .inventory-item__body {
      background: linear-gradient(360deg, rgba(136, 71, 255, 0.1) 0%, rgba(20, 21, 24, 0) 100%);
      &::after {
        background: #8847FF;
      }
      .info {
        strong {
          color: #8847FF
        }
      }
      .image {
        &::after {
          box-shadow: 0px 0px 27px 16px rgba(136, 71, 255, 0.7);
        }
      }
    }

  }
}
</style>