<template>
  <div class="upgrades">
    <EmptyInventory title="Нет апгрейдов" subtitle="Начните апгрейдить скины" link="/upgrade" linktext="Улучшить" v-if="!upgrades || !upgrades.items.length" :is-public="false"></EmptyInventory>
    <div class="profile-container" v-else>
      <div class="upgrades-wrapper">
        <div class="upgrade-item" v-for="upgrade in upgrades.items" :key="upgrade.id">
          <div class="upgrade-item__top">
            <div class="bet">
              <div class="head">
                Ваша ставка
              </div>
              <BaseSkin v-if="upgrade.type === 'skins'" :skin="{internal_id: upgrade.items[0].open.id, ...upgrade.items[0].open.item}" />
              <span v-else class="balance">Ваша ставка <br>{{ parseFloat(upgrade.sum).toFixed(2) }}$</span>
            </div>
            <div class="separator">
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3556 21.4686H19.6738V10.1257H23.6441L16.4844 1.50293L9.35589 10.1257L13.3556 10.1863V21.4704V21.4686ZM21.4555 15.8777V17.2837C24.5167 15.7807 28.236 12.6606 29.7094 8.87385C31.1795 5.09402 27.4291 3.31233 27.4291 3.31233C29.3909 7.97001 21.4555 15.8777 21.4555 15.8777ZM30.7674 9.99931C27.5312 15.824 21.4555 18.1684 21.4555 18.1684V19.4774C27.1728 17.4932 30.0021 14.8838 30.9873 12.8216C31.969 10.7629 30.7674 9.99931 30.7674 9.99931ZM21.4555 20.2497V21.2661C26.2984 20.596 28.7796 18.8437 30.0679 17.6248C31.3492 16.4041 30.4436 15.1054 30.4436 15.1054C26.1565 19.609 21.4555 20.2479 21.4555 20.2479V20.2497ZM16.6887 29.1253C25.876 29.1253 29.4272 19.7839 29.4272 19.7839C25.05 22.1578 21.4555 22.1578 21.4555 22.1578C19.9716 26.6077 16.6887 29.1253 16.6887 29.1253ZM19.7015 22.2045H13.347C13.347 22.2045 14.8291 27.0302 16.3961 27.0302C17.9527 27.0302 19.7032 22.2045 19.7032 22.2045H19.7015ZM11.5462 17.2837V15.8777C11.5462 15.8777 3.60737 7.97174 5.57433 3.31233C5.57433 3.31233 1.82395 5.09402 3.29397 8.87385C4.76054 12.6537 8.47457 15.7825 11.5427 17.2837H11.5462ZM11.5462 19.4844V18.1736C11.5462 18.1736 5.46698 15.8292 2.23257 10.0045C2.23257 10.0045 1.0292 10.7698 2.01095 12.8337C2.99269 14.8907 5.82194 17.4984 11.5462 19.4844ZM11.5462 20.2497C11.5462 20.2497 6.84524 19.609 2.56329 15.1072C2.56329 15.1072 1.65426 16.4041 2.93902 17.6248C4.22032 18.8437 6.70845 20.5908 11.5514 21.2661V20.2497H11.5462ZM11.5462 22.1578C11.5462 22.1578 7.95166 22.1578 3.57274 19.7839C3.57274 19.7839 7.12055 29.1253 16.313 29.1253C16.313 29.1253 13.0301 26.6077 11.5462 22.1578Z" fill="#D6D6E7"/>
                </g>
              </svg>
            </div>
            <div class="prize">
              <div class="head">
                апгрейд
              </div>
              <BaseSkin :skin="{price: upgrade.open.price, internal_id: upgrade.open.id, ...upgrade.open.item}" />
            </div>
          </div>
          <div class="upgrade-item__bottom">
            <div class="text">
              <strong>{{ parseFloat(upgrade.percent / 100).toFixed(2) }}%</strong>
              <span>шанс</span>
            </div>
            <div class="status" :class="{'win': upgrade.isWin}">
              <template v-if="!upgrade.isWin">
                проигрыш
              </template>
              <template v-else>
                выигрыш
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="inventory__load" @click="loadMore" :class="{'hide': !showMoreEnabled}">
        Показать ещё
        <img src="@/assets/icons/load.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import BaseSkin from "@/components/BaseSkin.vue";
import EmptyInventory from "@/components/profile/EmptyInventory.vue";

export default {
  components: {EmptyInventory, BaseSkin},
  methods: {
    ...mapActions({
      getUpgrades: 'upgrades/getUpgrades',
      loadMore: 'upgrades/loadMore',
    })
  },
  computed: {
    ...mapGetters({
      showMoreEnabled: 'upgrades/showMoreEnabled',
      upgrades: 'upgrades/upgrades'
    })
  },
  mounted() {
    this.getUpgrades()
  }
}
</script>
<style scoped lang="scss">
.upgrades {
  .profile-container {
    .upgrades-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      @media (max-width: 1100px) {
        grid-gap: 15px;
      }
      @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .upgrade-item {
        position: relative;
        background: #17181C;
        border-radius: 10px;
        padding: 15px 17px;
        .upgrade-item__top {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 30px;
          @media (max-width: 575px) {
            grid-gap: 15px;
          }
          .bet {
            .balance {
              width: 100%;
              display: flex;
              text-align: center;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
          }
          .head {
            margin-bottom: 12px;
            text-align: center;
            width: 100%;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            text-transform: uppercase;
            color: #C3D3DE;
          }
          .separator {
            top: calc(50% - 25px);
            position: absolute;
            left: calc(50% - 25px);
            width: 50px;
            height: 50px;
            background: rgba(147, 147, 147, 0.04);
            border: 1px solid rgba(111, 111, 111, 0.17);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          ::v-deep {
            .inventory-item {
              .inventory-item__body {
                @media (max-width: 575px) {
                  .info {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }
        .upgrade-item__bottom {
          background: #1B1D21;
          border-radius: 10px;
          margin-top: 18px;
          width: 100%;
          padding: 9px 18px;
          align-items: center;
          display: flex;
          justify-content: space-between;
          .text {
            strong {
              font-weight: 600;
              font-size: 20px;
              line-height: 18px;
              display: block;
              width: 100%;
              color: #C2AC5B;
            }
            span {
              font-weight: 300;
              font-size: 13px;
              line-height: 16px;
              text-transform: uppercase;
              color: #717380;
              display: block;
            }
          }
          .status {
            background: #B75353;
            border-radius: 10px;
            padding: 5px 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            text-transform: uppercase;
            color: #1B1D21;
            &.win {
              background: #00B876;
            }
          }
        }
      }
    }

    .inventory__load {
      background: rgba(36, 39, 44, 0.8);
      border-radius: 6px;
      padding: 18px 23px;
      display: flex;
      align-items: center;
      font-weight: 800;
      cursor: pointer;
      font-size: 16px;
      margin: 0 auto;
      width: fit-content;
      transition: 0.2s;
      line-height: 22px;
      margin-top: 30px;
      img {
        margin-left: 15px;
      }
      &:hover {
        background: #24272C;
      }
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
</style>