import { apolloClient } from '@/vue-apollo'
import {GET_CASE} from "@/queries";

export default {
    namespaced: true,
    state: {
        count: 1,
        status: 'wait', // wait, process, finish,
        prizes: [],
        isFast: false,
        caseInstance: null
    },
    getters: {
        count: state => state.count,
        status: state => state.status,
        prizes: state => state.prizes,
        isFast: state => state.isFast,
        caseInstance: state => state.caseInstance
    },
    mutations: {
        setCount(state, count) {
            state.count = count
        },
        setStatus (state, status) {
            state.status = status
        },
        addPrize (state, prize) {
            state.prizes.push(prize)
        },
        removePrize(state, prizeId) {
            //console.log('rm', prizeId)
            const index = state.prizes.findIndex(item => item.id === prizeId)
            if (index > -1) {
                state.prizes.splice(index, 1)
            }
        },
        resetGame (state) {
            state.count = 1
            state.status = 'wait'
            state.prizes = []
            state.isFast = false
        },
        setFast (state, value) {
            state.isFast = value
        },
        setCase (state, caseInstance) {
            state.caseInstance = caseInstance
        }
    },
    actions: {
        async loadCaseData ({commit}, slug) {
            const { data } = await apolloClient.query({ query: GET_CASE, variables: { slug } })

            if (data.case) {
                await commit('setCase', data.case)
            }
        },
    },
}