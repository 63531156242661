<template>
    <div class="modal">
        <div class="modal__body">
            <div class="body">
                <h1>Вы точно хотите продать?</h1>
                <button class="button-cancel" @click="cancel">
                    Отменить
                </button>
                <button class="button-confirm" @click="confirm">
                    Подтвердить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        cancel() {
            this.$emit('cancel:modal');
        },
        confirm() {
            this.$emit('confirm:modal', this.items);
        },
    },
};
</script>

<style scoped lang="scss">
.modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: rgba(18,19,21,.8);
    &__body {
        background: #151821;
        border-radius: 15px;
        padding: 20px;
        max-width: 95%;
        overflow: hidden;
        text-align: center;
    }
}
.body {
    background: rgba(30,33,44,.43);
    border-radius: 15px;
    padding: 44px 41px;
    width: 928px;
    position: relative;
    max-width: 100%;
    min-width: 928px;
    @media (max-width: 992px) {
        min-width: 100%;
    }
}

h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: 'Inter', sans-serif;
    margin: 0 0 25px 0;
    @media (max-width: 575px) {
        font-size: 20px;
    }
}
.button {
    border-radius: 10px;
    margin-top: 32px;
    padding: 18.5px 0;
    width: 100%;
    transition: 0.2s;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    font-family: 'Inter', sans-serif;
    &-confirm {
      background: #137B2A;
      padding: 18px 32px;
      border-radius: 6px;
      font-weight: 600;
      font-size: 16px;
      margin-left: 15px;
        &:hover {
            background: #3F8D50;
        }
    }
    &-cancel {
      background: #D06538;
      padding: 18px 32px;
      border-radius: 6px;
      font-weight: 600;
      font-size: 16px;
    }
}
</style>
