<template>
  <div class="pagination">
    <div class="pagination-item" @click="prev">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292893 7.29289C-0.0976309 7.68342 -0.097631 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538408 7.04738 0.538408 6.65685 0.928933L0.292893 7.29289ZM2 7L1 7L1 9L2 9L2 7Z" fill="#484B59"/>
      </svg>
    </div>
    <div class="pagination-item" @click="setActivePage(page)" :class="{'active': activePage === page}" v-for="(page, i) in getPages" :key="i">{{ page }}</div>
    <div class="pagination-item" @click="next">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928931C1.95262 0.538407 1.31946 0.538407 0.928933 0.928931C0.538409 1.31946 0.538409 1.95262 0.928933 2.34314L6.58579 8L0.928931 13.6569C0.538407 14.0474 0.538407 14.6805 0.928931 15.0711C1.31946 15.4616 1.95262 15.4616 2.34314 15.0711L8.70711 8.70711ZM7 9L8 9L8 7L7 7L7 9Z" fill="#484B59"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activePage: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
  },
  methods: {
    setActivePage (page) {
      if(page !== '...') {
        // eslint-disable-next-line vue/no-mutating-props
        this.activePage = page
      }
    },
    prev () {
      if (this.activePage > 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.activePage--
      }
    },
    next () {
      if (this.activePage + 1 < Math.ceil(this.total / 42)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.activePage++
      }
    }
  },
  watch: {
    activePage () {
      this.$emit('changePage', this.activePage)
      this.$nextTick(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      })
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.activePage === 0 || this.activePage === 1) {
        return 1;
      }

      // When on the last page
      if (this.activePage === Math.ceil(this.total / 42)) {
        return Math.ceil(this.total / 42) - this.maxVisibleButtons;
      }

      // When inbetween
      return this.activePage - 1;
    },
    getPages () {
      const range = [];

      if(this.startPage <= 3) {
        for(let i = 1; i <= this.maxVisibleButtons + 2; i++) {
          range.push(i);
        }
      }
      if(this.activePage >= 5) {
        range.push(1);
        range.push('...');
        if(this.activePage >= Math.ceil(this.total / 42) - this.maxVisibleButtons) {
          for (let i = Math.ceil(this.total / 42) - this.maxVisibleButtons - 1; i <= Math.ceil(this.total / 42); i++) {
            range.push(i);
          }
        } else {
          for (let i = this.activePage - 2; i <= this.activePage + this.maxVisibleButtons - 1; i++) {
            range.push(i);
          }
        }
      }
      if(this.activePage < Math.ceil(this.total / 42) - this.maxVisibleButtons) {
        range.push('...');
        range.push(Math.ceil(this.total / 42));
      }

      return range;
    }
  }
}
</script>
<style scoped lang="scss">
.pagination {
  display: flex;
  grid-row-gap: 15px;
  padding: 0 50px;
  margin-top: 38px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .pagination-item {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #53586C;
    background: #1D1E23;
    border-radius: 4px;
    cursor: pointer;
    &.active:not(.disabled), &:hover:not(.disabled) {
      background: #FE5E3B;
      color: #fff;
    }
    &.disabled {
      cursor: default;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
  .pagination-item + .pagination-item {
    margin-left: 10px;
  }
}
</style>
