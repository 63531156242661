<template>
  <div class="shop-inventory k">
    <UpgradeInventoryFilters :filters="filters" @search="search" title="Скины" @toggleSort="toggleSort" />

    <div v-if="$root.isAuth" class="items__wrapper">

      <transition name="fade">
        <LoaderBlock v-if="isLoading" />
      </transition>

      <div v-if="!isLoading && (!shop || !shop.items.length)" class="not-found">
        ПО ДАННОМУ ЗАПРОСУ ПРЕДМЕТОВ НЕ НАЙДЕНО
      </div>

      <div v-else-if="shop" class="items">
        <UpgradeItem :target-skin="secondSkin" @select="setSecondSkin" v-for="skin in shop.items" :key="skin.id" :skin="{internal_id: skin.id, ...skin}" />
      </div>

      <div class="inventory__wrapper-pagination" v-if="allPages > filters.page">
        <div class="prev" @click="goPrev" :class="{'disabled': filters.page === 0}">Назад</div>
        <div class="counter">
          {{ filters.page + 1 }}/{{ allPages }}
        </div>
        <div class="next" @click="goNext" :class="{'disabled': filters.page + 1 === allPages}">Вперед</div>
      </div>
    </div>
    <UnauthorizationUpgrade v-else />
  </div>
</template>
<script>
import UpgradeInventoryFilters from "@/components/upgrade/UpgradeInventoryFilters.vue";
import {debounce} from "vue-debounce";
import {mapActions, mapGetters, mapMutations} from "vuex";
import LoaderBlock from "@/components/LoaderBlock.vue";
import UpgradeItem from "@/components/upgrade/UpgradeItem.vue";
import UnauthorizationUpgrade from "@/components/upgrade/UnauthorizationUpgrade.vue";
export default {
  computed: {
    ...mapGetters({
      secondSkin: 'upgrade/secondSkin',
      filters: 'shop/filters',
      isLoading: 'shop/isLoading',
      shop: 'shop/shop'
    }),
    allPages () {
      if (!this.shop) {
        return 0;
      }

      return Math.ceil(this.shop.total / 20);
    }
  },
  methods: {
    ...mapMutations({
      setPage: 'shop/setPage',
      setSkip: 'shop/setSkip',
      setSearch: 'shop/setSearch'
    }),
    ...mapActions({
      setSecondSkin: 'upgrade/setSecondSkin',
      loadMore: 'shop/loadMore',
      getShop: 'shop/getShop'
    }),
    toggleSort () {
      if (this.filters.priceSort === 'desc') {
        this.filters.priceSort = 'asc'
      } else {
        this.filters.priceSort = 'desc'
      }
    },
    search: debounce(function (value) {
      this.setPage(0)
      this.setSkip(0)
      this.setSearch(value)
      this.getShop({ steamId: this.$root.user.steamId})
    }, '200ms'),
    goPrev () {
      if (this.filters.page === 0) return;
      this.loadMore({ steamId: this.$root.user.steamId, push: false, isPrev: true })
    },
    goNext () {
      if (this.filters.page + 1 === this.allPages) return;
      this.loadMore({steamId: this.$root.user.steamId, push: false})
    },
  },
  watch: {
    'filters.priceSort': {
      deep: true,
      async handler () {
        await this.setPage(0)
        await this.setSkip(0)
        await this.getShop({ steamId: this.$root.user.steamId })
      }
    }
  },
  async mounted () {
    await this.getShop({ steamId: this.$root.user.steamId })
  },
  components: {
    UnauthorizationUpgrade,
    UpgradeItem,
    LoaderBlock,
    UpgradeInventoryFilters
  }
}
</script>