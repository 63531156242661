<template>
  <div class="finish">
    <MultiPrizesOpenCase/>
<!--    <SinglePrizeOpenCase v-else />-->
    <FinishPanel />
  </div>
</template>
<script >
import FinishPanel from "@/components/open-case/FinishPanel.vue";
import MultiPrizesOpenCase from "@/components/open-case/MultiPrizesOpenCase.vue";
import {mapGetters} from "vuex";
// import SinglePrizeOpenCase from "@/components/open-case/SinglePrizeOpenCase.vue";
export default {
  computed: {
    ...mapGetters({
      prizes: 'open/prizes'
    })
  },
  components: {
    // SinglePrizeOpenCase,
    MultiPrizesOpenCase,
    FinishPanel
  }
}
</script>
<style scoped lang="scss">
.finish {
  margin-top: 20px;
}
</style>