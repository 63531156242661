import { apolloClient } from "@/vue-apollo";
import { MARKET_PRICE_LIST } from "@/queries";
import { createToastInterface } from 'vue-toastification'

const toast = createToastInterface()

export default {
	namespaced: true,
	state: {
		isLoading: false,
		market: null,
		filters: {
			page: 0,
			take: 20,
			priceSort: 'desc',
			priceFrom: 0.1,
			priceTo: 99999,
			skip: 0
		},
		search: null
	},
	getters: {
		isLoading: state => state.isLoading,
		filters: state => state.filters,
		market: state => state.market,
		search: state => state.search,
		showMoreEnabled: (state) => {
			if (!state.market) {
				return false;
			}
			return state.market.total > state.market.items.length
		}
	},
	mutations: {
		setSearch (state, value) {
			state.search = value
		},
		setMarket (state, market) {
			state.market = market
			state.market.total = market.length
		},
		setPage (state, page) {
			state.filters.page = page
		},
		setSkip (state, skip) {
			state.filters.skip = skip
		},
		pushMarket (state, market) {
			market.__typename = state.market.__typename
			state.market.items = [...market.items, ...state.market.items]
		},
		setLoading (state, value) {
			state.isLoading = value
		},
		resetLoad (state, config) {
			state.search = null
			state.filters = {
				page: 0,
				take: 20,
				priceSort: 'desc',
				skip: 0,
				priceFrom: config.getUpgradeConfig.upgradeMinSum,
				priceTo: 99999,
			}
		},
		setPriceDiapason (state, diapason) {
			state.filters.priceFrom = parseFloat(diapason.priceFrom)
			state.filters.priceTo = parseFloat(diapason.priceTo)
		}
	},
	actions: {
		async setPriceDiapason ({commit, dispatch, rootGetters}, sum) {
			await commit('setPriceDiapason', {
				priceFrom: parseFloat(sum * 1.25).toFixed(2),
				priceTo: sum > 0 ? parseFloat(sum * 20).toFixed(2) : 99999
			})
			await dispatch('getMarket', {steamId: rootGetters["user/user"].steamId})
		},
		async resetLoad ({commit, dispatch, rootGetters}) {
			await commit('resetLoad', rootGetters["config/config"])
			await dispatch('getMarket', { steamId: rootGetters["user/user"].steamId })
		},
		async loadMore ({dispatch, getters, commit}, { steamId, isPrev = false, push = true }) {
			let page = getters.filters.page

			if (!isPrev) {
				page = getters.filters.page + 1
			} else {
				page = getters.filters.page - 1
			}

			commit('setPage', page)
			commit('setSkip', getters.filters.take * page)
			dispatch('getMarket', { steamId, push })
		},
		async getMarket ({ commit, getters, dispatch }, { steamId, push = false, options = null }) {
			await commit('setLoading', true)
			await dispatch('upgrade/setSecondSkin', null, {root: true})
			let variables = getters.filters
			variables.steamId = steamId

			if (getters.onlyAvailableToWithdraw) {
				variables.status = 'process'
			} else {
				delete variables.status
			}

			if (getters.search) {
				variables.name = getters.search
			} else {
				delete variables.name
			}

			if (options) {
				variables = Object.assign({}, options, variables)
			}

			const { data } = await apolloClient.query({ fetchPolicy: 'network-only', query: MARKET_PRICE_LIST, variables })

			const markets = data.marketPrices.slice(10)

			if (markets) {
				if (push) {
					await commit('pushMarket', markets)
				} else {
					await commit('setMarket', markets)
				}
			} else {
				if (data.error) {
					data.error.graphQLErrors.map(item => {
						toast.error(item.message);
					})
				}

				return false;
			}

			await commit('setLoading', false)
		},
	},
}