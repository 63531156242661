<template>
  <div class="contract__skin">
    <div class="inject" :class="{'disabled': isAdded && statusGame === 'wait'}">
      <button class="add" v-if="statusGame === 'wait'" :class="{'disabled': isAdded || selectSkins.length > 9}" :disabled="isAdded || selectSkins.length > 9" @click="addSkin(skin)">

        <template v-if="selectSkins.length > 9">
          <template v-if="!isAdded">СЛОТЫ ЗАПОЛНЕНЫ</template>
          <template v-else>ДОБАВЛЕНО В КОНТРАКТ</template>
        </template>
        <template v-else>ДОБАВИТЬ В КОНТРАКТ</template>
      </button>
    </div>
    <BaseSkin :skin="skin" />
  </div>
</template>
<script>
import BaseSkin from "@/components/BaseSkin.vue";
import {mapGetters, mapMutations} from "vuex";
export default {
  props: {
    skin: {
      type: Object,
      required: true
    },
  },
  methods: {
    ...mapMutations({
      addSkin: 'contract/addSkin'
    })
  },
  computed: {
    ...mapGetters({
      selectSkins: 'contract/selectSkins',
      statusGame: 'contract/statusGame'
    }),
    isAdded () {
      return this.selectSkins.findIndex(item => item.id === this.skin.id) > -1
    }
  },
  components: {
    BaseSkin
  }
}
</script>
<style scoped lang="scss">
  .contract__skin {
    position: relative;
    @media (max-width: 575px) {
      ::v-deep {
        .inventory-item {
          height: 160px;
          .inventory-item__body .image {
            height: 72px;
          }
        }
      }
    }
    .inject {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 4;
      border-radius: 6px;
      justify-content: center;

      button.add {
        background: #DD9A36;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 9px 11px;
        font-weight: 800;
        font-size: 11px;
        line-height: 15px;
        color: #1D1929;
        transition: 0.2s;
        &:hover:not(.disabled) {
          opacity: 0.8;
        }
        &.disabled {
          background: #5F5B56;
          cursor: default;

        }
        @media (max-width: 575px) {
          padding: 8px 6px;
          font-size: 10px;

        }
      }
      &.disabled {
        background: rgba(62, 62, 62, 0.3);
        filter: grayscale(2);
        button.add {
          cursor: default;
          background: #5F5B56;

        }
      }
    }
  }
</style>