<template>
  <div class="modal">
    <div class="modal__body" ref="body">
      <div class="body">
        <div class="close" @click="$emit('close')">
          <img src="@/assets/icons/close.svg" alt="">
        </div>
        <div class="title">
          Покупка предмета
        </div>

        <div class="content">
          <span>предмет на покупку</span>
          <div class="skin">
            <BaseSkin :skin="item.item" />

          </div>

          <button :disabled="loading" @click="buy">
            <BlockLoader v-if="loading" />
            Подтвердить
          </button>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import closeModal from "@/mixins/closeModal";
import BaseSkin from "@/components/BaseSkin.vue";
import {MARKET_BUY} from "@/queries/mutations";
import BlockLoader from "@/components/BlockLoader.vue";

export default  {
  data () {
    return {
      loading: false
    }
  },
  components: {BlockLoader, BaseSkin},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    buy () {
      this.loading = true
      this.$apollo.mutate({
        mutation: MARKET_BUY,
        variables: {
          itemId: this.item.item.id
        }
      }).then(async () => {
        this.$toast.success('Запрос на покупку отправлен, ожидайте трейд оффера.');
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
        // this.loading = false
      }).finally(() => {
        this.loading = false
        this.$emit('close')
      })
    }
  },
  mixins: [closeModal],
}
</script>
<style scoped lang="scss">
.modal__body {
  padding: 0 !important;
}
.body {
  width: 660px;
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  min-width: 660px;
  .title {
    width: 100%;
    padding: 33px 0;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-transform: uppercase;
  }
  @media (max-width: 992px) {
    min-width: 100%;
  }
  .close {
    position: absolute;
    padding: 10px;
    top: 18px;
    right: 18px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 575px) {
      top: 10px;
      right: 20px;
      width: 35px;
      img {
        width: 35px;
      }
    }
  }
}
.content {
  width: 100%;
  padding: 16px 0;
  background: #17171F !important;
  .skin {
    width: 190px;
    margin: 0 auto;
    margin-top: 23px;
  }
  button {
    font-weight: 800;
    font-size: 16px;
    display: flex;
    line-height: 22px;
    position: relative;
    padding: 12.7px 27px;
    background: #D06538;
    white-space: nowrap;
    border-radius: 6px;
    margin: 0 auto;
    transition: 0.2s;
    margin-top: 40px;
    margin-bottom: 20px;
    &[disabled="disabled"] {
      cursor: not-allowed;
      background: gray;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #717380;
  }
}
</style>