import {apolloClient} from "@/vue-apollo";
import {GET_CONTRACTS} from "@/queries";

export default {
    namespaced: true,
    state: {
        page: 0,
        filters: {
            take: 10,
            skip: 0
        },
        contracts: []
    },
    getters: {
        page: state => state.page,
        showMoreEnabled: state => {
            if (!state.contracts) {
                return false;
            }
            return state.contracts.total > state.contracts.items.length
        },
        contracts: state => state.contracts,
        filters: state => state.filters
    },
    mutations: {
        setContracts (state, contracts) {
            state.contracts = contracts
        },
        setFilters(state, filters) {
            state.filters = filters
        },
        setPage (state, page) {
            state.page = page
        },
        pushContracts (state, contracts) {
            state.contracts.items = [...state.contracts.items, ...contracts.items ]
        }
    },
    actions: {
        async loadMore ({commit, dispatch, getters}) {
          await commit('setPage', getters.page + 1)
          await commit('setFilters', {
              skip: getters.page * getters.filters.take,
              take: getters.filters.take
          })

          dispatch('getContracts', true)
        },
        async getContracts ({commit, getters}, push = false) {
            const { data } = await apolloClient.query({ fetchPolicy: 'network-only', query: GET_CONTRACTS, variables: getters.filters })

            if (data.getContracts) {
                if (push) {
                    commit('pushContracts', data.getContracts)
                } else {
                    commit('setContracts', data.getContracts);
                }
            }
        }
    },
}