export default {
    namespaced: true,
    state: {
        selectSkins: [],
        statusGame: 'wait', // wait, process, finish
        skinPrize: null
    },
    getters: {
        selectSkins: state => state.selectSkins,
        statusGame: state => state.statusGame,
        skinPrize: state => state.skinPrize
    },
    mutations: {
        addSkin (state, skin) {
            state.selectSkins.push(skin)
        },
        removeSkin (state, index) {
            state.selectSkins.splice(index, 1);
        },
        changeStatusGame (state, value) {
            state.statusGame = value
        },
        setPrize (state, skin) {
            state.skinPrize = skin
        },
        restartGame (state) {
            state.selectSkins = []
            state.skinPrize = null
            state.statusGame = 'wait'
        }
    },
    actions: {
    },
}