export default {
    namespaced: true,
    state: {
        status: 'wait',
        winItem: null,
        betType: 'skin',
        firstSelected: null,
        isLoading: false,
        firstSkin: null,
        secondSkin: null,
        useSelectBalance: 5
    },
    getters: {
        useSelectBalance: state => state.useSelectBalance,
        status: state => state.status,
        winItem: state => state.winItem,
        betType: state => state.betType,
        firstSkin: state => state.firstSkin,
        secondSkin: state => state.secondSkin,
        isLoading: state => state.isLoading,
        firstSelected: state => state.firstSelected,
        betweenBalanceBet: (state, getters, rootGetters) => {
            if (getters.secondSkin) {
                return {
                    priceFrom: parseFloat(getters.secondSkin.price * 0.05).toFixed(2),
                    priceTo: parseFloat(getters.secondSkin.price * 0.80).toFixed(2)
                }
            }
            return {
                priceFrom: 0,
                priceTo: parseFloat(rootGetters.user.user.balance).toFixed(2)
            }
        },
        betCost: (state) => {
            if (state.betType === 'balance') {
                return state.useSelectBalance
            }
            if (!state.firstSkin) {
                return 0;
            }
            return state.firstSkin.price
        },
        availableUpgrade: (state, getters) => {
            return parseFloat(getters.upgradePercent) > 0
        },

        upgradePercent: state => {
            if (!state.secondSkin) return 0;
            let bet = 0;
            if (state.betType === 'balance') {
                bet = parseFloat(state.useSelectBalance)
            } else {
                if (!state.firstSkin) return 0;
                bet = parseFloat(state.firstSkin.price)
            }

            const itemPrice = state.secondSkin.price

            return +Number((bet / itemPrice) * 100).toFixed(2)
        },
    },
    mutations: {
        setSelectBalance (state, amount) {
            state.useSelectBalance = amount
        },
        resetGame (state) {
            state.status = 'wait'
            state.winItem = null
            state.firstSelected = null
            state.isLoading = false
            state.firstSkin = null
            state.secondSkin = null
        },
        setWinItem (state, item) {
            state.winItem = item
        },
        setLoading (state, value) {
            state.isLoading = value
        },
        setBetType (state, type) {
            state.betType = type
        },
        setStatus (state, status) {
            state.status = status
        },
        setFirstSkin (state, skin) {
            state.firstSkin = skin
        },
        setSecondSkin (state, skin) {
            state.secondSkin = skin
        },
        setFirstSelected (state, value) {
            state.firstSelected = value
        }
    },
    actions: {
        async setSelectBalance ({ commit }, amount) {
            await commit('setSelectBalance', amount)
            // dispatch('shop/setPriceDiapason', amount, {root: true})
        },
        async setBetType ({commit, dispatch}, type) {
            await commit('setBetType', type)
            await dispatch('reset', type !== 'balance')
        },
        async reset ({commit, dispatch}, resetShop = true) {
            await commit('resetGame')
            await dispatch('inventory/resetLoad', {status: 'process'}, {root: true})
            if (resetShop) {
                await dispatch('shop/resetLoad', null, {root: true})
            }
        },
        async setFirstSkin ({commit, rootGetters, dispatch, getters}, skin) {
            if (getters.firstSkin === skin) return;
            await commit('setFirstSkin', skin)

            if (skin) {
                if (!getters.secondSkin) {
                    commit('setFirstSelected', 'first')

                    dispatch('shop/setPriceDiapason', skin.price, {root: true})
                }
            } else {
                if (getters.firstSelected === 'first') {
                    commit('shop/setPriceDiapason', {
                        priceFrom: 0,
                        priceTo: 99999
                    }, {root: true})
                    commit('shop/setPage', 0, {root: true})
                    commit('shop/setSkip', 0, {root: true})
                    dispatch('shop/getShop', {steamId: rootGetters["user/user"].steamId}, {root: true})
                }
            }
            // if (!skin) {
            //         if (!getters.secondSkin) {

            //             return;
            //         }
            //
            // }
            //
            // if (!getters.secondSkin) {
            //     dispatch('shop/setPriceDiapason', skin.price, {root: true})
            // }
        },
        async setSecondSkin ({commit, dispatch, rootGetters, getters}, skin) {
            if (getters.secondSkin === skin) return;
            await commit('setSecondSkin', skin)

            if (getters.betType === 'balance') {
                return;
            }

            if (skin) {
                if (!getters.firstSkin) {
                    commit('setFirstSelected', 'second')

                    dispatch('inventory/setPriceDiapason', skin.price, {root: true})
                }
            } else {
                if (getters.firstSelected === 'second') {
                    commit('inventory/setPriceDiapason', {
                        priceFrom: 0,
                        priceTo: 99999
                    }, {root: true})
                    commit('inventory/setPage', 0, {root: true})
                    commit('inventory/setSkip', 0, {root: true})
                    dispatch('inventory/getInventory', {steamId: rootGetters["user/user"].steamId, options: {status: 'process'}}, {root: true})
                }
            }


            // if (!skin) {
            //     if (!getters.firstSkin) {
            //         commit('inventory/setPriceDiapason', {
            //             priceFrom: 0,
            //             priceTo: 99999
            //         }, {root: true})
            //         dispatch('inventory/getInventory', {steamId: rootGetters["user/user"].steamId}, {root: true})
            //         return;
            //     } else {
            //
            //     }
            // }
            //
            // if (!getters.firstSkin) {
            //     dispatch('inventory/setPriceDiapason', skin.price, {root: true})
            // }
        },
    },
}