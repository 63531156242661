import {apolloClient} from "@/vue-apollo";
import {GET_CONFIG} from "@/queries";

export default {
    namespaced: true,
    state: {
        isChatActive: false,
        isDepositActive: false,
        config: null,
        loader: false
    },
    getters: {
        isChatActive: state => state.isChatActive,
        isDepositActive: state => state.isDepositActive,
        config: state => state.config,
        loader: state => state.loader
    },
    mutations: {
        setLoader (state, value) {
            state.loader = value
        },
        toggleChat (state, value) {
            state.isChatActive = value
        },
        toggleDeposit(state, value) {
            state.isDepositActive = value
        },
        setConfig (state, config) {
            state.config = config
        },
        setTitle (state, title) {
            document.title = title
        }
    },
    actions: {
        async getConfig ({commit}) {
            const { data } = await apolloClient.query({ query: GET_CONFIG })
            if (data.config) {
                await commit('setConfig', data.config)
                await commit('setTitle', data.config.title.ru)
                await commit('upgrade/setSelectBalance', data.config.getUpgradeConfig.upgradeMinSum, {root: true})
                await commit('shop/resetLoad', data.config, {root: true})
            }
        }
    },
}