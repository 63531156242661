<template>
  <div class="inventories">
    <div class="inventory__instance" :class="{'blur': status !== 'wait' || betType === 'balance'}">
      <UpgradeProfileInventory />
    </div>
    <div class="inventory__instance" :class="{'blur': status !== 'wait'}">
      <UpgradeShopInventory />
    </div>
  </div>
</template>
<style scoped lang="scss">
.inventories {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 65px;
  @media (max-width: 575px) {
    margin-top: 40px;
  }
  @media (max-width: 1400px) {
    grid-gap: 20px;
  }
  @media (max-width: 992px) {
    grid-gap: 15px;
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .inventory__instance {
    transition: 0.3s;
    &.blur {
      position: relative;
      filter: blur(3px);
      &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        filter: blur(3px);
      }
    }
  }
}
</style>
<script>
import UpgradeProfileInventory from "@/components/upgrade/UpgradeProfileInventory.vue";
import UpgradeShopInventory from "@/components/upgrade/UpgradeShopInventory.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      betType: 'upgrade/betType',
      status: 'upgrade/status'
    })
  },
  components: {
    UpgradeProfileInventory,
    UpgradeShopInventory
  }
}
</script>