import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from "@/views/ProfileView.vue";
import PublicProfile from "@/views/PublicProfile.vue";
import ContractView from "@/views/ContractView.vue";
import CaseView from "@/views/CaseView.vue";
import UpgradeView from "@/views/UpgradeView.vue";
import ContactsView from "@/views/ContactsView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import store from "@/store";
import ShopView from "@/views/ShopView.vue";
import ChatView from "@/views/ChatView.vue";
import PrizesView from "@/views/PrizesView";
import DepositView from "@/views/DepositView";
import PartnerView from "@/views/PartnerView";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/profile/:id',
    name: 'public-profile',
    props: true,
    component: PublicProfile
  },
  {
    path: '/contract',
    name: 'contract',
    component: ContractView
  },
  {
    path: '/case/:slug',
    name: 'case',
    props: true,
    component: CaseView
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: UpgradeView
  },
  {
    path: '/store',
    name: 'store',
    component: ShopView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatView
  },
  {
    path: '/agreement',
    name: 'terms-and-conditions',
    component: TermsAndConditions
  },
  {
    path: '/prizes',
    name: 'prizes',
    component: PrizesView
  },
  {
    path: '/winners',
    name: 'winners',
    component: PrizesView
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: DepositView
  },
  {
    path: '/partner',
    name: 'partner',
    component: PartnerView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  store.commit('config/setLoader', true)

  setTimeout(() => {
    store.commit('config/setLoader', false)

  }, 1000)
  return next()
})
router.afterEach(() => {
  if (store.getters["config/config"]) {
    store.commit('config/setTitle', store.getters["config/config"].title.ru)
  }
})
export default router
