import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import { createProvider } from './vue-apollo'
Vue.config.productionTip = false
import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";
Vue.use(Toast)

let currentLanguage = Vue.observable(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru');
if (fromCookie('token')) {
  localStorage.setItem('apollo-token', fromCookie('token'))
}

import { fromCookie } from "@/helpers/fromCookie";
import { mapGetters } from "vuex";

new Vue({
  data () {
    return {
      currentLanguage
    }
  },
  store,
  router,
  i18n,
  render: h => h(App),
  computed: {
    ...mapGetters({
      authStatus: 'user/authStatus',
      getState: 'user/user',
    }),
    user () {
      return this.getState
    },
    isAuth () {
      return this.authStatus
    }
  },
  apolloProvider: createProvider(),
}).$mount('#app')


export {
  currentLanguage
}
