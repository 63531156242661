<template>
  <div class="finish-panel">
    <button class="reset" @click="reset">
      Попробовать ещё раз
      <img src="@/assets/icons/load.svg" alt="">
    </button>
    <button class="sell" @click="sellSkinsMethod">
      Продать за {{ totalCost }}$
      <img src="@/assets/icons/dollar.svg" alt="">
    </button>
    <router-link class="add" to="/contract" tag="button">
      Добавить в контракт
      <img src="@/assets/icons/contract.svg" alt="">
    </router-link>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  data () {
    return {
      
    }
  },
  computed: {
    totalCost () {
      let cost = 0
      this.prizes.forEach(item => {
        cost = parseFloat(cost) + parseFloat(item.price)
      })

      return parseFloat(cost).toFixed(2)
    },
    ...mapGetters({
      prizes: 'open/prizes'
    })
  },
  methods: {
    async sellSkinsMethod () {
      const ids = this.prizes.map(item => item.id)

      await this.sellSkins(ids)
      this.reset()
    },
    ...mapActions({
      sellSkins: 'inventory/sellSkins'
    }),
    ...mapMutations({
      reset: 'open/resetGame',
    })
  }
}
</script>
<style scoped lang="scss">
.finish-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media (max-width: 575px) {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 20px;
  }
  button {
    padding: 18px 32px;
    font-weight: 800;
    font-size: 16px;
    border-radius: 6px;
    overflow: hidden;
    line-height: 22px;
    display: flex;
    align-items: center;
    transition: 0.2s;
    @media (max-width: 900px) {
      padding: 16px 24px;
      font-size: 14px;
    }
    @media (max-width: 768px) {
      width: calc(50% - 5px);
      justify-content: center;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
    img {
      margin-left: 15px;
    }

    &.reset {
      background: #AFA362;
      transition: 0.2s;
      &:hover {
        background: #897F4B;
      }
    }
    &.sell {
      background: #629E6F;
      &:hover {
        background: #3F8D50;
      }
    }
    &.add {
      background: #5472BE;
      &:hover {
        background: #3A5CB1;
      }
    }
  }
  button + button {
    margin-left: 22px;
    @media (max-width: 768px) {
      margin-left: 10px;
    }
    @media (max-width: 575px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  button:last-child {
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
</style>