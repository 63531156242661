<template>
  <div class="subscribe-withdraws"></div>
</template>
<script>
import {WITHDRAW_UPDATES} from "@/queries/subscriptions";
import {mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters({
      inventory: 'inventory/inventory'
    })
  },
  methods: {
    ...mapMutations({
      updateSkinsStatus: 'inventory/updateSkinsStatus'
    }),
    async updateStatusWithdraw (withdrawUpdate, status) {
      await this.$nextTick()
      if (!withdrawUpdate || !withdrawUpdate.id) return;

      const findProduct = this.inventory.items.find(item => item.withdraw && item.withdraw.id === withdrawUpdate.id)
      //console.log(findProduct)
      if (findProduct) {
        this.updateSkinsStatus({ status: status, ids: [findProduct.id] })
      }
    },
  },
  apollo: {
    $subscribe: {
      WithdrawUpdatesStream: {
        query: WITHDRAW_UPDATES,
        result ({ data: { WithdrawUpdatesStream } }) {
          switch (WithdrawUpdatesStream.status) {
            case 'waitingAccept':
              //this.$toast.success(this.$t('open.waitingAccept'));
              this.$toast.success('Запрос на обмен отправлен в Ваш steam.');
              break
            case 'buyError':
              //this.$toast.error(this.$t('open.buyError'));
              this.$toast.error('Произошла ошибка, возможно предмет недоступен для покупки.');
              break
            case 'tradeCreateError':
              //this.$toast.error(this.$t('open.tradeCreateError'));
              this.$toast.error('Произошла ошибка при создание обмена, попробуйте позже.');
              break
            default:
              break
          }
          let status = null
          let processStatuses = ['declined', 'canceled', 'timeout', 'invalidTradeToken', 'userNotTradable', 'tradeCreateError', 'buyError', 'unknownError']
          if (processStatuses.includes(WithdrawUpdatesStream.status)) {
            status = 'process'
          }
          //console.log('status', WithdrawUpdatesStream.status)
          let successStatuses = ['process', 'creatingTrade', 'waitingAccept']

          if (successStatuses.includes(WithdrawUpdatesStream.status)) {
            status = 'withdrawing'
          }

          if (WithdrawUpdatesStream.status === 'accepted') {
            status = 'withdrawn'
          }

          this.updateStatusWithdraw(WithdrawUpdatesStream, status)
        },
      }
    },
  }
}
</script>