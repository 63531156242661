<template>
  <div class="roulette" :key="`k_${randContainer}`" :class="{'without-shadow': !$root.isAuth}">
    <div class="arrow up" v-if="$root.isAuth">
      <img src="@/assets/images/arrow-down.svg" alt="">
    </div>

    <div :style="{'visibility': !initShow ? 'hidden' : ''}" style="visibility: hidden">
      <agile v-if="initSlider" :ref="`slider_${randContainer}`" :timing="'ease-in-out'" :speed="4750" :options="settings" class="open__case-list">
        <div class="roulette__item" v-for="(skin, i) in cases" :key="i">
          <BaseSkin :hide-price="true" :skin="skin" />
        </div>
      </agile>
    </div>
    
    <div class="arrow" v-if="$root.isAuth">
      <img src="@/assets/images/arrow-top.svg" alt="">
    </div>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import {eventBus} from "@/helpers/eventBus";
import {mapGetters, mapMutations} from "vuex";
import BaseSkin from "@/components/BaseSkin.vue";
import {generateForSlider} from "@/helpers/generateForSlider";

export default {
  props: ['index'],
  data () {
    return {
      cases: [],
      randContainer: (Math.random() + 1).toString(36).substring(7),
      initSlider: false,
      initShow: false
    }
  },
  computed: {
    ...mapGetters({
      caseInstance: 'open/caseInstance',
      count: 'open/count',
      status: 'open/status',
      prizes: 'open/prizes'
    }),
    getCountSlides () {
      let slides = 5
      const width = window.innerWidth
      if (this.status === 'wait') {
        if (width < 1000) {
          return 3;
        }
        return 5
      }

      if (width < 769) {
        return 3;
      }

      if (window.innerWidth < 1600) {
        return 1;
      }

      if (this.count === 2) {

          slides = 3
      }

      if (this.count > 2) {
        slides = 1
      }
      return slides
    },
    settings () {
      return {
            initialSlide: 9,
            responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: this.getCountSlides
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: this.getCountSlides
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: this.getCountSlides
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: this.getCountSlides
            }
          },
        ],
            slidesToShow: this.getCountSlides,
            centerMode: true,
            rtl: false,
            pauseOnHover: false,
            pauseOnDotsHover: false,

            infinite: true,
            navButtons: false,
            timing: 'ease-out',
            dots: false
      };
    },
  },
  methods: {
    ...mapMutations({
      addPrize: 'open/addPrize',
      setStatus: 'open/setStatus',
    }),
    loadCases () {
      const items = JSON.parse(JSON.stringify(this.caseInstance.items))

      this.cases = generateForSlider(items)

      if (this.status === 'wait') {
        setTimeout(() => {
          this.$refs[`slider_${this.randContainer}`].reload()
        }, 0)
      }

      this.initSlider = true
      setTimeout(() => {
        this.initShow = true
      }, 700)
    },
    run () {
      const prize = this.prizes[this.index - 1]
      let index = 35

      for (const item in this.cases) {
        if (this.cases[item].name === prize.item.name) {
          index = item
        }
      }

      this.$nextTick(() => {
        this.cases[index] = prize.item
        this.$refs[`slider_${this.randContainer}`].goTo(index)
      })
    },
  },
  components: {
    BaseSkin,
    agile: VueAgile,
  },
  mounted() {
    this.randContainer = (Math.random() + 1).toString(36).substring(7)
    this.loadCases()

    eventBus.$on("case.load-results", async () => {
      await this.run()
    })
  },
  beforeDestroy() {
    eventBus.$off('case.load-results')
  },
  watch: {
    $route() {
      this.loadCases()
    }
  }
}
</script>
<style scoped lang="scss">
//::v-deep {
//  .agile__list {
//    padding-left: 37px;
//  }
//}
.open__case-list {

}
.roulette {
  position: relative;
  padding: 24px 0;
  max-width: 100%;
  @media (max-width: 575px) {
    padding: 4px 0;
  }
  &:not(.without-shadow)::after {
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 64px;
    z-index: 2;
    height: 100%;
    top: 0;
    background: linear-gradient(90deg, #141518 0%, rgba(20, 21, 24, 0) 100%);
  }
  &:not(.without-shadow)::before {
    content: "";
    display: block;
    right: 0;
    position: absolute;
    width: 64px;
    height: 100%;
    z-index: 2;
    top: 0;
    background: linear-gradient(90deg, rgba(20, 21, 24, 0) 0%, #141518 100%);
  }
  .roulette__item {
    padding: 0 3px;
    @media (max-width: 575px) {
      padding: 0 1px;
    }
    @media (max-width: 575px) {
      ::v-deep {
        .inventory-item {
          height: 125px;
          .inventory-item__body {
            .image {
              margin-top: 0;
              height: 60px;
            }
            .info {
              strong {
                font-size: 11px;
                line-height: 14px;
              }
              span {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: -12px;
    left: 0;
    @media (max-width: 575px) {
      bottom: -8px;
    }
    margin-right: 1px;
    &.up {
      bottom: unset;
      top: -12px;
    }
    @media (max-width: 768px) {
      img {
        width: 30px;
      }
    }
  }
}
</style>