<template>
  <div class="multi-prizes">
    <MultiPrize v-for="(prize, i) in prizes" :prize="prize" :key="i" />
  </div>
</template>
<script >
import MultiPrize from "@/components/open-case/MultiPrize.vue";
import {mapGetters, mapMutations} from "vuex";
export default {
  computed: {
    ...mapGetters({
      prizes: 'open/prizes'
    })
  },
  methods: {
    ...mapMutations({
      resetGame: 'open/resetGame'
    })
  },
  watch: {
    'prizes.length': function () {
      if (this.prizes.length === 0) {
        this.resetGame()
      }
    }
  },
  components: {
    MultiPrize
  }
}
</script>
<style scoped lang="scss">
.multi-prizes {
  display: flex;
  justify-content: center;
  margin-bottom: 98px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    grid-row-gap: 30px;
  }
  @media (max-width: 575px) {
    justify-content: space-around;
    grid-row-gap: 20px;
  }
  .multi-prize + .multi-prize {
    margin-left: 26px;
    @media (max-width: 1100px) {
      margin-left: 0;
    }
  }
}
</style>