<template>
  <div class="circle">
    <div class="arrow" v-if="status !== 'finish'" ref="cursor">
      <img src="@/assets/icons/upgrade-arrow.svg" alt="">
    </div>
    <div class="info">
      <BarComponent v-if="status !== 'finish'" :percent="parseFloat(upgradePercent)" />
      <div class="shadow" :class="{'fail': status ==='finish' && winItem && !winItem.isWin, 'success': status ==='finish' && winItem && winItem.isWin}"></div>
      <template v-if="status !== 'finish'">
        <strong>{{ parseFloat(upgradePercent).toFixed(2) }}%</strong>
        <span v-if="parseInt(upgradePercent) < 41">НИЗКИЙ ШАНС</span>
        <span v-else-if="parseInt(upgradePercent) < 70 && parseInt(upgradePercent) > 40">средний шанс</span>
        <span v-else-if="parseInt(upgradePercent) < 100 && parseInt(upgradePercent) > 69">высокий шанс</span>

      </template>
      <template v-else>
        <div class="skin">
          <div class="image">
            <img :src="winItem.open.item.getImage" alt="">
          </div>
          <strong :class="[hexToColor(winItem.open.item.color)]">{{ getNameSkin(winItem.open.item.name) }}</strong>
          <span>{{ getQualitySkin(winItem.open.item.name) }}</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import getNameSkin from '../../helpers/getNameSkin'
import getQualitySkin from '../../helpers/getQualitySkin'
import BarComponent from "@/components/upgrade/BarComponent.vue";
import hexToColor from "@/filters/hexToColor";

export default {
  watch: {
    status () {
      if (this.status === 'process') {
        this.startAnimations()
        this.$root.$emit('SoundPlay', 'upgrade_spin')
      }
      if (this.status === 'finish') {
        if (this.winItem.isWin) {
          this.$root.$emit('SoundPlay', 'upgrade_win')
        } else {
          this.$root.$emit('SoundPlay', 'upgrade_lose')
        }
      }
    }
  },
  methods: {
    hexToColor,
    ...mapMutations({
      setStatus: 'upgrade/setStatus',
      setLoading: 'upgrade/setLoading'
    }),
    getQualitySkin,
    getNameSkin,
    startAnimations () {
      const result = this.winItem
      const isWin = result.isWin
      this.isWin = isWin
      this.finishItem = result.open.item
      const cursor = this.$refs.cursor;
      const baseRotate = 3600;

      const getRandomArbitrary = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

      let rotate = baseRotate;
      if (!isWin) {
        const freePercent = 100 - this.upgradePercent
        const countOfPercent = (360 / 100) * freePercent
        const res = 360 - getRandomArbitrary(0, countOfPercent)
        rotate += res
      } else {
        const countOfPercent = (360 / 100) * this.upgradePercent
        rotate += getRandomArbitrary(0, countOfPercent)
      }

      cursor.style.transform = `rotate(${rotate}deg)`;
      setTimeout(() => {
        this.setLoading(false)
        this.setStatus('finish')
      }, 6000)
    }
  },
  computed: {
    ...mapGetters({
      winItem: 'upgrade/winItem',
      status: 'upgrade/status',
      upgradePercent: 'upgrade/upgradePercent'
    })
  },
  components: {
    BarComponent
  }
}
</script>
<style scoped lang="scss">
.circle {
  width: 416px;
  height: 416px;
  min-width: 416px;
  background: url(@/assets/images/upgrade-circle.png);
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    width: 316px;
    height: 316px;
    min-width: 316px;
  }
  .arrow {
    width: 219px;
    height: 219px;
    transition: ease-out 5.8s;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 100%;
    img {
      margin-top: -7px;
    }
    z-index: 5;
    @media (max-width: 1100px) {
      width: 170px;
      height: 170px;
      min-width: 170px;
    }
  }
  .info {
    z-index: 4;
    width: 225px;
    height: 225px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 1100px) {
      width: 170px;
      height: 170px;
      min-width: 170px;
    }
    .shadow {
      position: absolute;
      width: 0;
      height: 0;
      box-shadow: 0px 0px 60px 40px rgba(241, 205, 113, 0.24);
      z-index: 6;
      &.fail {
        box-shadow: 0px 0px 60px 40px rgba(237, 23, 74, 0.24);
      }
      &.success {
        box-shadow: 0px 0px 60px 40px rgba(98, 158, 111, 0.24);
      }
    }
    strong, span {
      position: relative;
      z-index: 5;
      display: block;
      width: 100%;
      text-align: center;
    }
    strong {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #FBCA6C;
      margin-bottom: 2px;
    }
    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
      color: #717380;
    }
    .bar__component {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
    .skin {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 150px;
        img {
          max-width: 100%;
          max-height: 110px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
      }
      strong, span {
        display: block;
        width: 100%;
        text-align: center;
      }
      strong {
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #EB4C4C;
        &.pink {
          color: #D32CE6
        }
        &.grey {
          color: #B0C2D9
        }
        &.light-blue {
          color: #72C8D8
        }
        &.red {
          color: #EB4C4C
        }
        &.yellow {
          color: #C7AC4C
        }
        &.blue {
          color: #5E98D9
        }
        &.purple {
          color: #8847FF
        }
      }
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #646465;
      }
    }
  }
}
</style>