<template>
  <div class="market__filter">
    <div class="filters" v-if="!filterClose">
      <p class="market__filter-title">Фильтры:</p>

      <div class="market__filter-block">
        <div class="filter_price">
          <div class="priceFrom">
            <p class="title">Цена от</p>
            <input type="number" @input="$emit('priceFrom', $event)" name="priceFrom" placeholder="0">
          </div>
          <div class="priceTo">
            <p class="title">Цена до</p>
            <input type="number" @input="$emit('priceTo', $event)" name="priceTo" placeholder="5000">
          </div>
        </div>
      </div>

      <div class="market__filter-block">
        <p class="title">Качество</p>
        <div class="filter_quality">
          <div class="--checkbox">
            <input v-model="qualities.FN" type="checkbox" id="FN" name="FN">
            <label for="FN">Прямо с завода</label>
          </div>
          <div class="--checkbox">
            <input v-model="qualities.MW" type="checkbox" id="MW" name="MW">
            <label for="MW">Немного поношенное</label>
          </div>
          <div class="--checkbox">
            <input v-model="qualities.FT" type="checkbox" id="FT" name="FT">
            <label for="FT">После полевых испытаний</label>
          </div>
          <div class="--checkbox">
            <input v-model="qualities.WW" type="checkbox" id="WW" name="WW">
            <label for="WW">Поношеное</label>
          </div>
          <div class="--checkbox">
            <input v-model="qualities.BS" type="checkbox" id="BS" name="BS">
            <label for="BS">Закаленное в боях</label>
          </div>
        </div>
      </div>

      <div class="market__filter-block">
        <p class="title">Раритетность</p>
        <div class="filter_rarity">
          <div class="--checkbox">
            <input v-model="rarity.consumer" type="checkbox" id="Common" name="Common">
            <label for="Common">Ширпотреб</label>
          </div>
          <div class="--checkbox">
            <input v-model="rarity.industrial" type="checkbox" id="Uncommon" name="Uncommon">
            <label for="Uncommon">Промышленное качество</label>
          </div>
          <div class="--checkbox">
            <input v-model="rarity.milSpec" type="checkbox" id="Rare" name="Rare">
            <label for="Rare">Армейское качество</label>
          </div>
          <div class="--checkbox">
            <input v-model="rarity.restricted" type="checkbox" id="Mythical" name="Mythical">
            <label for="Mythical">Запрещенное</label>
          </div>
          <div class="--checkbox">
            <input v-model="rarity.classified" type="checkbox" id="Legendary" name="Legendary">
            <label for="Legendary">Засекреченное</label>
          </div>
          <div class="--checkbox">
            <input v-model="rarity.covert" type="checkbox" id="Ancient" name="Ancient">
            <label for="Ancient">Тайное</label>
          </div>
        </div>
      </div>

      <div class="market__filter-block">
        <p class="title">Категория</p>
        <div class="filter_category">
          <div class="--checkbox">
            <input v-model="types.weapon" type="checkbox" id="weapon" name="weapon">
            <label for="weapon">Оружие</label>
          </div>
          <div class="--checkbox">
            <input v-model="types.gloves" type="checkbox" id="gloves" name="gloves">
            <label for="gloves">Перчатки</label>
          </div>
          <div class="--checkbox">
            <input v-model="types.knife" type="checkbox" id="knife" name="knife">
            <label for="knife">Ножи</label>
          </div>
          <div class="--checkbox">
            <input v-model="types.agent" type="checkbox" id="agent" name="agent">
            <label for="agent">Агенты</label>
          </div>

          <div class="--checkbox">
            <input v-model="types.music" type="checkbox" id="music" name="music">
            <label for="music">Музыка</label>
          </div>

          <div class="--checkbox">
            <input v-model="types.graffiti" type="checkbox" id="graffiti" name="graffiti">
            <label for="graffiti">Граффити</label>
          </div>

          <div class="--checkbox">
            <input v-model="types.sticker" type="checkbox" id="sticker" name="sticker">
            <label for="sticker">Стикеры</label>
          </div>
        </div>
      </div>
    </div>
    <button class="filter_btn open" @click="toggleFilters()" v-if="filterClose">Открыть фильтр</button>
    <button class="filter_btn close" @click="toggleFilters()" v-else>Закрыть фильтр</button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      types: {
        weapon: false,
        gloves: false,
        agent: false,
        knife: false,
        music: false,
        graffiti: false,
        sticker: false
      },
      qualities: {
        FN: false,
        MW: false,
        FT: false,
        WW: false,
        BS: false
      },
      rarity: {
        consumer: false,
        industrial: false,
        milSpec: false,
        restricted: false,
        classified: false,
        covert: false
      },
      filterClose: false
    }
  },
  watch: {
    qualities: {
      deep: true,
      handler() {
        this.$emit('qualities', this.qualities)
      }
    },
    types: {
      deep: true,
      handler() {
        this.$emit('types', this.types)
      }
    },
    rarity: {
      deep: true,
      handler() {
        this.$emit('rarity', this.rarity)
      }
    }
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    toggleFilters() {
      this.filterClose = !this.filterClose
    }
  },
  computed() {
    if(this.isMobile()) this.filterClose = true
  }
}
</script>

<style scoped lang="scss">
.market__filter {
  padding: 20px 25px;
  max-width: 310px;
  background: #141518;
  .filter_btn {
    margin-top: 30px;
    background: #D06538;
    transition: 0.2s;
    border-radius: 6px;
    padding: 9px 16px;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    display: none;
    &:hover {
      background: #F1682D;
    }
    @media (max-width: 900px) {
      display: block;
    }
  }
  @media (max-width: 900px) {
    &-title {
      position: absolute;
    }
    max-width: 100%;
    grid-gap: 25px;
  }
  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: unset;
  }
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
  }
  &-block {
    margin: 30px 0;
    .title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #717380;
      margin-bottom: 10px;
    }
    .filter_price {
      display: grid;
      grid-template-columns: 50% 50%;
      input {
        background: #121315;
        border: 1px solid rgba(255, 255, 255, .07);
        padding: 13px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        width: 100%;
        &:focus,
        &:hover {
          outline: none;
          border: 1px solid rgba(255,255,255,.15);
          transition: border-color .2s cubic-bezier(.1,.7,.5,1);
        }
      }
      .priceFrom {
        input {
          border-radius: 5px 0px 0px 5px;
        }
      }
      .priceTo {
        input {
          border-radius: 0px 5px 5px 0px;
        }
      }
    }
    .filter_quality {

    }
    .filter_rarity {
      #Common+label {
        color: #B0C2D9;
      }
      #Uncommon+label {
        color: #5E98D9;
      }
      #Rare+label {
        color: #4B69FF;
      }
      #Mythical+label {
        color: #8747FF;
      }
      #Legendary+label {
        color: #D32CE6;
      }
      #Ancient+label {
        color: #EB4C4C;
      }
      #Contraband+label {
        color: #C7AC4C;
      }
    }
    .filter_category {
      #usual+label {
        color: #717380;
      }
      #souvenir+label {
        color: #FFD700;
      }
      #stattrak+label {
        color: #CF6A32;

      }
      #knife+label {
        //color: #8650AC;

      }
      #stattrak_knife+label {
        color: #8650AC;

      }
    }
    .--checkbox {
      input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        &:checked+label:before {
          background-color: #ED174A;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
        }
      }
      label {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #717380;
        &:before {
          content: '';
          display: inline-block;
          width: 19px;
          height: 19px;
          flex-shrink: 0;
          flex-grow: 0;
          border: 1px solid rgba(255, 255, 255, 0.15);
          border-radius: 5px;
          margin-right: 15px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
        }
      }
    }
    .--checkbox+.--checkbox {
      margin-top: 10px;
    }
  }
}
</style>