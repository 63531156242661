import {apolloClient} from "@/vue-apollo";
import {GET_UPGRADES} from "@/queries";

export default {
    namespaced: true,
    state: {
        page: 0,
        filters: {
            take: 9,
            skip: 0
        },
        upgrades: []
    },
    getters: {
        page: state => state.page,
        showMoreEnabled: state => {
            if (!state.upgrades) {
                return false;
            }
            return state.upgrades.total > state.upgrades.items.length
        },
        upgrades: state => state.upgrades,
        filters: state => state.filters
    },
    mutations: {
        setUpgrades (state, upgrades) {
            state.upgrades = upgrades
        },
        setFilters(state, filters) {
            state.filters = filters
        },
        setPage (state, page) {
            state.page = page
        },
        pushUpgrades (state, upgrades) {
            state.upgrades.items = [...state.upgrades.items, ...upgrades.items ]
        }
    },
    actions: {
        async loadMore ({commit, dispatch, getters}) {
            await commit('setPage', getters.page + 1)
            await commit('setFilters', {
                skip: getters.page * getters.filters.take,
                take: getters.filters.take
            })

            dispatch('getUpgrades', true)
        },
        async getUpgrades ({commit, getters}, push = false) {
            const { data } = await apolloClient.query({ fetchPolicy: 'network-only', query: GET_UPGRADES, variables: getters.filters })

            if (data.getUpgrades) {
                if (push) {
                    commit('pushUpgrades', data.getUpgrades)
                } else {
                    commit('setUpgrades', data.getUpgrades);
                }
            }
        }
    },
}