<template>
  <div class="result" v-if="skinPrize">
    <div class="result-wrapper">
      <div class="image">
        <img :src="skinPrize.item.getImage" alt="">
        <div class="shadow" :class="[hexToColor(skinPrize.item.color)]"></div>

        <div class="info">
          <strong>{{ getNameSkin(skinPrize.item.name) }}</strong>
          <span>{{ getQualitySkin(skinPrize.item.name) }}</span>
        </div>
      </div>
      <div class="controls">
        <button class="reset" @click="restartGame">
          Попробовать ещё раз
          <img src="@/assets/icons/restart.svg" alt="">
        </button>
        <button class="sell" @click="sellSkin">
          Продать за {{ parseFloat(skinPrize.price).toFixed(2) }}$
          <img src="@/assets/icons/dollar.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import getNameSkin from "../../helpers/getNameSkin";
import getQualitySkin from "../../helpers/getQualitySkin";
import hexToColor from "@/filters/hexToColor";

export default {
  computed: {
    ...mapGetters({
      skinPrize: 'contract/skinPrize'
    })
  },
  methods: {
    async sellSkin () {
      if (await this.sellSkins([this.skinPrize.id])) {
        await this.restartGame()
      }
    },
    hexToColor,
    getNameSkin,
    getQualitySkin,
    ...mapMutations({
      restartGame: 'contract/restartGame'
    }),
    ...mapActions({
      sellSkins: 'inventory/sellSkins'
    })
  }
}
</script>
<style scoped lang="scss">
.result {
  display: flex;
  justify-content: center;
  .result-wrapper {

  }
  .image {
    width: 445px;
    margin: 0 auto;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(@/assets/images/contract-prize-bg.png);
    background-size: 100% 100%;
    position: relative;
    @media (max-width: 768px) {
      width: 320px;
    }
    img {
      max-width: 90%;
      max-height: 90%;
      position: relative;
      z-index: 2;
    }
    .shadow {
      box-shadow: 0px 0px 56px 16px rgba(254,84,84, 0.6);
      position: absolute;
      width: 108px;
      height: 108px;
      border-radius: 100%;
      background: #FE5454;
      filter: blur(16.5px);
      &.grey {
        box-shadow: 0px 0px 27px 16px rgba(176, 195, 217, 0.7);
        background: rgba(176, 194, 217, 1);
      }
      &.light-blue {
        box-shadow: 0px 0px 56px 16px rgba(114, 200, 216, 0.6);
        background: rgba(114, 200, 216, 1);
      }
      &.pink {
        box-shadow: 0px 0px 56px 16px rgba(211, 44, 230, 0.6);
        background: rgba(211, 44, 230, 1);
      }
      &.blue {
        box-shadow: 0px 0px 56px 16px rgba(94, 152, 217, 0.6);
        background: rgba(94, 152, 217, 1);
      }
      &.yellow {
        box-shadow: 0px 0px 56px 16px rgba(199, 172, 76, 0.6);
        background: rgba(199, 172, 76, 1);
      }
      &.purple {
        box-shadow: 0px 0px 56px 16px rgba(136, 71, 255, 0.6);
        background: rgba(136, 71, 255, 1);
      }
    }
    .info {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      strong, span {
        width: 100%;
        display: block;
        text-align: center;
      }
      strong {
        font-weight: 800;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 2px;
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        color: #646465;
      }
    }
  }
  .controls {
    display: flex;
    justify-content: center;
    margin-top: 43px;
    @media (max-width: 575px) {
      flex-wrap: wrap;
      button {
        width: 100% !important;
      }
    }
    .reset {
      margin-right: 22px;
      background: #AFA362;
      border-radius: 6px;
      padding: 18px 32px;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        padding: 15px 20px;
      }
      @media (max-width: 575px) {
        margin-bottom: 10px;
        margin-right: 0;
        max-width: 100%;
        justify-content: center;
      }
      img {
        margin-left: 16px;
      }
      transition: 0.2s;
      &:hover {
        background: #897F4B;

      }
    }
    .sell {
      background: #629E6F;
      border-radius: 6px;
      padding: 18px 32px;
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 16px;
      transition: 0.2s;
      line-height: 22px;
      @media (max-width: 768px) {
        padding: 15px 20px;
      }
      @media (max-width: 575px) {
        justify-content: center;
        max-width: 100%;
      }
      &:hover {
        background: #3F8D50;
      }
      img {
        margin-left: 15px;
      }
    }
  }
}
</style>