<template>
  <div class="trade-link">
    <div class="trade-link-top">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect width="20" height="20" fill="url(#pattern0)"/>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0_1007_17165" transform="scale(0.05)"/>
          </pattern>
          <image id="image0_1007_17165" width="20" height="20" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABNUlEQVQ4je2SO0oDQBRFzwQ/qRQ1YBE/BMHSSixEFF2Ai3ADLkJUsLCwdQuCnwVoZxHExkokKCgIVlppQHIsfMExJEIasfBWM/fNve/NzIV//F2oI+qOeqPW/cKDOhz1x4x/V+/UXbXU9ElhVgHOgEngGbgC6sAUUAFm4/wFcAvUgH5gBhgE7oHllFINNanV6Lqh9mZTr6sNdUKdjPV6Vu9VN0N7oRZQF4M4bPMMBbWc7ctqoc254/BY6gHmgj+I4jywDxQzQasBwCuwllKqhnYVmM27pdbOXaCpbaAux7hHba5SUMey/ViHK5+Ex0JTdBnEZodPGe/wKX3qVmjP1dSMzTRwCpSBFz5j88bPsSnyGZuB4FZSSnf52KPqnnrvdzyppQj2Q8bX1Wt1Wx3q+tX/8Xv4AHdfT5YcfJnGAAAAAElFTkSuQmCC"/>
        </defs>
      </svg>
      <span>ТРЕЙД-ССЫЛКА</span>
      <a :href="`https://steamcommunity.com/profiles/${$root.user.steamId}/tradeoffers/privacy`" target="_blank" class="where">Где получить?</a>
    </div>
    <div class="trade-link-content">
      <input @keydown.enter="save" v-model="$root.user.tradeLink" placeholder="Trade link" type="text">
      <button @click="save" :disabled="!validTradeLink || isLoading" :class="{'disabled': !validTradeLink || isLoading}">
        Сохранить
        <BlockLoader v-if="isLoading" />
      </button>
    </div>
  </div>
</template>
<script>
import { SAVE_TRADE_LINK } from "@/queries/mutations";
import BlockLoader from "@/components/BlockLoader.vue";

export default {
  components: {BlockLoader},
  data () {
    return {
      isLoading: false,
      tradeLink: null
    }
  },
  methods: {
    async save () {
      if (!this.validTradeLink) return;

      this.isLoading = true

      await this.$apollo.mutate({
        mutation: SAVE_TRADE_LINK,
        variables: {
          tradeLink: this.$root.user.tradeLink
        }
      }).then(() => {
        this.$toast.success('Трейд ссылка успешно сохранена.');
        this.$emit('close')
      }).catch((e) => {
        const errors = e.graphQLErrors

        errors.forEach(error => {
          this.$toast.error(error.message);
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  computed: {
    validTradeLink () {
      const urlPattern = new RegExp(/https?:\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=(\d+)&token=(.{8})$/,'i'); // validate fragment locator
      return !!urlPattern.test(this.$root.user.tradeLink);
    },
  }
}
</script>
<style scoped lang="scss">
.trade-link {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 11px 0;
  padding-bottom: 0;

  .trade-link-top {
    display: flex;
    align-items: center;
    span {
      font-weight: 800;
      font-size: 13px;
      margin-left: 6px;
      margin-right: 12px;
      margin-top: 2px;
    }
    .where {
      background: rgba(94, 152, 217, 0.5);
      border-radius: 6px;
      padding: 5px 13px;
      font-weight: 300;
      font-size: 12px;
      transition: 0.2s;
      line-height: 15px;
      &:hover {
        opacity: 0.7;
      }

    }
  }
  .trade-link-content {
    margin-top: 16px;
    display: flex;
    @media (max-width: 575px) {
      display: block;
    }
    input {
      flex-grow: 1;
      overflow: hidden;
      margin-right: 10px;
      outline: none;
      border: none;
      padding: 0 15px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      background: #1E2231;
      border-radius: 6px;
      height: 50px;
      &::placeholder {
        color: #717380;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    button {
      padding: 12px 20px;
      background: #D06538;
      border-radius: 6px;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      position: relative;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      @media (max-width: 575px) {
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
        padding: 11px 18px;
      }

      &:hover:not(.disabled) {
        background: #F1682D;
      }
      &.disabled {
        cursor: not-allowed;
        filter: grayscale(1);
      }
    }
  }
}
</style>