<template>
  <div  class="main">
    <div class="skin-image">
      <img :src="`https://imagedelivery.net/h3QE3aUHmCs2QhBZ-RLUNQ/${caseInstance.image}/320f`" alt="">
    </div>
    <div class="skin-wrapper">
      <CaseOpen />
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      caseInstance: 'open/caseInstance'
    })
  }
}
</script>
<script setup>
import CaseOpen from "@/components/open-case/CaseOpen.vue";
</script>
<style scoped lang="scss">
.skin-image {
  @media (max-width: 1200px) {
    display: none;
  }
  img {
    width: 320px;
    height: 320px;
    padding: 15px;
  }
}
.skin-wrapper {
  max-width: 100%;
  overflow-x: hidden;
}
</style>