import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import config from "@/store/modules/config";
import user from "@/store/modules/user";
import contract from "@/store/modules/contract";
import open from "@/store/modules/open";
import upgrade from "@/store/modules/upgrade";
import inventory from "@/store/modules/inventory";
import shop from "@/store/modules/shop";
import market from "@/store/modules/market";
import contracts from "@/store/modules/contracts";
import upgrades from "@/store/modules/upgrades";
export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    config,
    shop,
    market,
    user,
    contract,
    inventory,
    open,
    upgrade,
    contracts,
    upgrades
  },
})
