<template>
  <div class="unauthorization-upgrade">
    <div class="image">
      <img src="@/assets/images/empty-unauth.png" alt="">
    </div>
    <span>Для доступа к функционалу необходимо авторизоваться</span>

    <button @click="login">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.315 25.9801L10.5 25.2001C10.7714 25.7561 11.1983 26.2214 11.729 26.5394C12.2598 26.8574 12.8714 27.0145 13.4897 26.9916C14.108 26.9686 14.7063 26.7667 15.212 26.4102C15.7177 26.0538 16.109 25.5582 16.3385 24.9836C16.5679 24.409 16.6256 23.7802 16.5045 23.1734C16.3834 22.5667 16.0887 22.0082 15.6563 21.5657C15.2239 21.1232 14.6723 20.8158 14.0685 20.6808C13.4647 20.5457 12.8347 20.5889 12.255 20.8051L14.175 21.6001C14.7558 21.8428 15.2165 22.3062 15.4556 22.8885C15.6947 23.4708 15.6927 24.1243 15.45 24.7051C15.2073 25.2859 14.7439 25.7466 14.1616 25.9857C13.5793 26.2248 12.9258 26.2228 12.345 25.9801H12.315Z" fill="white"/>
        <path d="M18 3.00004C14.1875 2.99172 10.5149 4.43547 7.72862 7.03782C4.94238 9.64017 3.25162 13.2058 3 17.01L11.07 20.325C11.2905 20.1785 11.527 20.0577 11.775 19.965C12.3189 19.7315 12.9086 19.6238 13.5 19.65L17.16 14.31C17.16 13.1827 17.4943 12.0806 18.1206 11.1433C18.7469 10.2059 19.6372 9.47534 20.6787 9.04392C21.7202 8.6125 22.8663 8.49962 23.972 8.71956C25.0777 8.9395 26.0933 9.48237 26.8905 10.2795C27.6877 11.0767 28.2305 12.0923 28.4505 13.198C28.6704 14.3037 28.5575 15.4498 28.1261 16.4913C27.6947 17.5329 26.9641 18.4231 26.0268 19.0494C25.0894 19.6757 23.9874 20.01 22.86 20.01H22.74L17.475 23.76C17.499 24.7896 17.1397 25.7914 16.4669 26.5712C15.7941 27.3509 14.8557 27.853 13.8337 27.98C12.8117 28.1071 11.7789 27.8501 10.9357 27.2588C10.0924 26.6675 9.49884 25.7842 9.27 24.78L3.615 22.38C4.42562 25.0351 5.95713 27.4131 8.03907 29.2494C10.121 31.0857 12.6717 32.3083 15.4072 32.7809C18.1427 33.2536 20.9557 32.9579 23.5332 31.9267C26.1106 30.8955 28.3513 29.1693 30.0059 26.9402C31.6604 24.7111 32.664 22.0666 32.9047 19.301C33.1455 16.5354 32.6141 13.7573 31.3696 11.2758C30.1252 8.79427 28.2165 6.7068 25.8561 5.2457C23.4956 3.7846 20.776 3.00721 18 3.00004Z" fill="white"/>
        <path d="M26.6854 14.2499C26.6944 13.4975 26.4794 12.7594 26.0679 12.1294C25.6563 11.4994 25.0668 11.006 24.3742 10.7119C23.6816 10.4177 22.9171 10.3361 22.178 10.4774C21.4389 10.6187 20.7585 10.9766 20.2233 11.5055C19.688 12.0344 19.3221 12.7105 19.172 13.4478C19.0219 14.1852 19.0944 14.9505 19.3802 15.6466C19.6661 16.3427 20.1524 16.9381 20.7774 17.3571C21.4025 17.7761 22.138 17.9999 22.8904 17.9999C23.888 17.9961 24.8443 17.6012 25.5539 16.9C26.2635 16.1989 26.6697 15.2474 26.6854 14.2499ZM20.0554 14.2499C20.0584 13.6869 20.2281 13.1373 20.543 12.6706C20.858 12.2039 21.3041 11.841 21.8251 11.6276C22.3462 11.4142 22.9187 11.3599 23.4706 11.4715C24.0224 11.5832 24.5289 11.8558 24.9259 12.255C25.323 12.6541 25.593 13.162 25.7017 13.7144C25.8105 14.2668 25.7532 14.8391 25.537 15.359C25.3209 15.8789 24.9556 16.3231 24.4872 16.6356C24.0189 16.9481 23.4685 17.1149 22.9054 17.1149C22.5286 17.1149 22.1556 17.0402 21.8078 16.8951C21.4601 16.75 21.1445 16.5374 20.8795 16.2696C20.6145 16.0018 20.4052 15.684 20.2637 15.3348C20.1223 14.9855 20.0515 14.6117 20.0554 14.2349V14.2499Z" fill="white"/>
      </svg>
      Авторизация
    </button>
  </div>
</template>
<script>
import {LOGIN_URI} from "@/const/ENDPOINTS";

export default {
  methods: {
    login () {
      window.location.href = LOGIN_URI
    }
  }
}
</script>
<style scoped lang="scss">
.unauthorization-upgrade {
  padding: 37px 0;
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 352px;
    }

    margin-bottom: 35px;
  }
  span {
    margin-bottom: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 100%;
    display: block;
    color: #717380;
  }
  button {
    background: #ED174A;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 14px 35px;
    svg {
      margin-right: 19px;
    }
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    transition: 0.2s;
    &:hover {
      background: #C80A38;
    }

  }
}
</style>