<template>
  <div class="selected__item">
    <div class="ranger" v-if="first && betType === 'balance' && secondSkin">
      <div class="preview">
        <img src="@/assets/images/empty-upgrade.png" alt="">
        <span>
            {{ previewSelect }}$
        </span>
      </div>
      <div class="balance">Ваш баланс: <strong>{{ parseFloat($root.user.getBalance()).toFixed(2) }}$</strong></div>
      <SetBalanceRanger v-if="status === 'wait'" ref="comp" @change="selectPreview" />
    </div>
    <template v-else>
      <div class="empty" v-if="!skin">
      <span>Выберите скин, который<br>
хотите улучшить</span>
        <img src="@/assets/images/empty-upgrade.png" alt="">
      </div>
      <div v-else class="select__skin">
        <div class="image">
          <img :src="skin.getImage" alt="">
        </div>
        <div class="info">
          <div class="text">
            <div class="name">{{ getNameSkin(skin.name) }}</div>
            <div class="category">
              {{ getQualitySkin(skin.name) }}
            </div>
          </div>
          <div class="price">
            {{ parseFloat(skin.price).toFixed(2) }}$
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import getNameSkin from "../../helpers/getNameSkin";
import getQualitySkin from "../../helpers/getQualitySkin";
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      previewSelect: null,
    }
  },
  methods: {getNameSkin, getQualitySkin,
    selectPreview (val) {
      this.previewSelect = val
    }
  },
  computed: {
    ...mapGetters({
      secondSkin: 'upgrade/secondSkin',
      status: 'upgrade/status',
      betType: 'upgrade/betType',
      useSelectBalance: 'upgrade/useSelectBalance'
    })
  },
  components: {
    SetBalanceRanger: () => import('./SetBalanceRanger.vue')
  },
  props: {
    first: {
      type: Boolean,
      default: false
    },
    skin: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.ranger {
  width: 100%;
  @media (max-width: 650px) {
    height: 100%;
  }
  .preview {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;
    span {
      position: absolute;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #FBCA6C;
    }
    position: relative;
    img {
      width: 221px;
      @media (max-width: 650px) {
        width: 120px;
      }
    }
  }
  .balance {
    font-family: 'Inter', sans-serif;
    display: block;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #717380;
    opacity: 0.5;
    margin-bottom: 48px;
    @media (max-width: 650px) {
      margin-bottom: 0;
    }
  }
  ::v-deep {
    @media (max-width: 650px) {
      .select__balance-ranger {
        padding-top: 5px;
      }
      .range-slider {
        width: 110px !important;
      }
    }
  }
}
.selected__item {
  width: 260px;
  min-width: 260px;
  @media (max-width: 992px) {
    min-width: 200px;
    width: 200px;
  }
  @media (max-width: 768px) {
    min-width: 150px;
    width: 150px;
  }
  @media (max-width: 650px) {
    background: rgba(30, 31, 36, 0.4);
    border: 1px solid rgba(235, 215, 215, 0.09);
    backdrop-filter: blur(7.5px);
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    z-index: 98;
    height: 170px;
  }
  .empty {
    span {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      font-family: 'Inter', sans-serif;
      color: #717380;
      margin-bottom: 29px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    img {
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .select__skin {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width:250px;
      max-width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      height: 130px;
      margin-right: 0;
      @media (max-width: 768px) {
        width: 100px;
        height: 100px;
      }
      @media (max-width: 650px) {
        height: 50px;
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      @media (max-width: 768px) {
        display: block;
      }
      .text {
        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
        .name {
          font-weight: 700;
          font-size: 13px;
          line-height: 18px;
          color: #72C8D8;
          @media (max-width: 768px) {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .category {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #646465;
          @media (max-width: 768px) {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .price {
        background: rgba(70, 109, 70, 0.4);
        backdrop-filter: blur(6px);
        border-radius: 6px;
        padding: 7.5px 13.5px;
        @media (max-width: 768px) {
          justify-content: center;
          width: 100%;
          display: flex;
          font-size: 12px;
        }
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}
</style>