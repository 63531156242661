<template>
  <div class="contract">

    <div class="profile-container">
      <h1>КОНТРАКТЫ</h1>
      <transition name="fade">
        <div class="contract-game__wrapper" v-if="statusGame === 'wait'">
          <ContractSkinSelected></ContractSkinSelected>
          <ContractPanel></ContractPanel>
        </div>
      </transition>
      <transition name="fade">
        <ContractSkinResult v-if="statusGame === 'process'" />
      </transition>
    </div>

    <div class="profile-container" v-if="$root.isAuth">
      <SubTitle title="ПРЕДМЕТЫ ДЛЯ КОНТРАКТА"></SubTitle>
        <EmptyInventory v-if="!inventory || !inventory.items.length" :is-public="false"></EmptyInventory>
        <div class="skins">
          <ContractSkin v-for="skin in inventory.items" :key="skin.id" :skin="{...skin.item, id: skin.id, price: skin.price}" />
        </div>
        <div class="inventory__load" :class="{'hide': !showMoreEnabled}" @click="loadMore({ steamId: $root.user.steamId })">
          Показать ещё
          <img src="@/assets/icons/load.svg" alt="">
        </div>
    </div>
    <div v-else class="profile-container">
      <SubTitle title="НЕ АВТОРИЗИРОВАН"></SubTitle>
      <UnauthorizationContract />
    </div>
  </div>
</template>
<script>
import ContractSkin from "@/components/contract/ContractSkin.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ContractSkinResult from "@/components/contract/ContractSkinResult.vue";
import EmptyInventory from "@/components/profile/EmptyInventory.vue";
import UnauthorizationContract from "@/components/contract/UnauthorizationContract.vue";

export default {
  computed: {
    ...mapGetters({
      showMoreEnabled: 'inventory/showMoreEnabled',
      inventory: 'inventory/inventory',
      statusGame: 'contract/statusGame'
    })
  },
  methods: {
    ...mapMutations({
      resetGame: 'contract/restartGame'
    }),
    ...mapActions({
      loadMore: 'inventory/loadMore',
      getInventory: 'inventory/getInventory'
    })
  },
  watch: {
    async statusGame () {
      await this.getInventory({ steamId: this.$root.user.steamId, options: {status: 'process'} })
    }
  },
  async mounted() {
    await this.resetGame()
    await this.getInventory({ steamId: this.$root.user.steamId, options: {status: 'process'} })
  },
  components: {
    UnauthorizationContract,
    EmptyInventory,
    ContractSkinResult,
    ContractSkin,
    'SubTitle': () => import('../components/SubTitle.vue'),
    'ContractSkinSelected': () => import('../components/contract/ContractSkinSelected.vue'),
    'ContractPanel': () => import('../components/contract/ContractPanel.vue')
  }
}
</script>
<style scoped lang="scss">
.contract {
  padding: 60px 0;
  @media (max-width: 575px) {
    padding-top: 30px;
  }
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
  @media (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  h1 {
    font-weight: 800;
    font-size: 23px;
    line-height: 31px;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  .skins {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 33px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 575px) {
      grid-gap: 4px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .inventory__load {
    background: rgba(36, 39, 44, 0.8);
    border-radius: 6px;
    padding: 18px 23px;
    display: flex;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    font-size: 16px;
    margin: 0 auto;
    width: fit-content;
    transition: 0.2s;
    line-height: 22px;
    margin-top: 40px;
    img {
      margin-left: 15px;
    }
    &:hover {
      background: #24272C;
    }
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  }

}
</style>