<template>
  <div>
    <div class="user_info">
      <div class="name">{{ user.name }}</div>
    </div>
    <router-link :to="user.link" :class="user.topUser ? 'user topUser' : 'user'">
      <img src="@/assets/images/avatar.png">
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  display: flex;
  align-items: center;
  padding: 15px;
  img {
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid rgba(237, 23, 74, 0.5);
  }
  &_info {
    margin: 40px 0;
    .name {
      text-align: center;
      color: #FFFFFF;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 600;
      max-width: 142px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>