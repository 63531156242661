<template>
  <div class="controls">
    <div class="tabs" v-if="$root.isAuth && status !== 'finish'">
      <div class="tab" :class="{'active': betType === 'skin'}" @click="setBetType('skin')">СКИН</div>
      <div class="tab" :class="{'active': betType === 'balance'}" @click="setBetType('balance')">БАЛАНС</div>
    </div>
    <div class="finish-status" v-if="status === 'finish' && winItem">
      <strong class="fail" :class="{'fail': !winItem.isWin, 'success': winItem.isWin}">
        <template v-if="!winItem.isWin">
          Неудача :(
        </template>
        <template v-else>Вы выиграли!</template>
      </strong>
      <span v-if="!winItem.isWin">Ваш гарантированный приз</span>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      status: 'upgrade/status',
      winItem: 'upgrade/winItem',
      betType: 'upgrade/betType'
    })
  },
  methods: {
    ...mapActions({
      setBetType: 'upgrade/setBetType'
    })
  }
}
</script>
<style scoped lang="scss">
  .controls {
    width: 100%;
    background: url(@/assets/images/upgrade-top-bg.png);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 57px;
    padding-left: 15%;
    padding-top: 6px;
    @media (max-width: 1750px) {
      padding-left: 0;
    }
    position: relative;
    .finish-status {
      position: absolute;
      left: 0;
      bottom: 20px;
      width: 100%;
      strong,span {
        display: block;
        width: 100%;
        text-align: center;
      }
      strong {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        text-align: center;
        &.fail {
          color: rgba(237, 23, 74, 0.75);
        }
        &.success {
          color: #629E6F;
        }
      }
      span {
        font-size: 14px;
      }
    }
    .tabs {
      display: flex;
      .tab {
        padding: 13px 0;
        border-top: 2px solid transparent;
        font-weight: 800;
        font-size: 13px;
        line-height: 18px;
        cursor: pointer;
        color: #717380;
        transition: 0.2s;
        &:hover, &.active {
          font-weight: 800;
          font-size: 13px;
          line-height: 18px;
          border-color: #DAAA62;
          color: #DAAA62;
        }
      }
      .tab + .tab {
        margin-left: 33px;
      }
    }
  }
</style>