<template>
  <div class="market__panel">
    <div class="market__search">
      <input type="text" @input="$emit('search', $event)" placeholder="Поиск по названию">
      <div class="search_btn">
        <svg viewBox="0 0 16 16" fill="#fff" xmlns="http://www.w3.org/2000/svg" height="16" width="16" focusable="false">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.31467 13.3707C13.0069 13.3707 16 10.3775 16 6.68533C16 2.99313 13.0069 0 9.31467 0C5.62246 0 2.62934 2.99313 2.62934 6.68533C2.62934 8.21006 3.13977 9.61558 3.99909 10.7403L0.261079 14.4783C-0.087026 14.8264 -0.0870263 15.3908 0.261078 15.7389C0.609183 16.087 1.17357 16.087 1.52168 15.7389L5.25968 12.0009C6.38442 12.8602 7.78994 13.3707 9.31467 13.3707ZM9.31467 11.5879C6.60705 11.5879 4.41209 9.39295 4.41209 6.68533C4.41209 3.97771 6.60705 1.78276 9.31467 1.78276C12.0223 1.78276 14.2172 3.97771 14.2172 6.68533C14.2172 9.39295 12.0223 11.5879 9.31467 11.5879Z" fill="#fff" fill-opacity="1"></path>
        </svg>
      </div>
    </div>
    <div class="market__sorting" @click="changeOrder()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="order == 'desc'">
        <path d="M5 8H13" stroke="white" stroke-linecap="round"/>
        <path d="M5 12H11" stroke="white" stroke-linecap="round"/>
        <path d="M5 16H9" stroke="white" stroke-linecap="round"/>
        <path d="M19 18L22 15M19 18L16 15M19 18L19 6" stroke="white"/>
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
        <path d="M5 16H13" stroke="white" stroke-linecap="round"/>
        <path d="M5 12H11" stroke="white" stroke-linecap="round"/>
        <path d="M5 8H9" stroke="white" stroke-linecap="round"/>
        <path d="M19 6L22 9M19 6L16 9M19 6L19 18" stroke="white"/>
      </svg>
      <span>Цена</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      order: 'desc'
    }
  },
  methods: {
    changeOrder () {
      this.page = 0
      this.loading = true
      if (this.order === 'asc') {
        this.order = 'desc'
      } else {
        this.order = 'asc'
      }
    }
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.$emit('order', this.order)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.market {
  &__panel {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-left: 35px;
    display: flex;
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
  &__search {
    position: relative;
    input {
      outline: none;
      background: rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 5px;
      padding: 12px;
      width: 320px;
      &:focus,
      &:hover {
        outline: none;
        border: 1px solid rgba(255, 255, 255, .15);
        transition: border-color .2s cubic-bezier(.1, .7, .5, 1);
      }
    }
    .search_btn {
      position: absolute;
      width: 42px;
      height: 42px;
      right: 0;
      top: 0;
      cursor: pointer;
      &:hover svg {
        opacity: 0.7;
        transition: opacity .3s cubic-bezier(.1, .7, .5, 1);
      }
      svg {
        position: absolute;
        width: 19px;
        height: 19px;
        right: 13px;
        top: 12px;
        opacity: 0.2;
        cursor: pointer;
      }
    }
  }
  &__sorting {
    position: relative;
    background: #1C1E23;
    margin-left: 10px;
    padding: 9px 12px;
    width: 165px;
    height: 42px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    @media (max-width: 575px) {
      margin-left: 0;
      margin-top: 10px;
    }
    span {
      margin-left: 10px;
    }
  }
}
</style>