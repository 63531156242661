import { render, staticRenderFns } from "./ProfileUpgrades.vue?vue&type=template&id=5d902b73&scoped=true"
import script from "./ProfileUpgrades.vue?vue&type=script&lang=js"
export * from "./ProfileUpgrades.vue?vue&type=script&lang=js"
import style0 from "./ProfileUpgrades.vue?vue&type=style&index=0&id=5d902b73&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d902b73",
  null
  
)

export default component.exports