<template>
    <div id="app" :class="{'stop': isChatActive, 'pt0': $route.name === 'chat'}">
        <WorkInProgress v-if="!$root.user.isAdmin"></WorkInProgress>
        <template v-else>
            <template v-if="$route.name !== 'chat'">
                <HeaderTop></HeaderTop>
                <!--      <HeaderBottom v-if="$root.user.isAdmin"></HeaderBottom>-->
                <LiveDrop></LiveDrop>
            </template>
            <router-view v-if="!isLoading"/>

            <template v-if="$route.name !== 'chat'">
                <FooterBottom></FooterBottom>
            </template>
            <transition name="slide-fade" mode="out-in">
                <Chat v-show="isChatActive"></Chat>
            </transition>

            <transition name="slide-fade" mode="out-in">
                <DepositModal @close="toggleDeposit(false)" v-show="isDepositActive"/>
            </transition>


            <SubscribeChecker v-if="$root.isAuth"/>
            <SoundsListen/>
            <GlobalLoader v-show="loader"/>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DepositModal from "@/components/modals/DepositModal.vue";
import {mapMutations} from "vuex";
import SubscribeChecker from "@/components/SubscribeChecker.vue";
import SoundsListen from "@/components/SoundsListen.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";

export default {
    data() {
        return {
            isLoading: true,
        }
    },
    computed: {
        ...mapGetters({
            loader: 'config/loader',
            isChatActive: 'config/isChatActive',
            isDepositActive: 'config/isDepositActive',
            authStatus: 'user/authStatus',
            user: 'user/user',
            isAuthenticated: 'user/isAuthenticated',
        })
    },
    methods: {
        ...mapActions({
            getConfig: 'config/getConfig',
            getUser: 'user/getUser'
        }),
        ...mapMutations({
            toggleDeposit: 'config/toggleDeposit'
        })
    },
    async mounted() {
        if (this.isAuthenticated) {
            await this.getUser()
        }
        await this.getConfig()
        this.isLoading = false
    },
    components: {
        GlobalLoader,
        SoundsListen,
        SubscribeChecker,
        DepositModal,
        'WorkInProgress': () => import('./views/WorkInProgress.vue'),
        'HeaderTop': () => import('./components/main/HeaderTop.vue'),
        // 'HeaderBottom': () => import('./components/main/HeaderBottom.vue'),
        'Chat': () => import('./components/chat/ChatMain.vue'),
        'LiveDrop': () => import('./components/main/LiveDrop.vue'),
        'FooterBottom': () => import('./components/main/FooterBottom.vue')
    }
}
</script>
<style lang="scss">
#app {
    max-width: 100%;
    overflow: hidden;

    &.pt0 {
        padding-top: 0;
    }

    @media (max-width: 992px) {
        padding-top: 102px;
    }
    @media (max-width: 768px) {
        padding-top: 85px;
    }
    @media (max-width: 992px) {
        &.stop {
            overflow-y: hidden;
            height: 100vh;
        }
    }

    &.pt0 {
        padding-top: 0 !important;
    }
}

@import './styles/main.scss';
</style>
