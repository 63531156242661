<template>
  <div class="upgrade-inventory k">
    <UpgradeInventoryFilters :filters="filters" @search="search" title="Инвентарь" @toggleSort="toggleSort" />
    <div v-if="$root.isAuth" class="items__wrapper">

      <transition name="fade">
        <LoaderBlock v-if="isLoading" />
      </transition>

      <div v-if="!isLoading && (!inventory || !inventory.items.length)" class="not-found">
        ПО ДАННОМУ ЗАПРОСУ ПРЕДМЕТОВ НЕ НАЙДЕНО
      </div>
      <div v-else-if="inventory" class="items">
        <UpgradeItem :target-skin="firstSkin" @select="setFirstSkin" v-for="skin in inventory.items" :key="skin.id" :skin="{price: skin.price, internal_id: skin.id, status: skin.status, ...skin.item}" />
      </div>

      <div class="inventory__wrapper-pagination" v-if="allPages > filters.page">
        <div class="prev" @click="goPrev" :class="{'disabled': filters.page === 0}">Назад</div>
        <div class="counter">
          {{ filters.page + 1 }}/{{ allPages }}
        </div>
        <div class="next" @click="goNext" :class="{'disabled': filters.page + 1 === allPages}">Вперед</div>
      </div>
    </div>
    <UnauthorizationUpgrade v-else />
  </div>
</template>
<script>
import UpgradeInventoryFilters from "@/components/upgrade/UpgradeInventoryFilters.vue";
import UpgradeItem from "@/components/upgrade/UpgradeItem.vue";
import { debounce } from 'vue-debounce'
import {mapActions, mapGetters, mapMutations} from "vuex";
import LoaderBlock from "@/components/LoaderBlock.vue";
import UnauthorizationUpgrade from "@/components/upgrade/UnauthorizationUpgrade.vue";
export default {
  components: {
    UnauthorizationUpgrade,
    LoaderBlock,
    UpgradeInventoryFilters,
    UpgradeItem
  },
  computed: {
    ...mapGetters({
      firstSkin: 'upgrade/firstSkin',
      isLoading: 'inventory/isLoading',
      filters: 'inventory/filters',
      inventory: 'inventory/inventory'
    }),
    allPages () {
      if (!this.inventory) {
        return 0;
      }

      return Math.ceil(this.inventory.total / 20);
    }
  },
  methods: {
    toggleSort () {
      if (this.filters.priceSort === 'desc') {
        this.filters.priceSort = 'asc'
      } else {
        this.filters.priceSort = 'desc'
      }
    },
    search: debounce(function (value) {
      this.setPage(0)
      this.setSkip(0)
      this.setSearch(value)
      this.getInventory({ steamId: this.$root.user.steamId, options: {status: 'process'}})
    }, '200ms'),
    goPrev () {
      if (this.filters.page === 0) return;
      this.loadMore({ steamId: this.$root.user.steamId, push: false, isPrev: true, options: {status: 'process'} })
    },
    goNext () {
      if (this.filters.page + 1 === this.allPages) return;
      this.loadMore({steamId: this.$root.user.steamId, push: false, options: {status: 'process'}})
    },
    ...mapActions({
      loadMore: 'inventory/loadMore',
      getInventory: 'inventory/getInventory',
      setFirstSkin: 'upgrade/setFirstSkin',
    }),
    ...mapMutations({
      setPage: 'inventory/setPage',
      setSkip: 'inventory/setSkip',
      setSearch: 'inventory/setSearch'
    })
  },
  watch: {
    'filters.priceSort': {
      deep: true,
      async handler () {
        await this.setPage(0)
        await this.setSkip(0)
        await this.getInventory({ steamId: this.$root.user.steamId, options: {status: 'process'} })
      }
    }
  },
  async mounted () {
    await this.getInventory({ steamId: this.$root.user.steamId, options: {status: 'process'} })
  }
}
</script>
<style scoped lang="scss">
.upgrade-inventory {
  position: relative;
}
</style>