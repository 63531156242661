<template>
  <div class="upgrade__item">
    <div class="inject" :class="{'active': targetSkin && targetSkin.internal_id === skin.internal_id}">
      <button v-if="targetSkin && targetSkin.internal_id === skin.internal_id" @click="$emit('select', null)">
        Отмена
      </button>
      <button @click="$emit('select', skin)" v-if="(targetSkin && targetSkin.internal_id !== skin.internal_id) || !targetSkin">
        Выбрать
      </button>
    </div>
    <BaseSkin :skin="skin" />
  </div>
</template>
<script>
import BaseSkin from "@/components/BaseSkin.vue";
export default {
  components: {
    BaseSkin
  },
  props: {
    targetSkin: {
      required: true
    },
    skin: {
      type: Object,
      required: true
    },
  }
}
</script>
<style scoped lang="scss">
.upgrade__item {
  position: relative;
  .inject {
    position: absolute;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    &.active {
      visibility: visible;
      opacity: 1;
    }
    @media (max-width: 992px) {
      opacity: 1 !important;
      visibility: visible !important;
    }
    button {
      background: #DD9A36;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      padding: 9px 11px;
      font-weight: 800;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      color: #1D1929;
      transition: 0.2s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &:hover {
    .inject {
      visibility: visible;
      opacity: 1;
    }
  }
}
::v-deep {
  .inventory-item {
    height: 150px;
    .inventory-item__body {
      .image {
        height: 66px;
        margin-top: 15px;
        @media (max-width: 600px) {
          margin-top: 25px;
        }
      }
    }
    @media (max-width: 575px) {
      height: 160px;
    }
  }
}
</style>