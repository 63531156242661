<template>
  <div class="empty-inventory">
    <div class="text">
      <strong>{{ title }}</strong>
      <span v-if="!isPublic">{{ subtitle }}</span>
      <span v-else>Пользователь еще не открывал кейсы</span>
    </div>

    <router-link class="open" :to="link" v-if="!isPublic">{{ linktext }}</router-link>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: 'Нет предметов',
      type: String
    },
    subtitle: {
      default: 'Начните открывать кейсы',
      type: String
    },
    link: {
      default: '/',
      type: String
    },
    linktext: {
      default: 'Открыть',
      type: String
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
.empty-inventory {
  background: rgba(23, 24, 28, 0.45);
  padding: 50px 0;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  .text {
    padding: 33px 32px;
    background: url(@/assets/images/empty-bg.png);
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    strong {
      display: block;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-top: 3px;
      display: block;
      font-family: 'Inter', sans-serif;
      color: #717380;
    }
  }
  .open {
    background: #D06538;
    border-radius: 6px;
    padding: 18px 32px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>