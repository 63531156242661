import gql from 'graphql-tag'
export const GET_MY_PROFILE = gql`
  query account {
      account {
          promocode {
              type
              code
              prize
          }
          steamId
          name
          balance
          avatar
          tradeLink
          isAdmin
          chatMuted
          isBanned
          isModerator
          notifications {
              id
              type
              createdAt
              text {
                  en
                  ru
              }
              title {
                  en
                  ru
              }
          }
      }
  }
`

export const GET_CATEGORIES = gql`
    query categories {
        categories {
            id
            color
            position
            name {ru, en}
            cases {
                slug
                image
                count
                countOpen
                getImage
                priceOld
                price
                name {ru, en}
            }
        }
    }
`

export const GET_CHAT_MESSAGES = gql`
    query chatMessages {
        chatMessages {
            id
            chatLang
            text
            createdAt
            user {
                steamId
                name
                isModerator
                avatar
                chatMuted
                isBanned
            }
        }
    }
`

export const GET_USER_ONLINE = gql`
    query usersOnline {
        usersOnline
    }
`

export const GET_HIGHT_LIVE_DROP = gql`
    query lastHighPriceOpens {
        lastHighPriceOpens {
            id
            price
            createdAt
            item {
                name
                getImage
                color
            }
            case {
                color
                slug
                image
                price
                name {
                    en
                    ru
                }
            }
            user {
                steamId
                name
                avatar
            }
        }
    }
`

export const GET_TOP_OPEN = gql`
    query topDayOpen {
        topDayOpen {
            id
            price
            item {
                name
                getImage
                color
            }
            type
            case {
                slug
                image
                price
                name {ru, en}
            }
            user {
                avatar
                name
                steamId
            }
        }
    }
`

export const GET_FOOTER_STATS = gql`
    query {
        getContractCount
        getUserCount
        getUpgradeCount
        getOpenCount
    }
`

export const GET_LIVE_DROP = gql`
    query lastOpens {
        lastOpens {
            id
            price
            type
            createdAt
            item {
                name
                getImage
                color
            }
            case {
                slug
                image
                price
                name {ru, en}
            }
            user {
                avatar
                name
                steamId
            }
        }
    }
`


export const GET_CASE = gql`
    query case ($slug: String!) {
        case (slug: $slug) {
            id
            slug
            image
            price
            createdAt
            name {
                en
                ru
            }
            items {
                name
                getImage
                color
                id
                price
            }
            seo {
                title { ru en }
                description { ru en }
                keywords
            }
        }
    }
`

export const GET_BONUS = gql`
    query getPaymentBonus {
        getPaymentBonus {
            code,
            type,
            prize
        }
    }
`

export const GET_UPGRADES = gql`
    query getUpgrades ($take: Int = 10, $skip: Int = 0) {
        getUpgrades (take: $take, skip: $skip) {
            total
            items {
                items {
                    open {
                        id
                        price
                        createdAt
                        item {
                            id
                            name
                            image
                            price
                            color
                            quality
                            type
                            getImage
                        }
                    }
                }
                id
                isWin
                sum
                type
                open {
                    id
                    price
                    createdAt
                    item {
                        id
                        name
                        image
                        price
                        color
                        quality
                        type
                        getImage
                    }
                }
                percent
                item {
                    id
                    name
                    image
                    price
                    color
                    quality
                    type
                    getImage
                }
            }
        }
    }
`

export const GET_CONTRACTS = gql`
    query getContracts ($take: Int = 10, $skip: Int = 0) {
        getContracts (take: $take, skip: $skip) {
            total
            items {
                id
                sum
                createdAt
                open {
                    id
                    price
                    createdAt
                    item {
                        id
                        name
                        image
                        price
                        color
                        quality
                        type
                        getImage
                    }
                }
                items {
                    open {
                        id
                        price
                        createdAt
                        item {
                            id
                            name
                            image
                            price
                            color
                            quality
                            type
                            getImage
                        }
                    }
                }
            }
        }
    }
`

export const GET_PROFILE = gql`
    query profileStatistics ($steamId: String!) {
        profileStatistics (steamId: $steamId) {
            getFavoriteCase {
                slug
                image
                price
                name {
                    en
                    ru
                }
            }
            getBestDrop {
                id
                price
                createdAt
                item {
                    name
                    image
                    color
                }
            }
            opens
            registredAt
            contracts
            upgrades
        }
        profile (steamId: $steamId) {
            steamId
            name
            avatar
        }
    }
`

export const PROFILE = gql`
    query getProfile ($steamId: String!) {
        profile (steamId: $steamId) {
            steamId
            name
            avatar
        }
    }
`


export const GET_WITHDRAWS = gql`
    query withdrawHistory ($take: Int = 10, $skip: Int = 0) {
        withdrawHistory (take: $take, skip: $skip) {
            items {
                id
                type
                buyId
                tradeofferId
                price
                status
                createdAt
                item {
                    name
                    image
                    color
                }
            }
            total
        }
    }
`

export const GET_SITE_ITEMS = gql`
    query itemsList ($take: Int = 10, $skip: Int = 0, $name: String, $priceSort: SortOrder, $priceFrom: Float, $priceTo: Float) {
        itemsList (take: $take, skip: $skip, name: $name, priceSort: $priceSort, priceFrom: $priceFrom, priceTo: $priceTo) {
            items {
                name
                getImage
                color
                id
                price
            }
            total
        }
    }
`

// export const GET_NOTIFICATIONS = gql`
//     query notifications {
//         notifications {
//             id
//             type
//             createdAt
//             text {
//                 en
//                 ru
//             }
//             title {
//                 en
//                 ru
//             }
//         }
//     }
// `

// export const GET_INVENTORY = gql`
//     query userInventory ($steamId: String!, $take: Int = 10, $skip: Int = 0) {
//         userInventory (steamId: $steamId, take: $take, skip: $skip) {
//             items {
//                 id
//                 price
//                 createdAt
//                 item {
//                     name
//                     image
//                     color
//                 }
//                 status
//                 withdraw {
//                     id
//                     buyId
//                     tradeofferId
//                     status
//                 }
//             }
//             total
//         }
//     }
// `

// export const GET_PROCESS_INVENTORY = gql`
//     query processInventory ($take: Int = 10, $skip: Int = 0, $priceSort: SortOrder, $priceFrom: Float, $priceTo: Float) {
//         processInventory (take: $take, skip: $skip, priceSort: $priceSort, priceFrom: $priceFrom, priceTo: $priceTo) {
//             items {
//                 id
//                 price
//                 createdAt
//                 item {
//                     name
//                     image
//                     color
//                 }
//                 status
//                 withdraw {
//                     id
//                     buyId
//                     tradeofferId
//                     status
//                 }
//             }
//             total
//         }
//     }
// `

export const GET_INVENTORY = gql`
    query inventory ($take: Int = 10, $skip: Int = 0, $priceSort: SortOrder, $priceFrom: Float, $priceTo: Float, $status: OpenStatusEnum, $steamId: String!) {
        inventory(take: $take, skip: $skip, priceSort: $priceSort, priceFrom: $priceFrom, priceTo: $priceTo, status: $status, steamId: $steamId) {
            items {
                id
                price
                createdAt
                item {
                    name
                    quality
                    getImage
                    color
                    id
                    image
                }
                status
                withdraw {
                    id
                    buyId
                    tradeofferId
                    status
                }
            }
            total
        }
    }
`

export const MARKET_PRICE_LIST = gql`
    query marketPrices {
        marketPrices {
            item {
                type
                name
                getImage
                color
                quality
                rarity
                id
                price
            }
            count
        }
    }
`



export const GET_CONFIG = gql`
    query config {
        config {
            tgLink
            vkLink
            getPaymentConfig {
                enot
                freekassa
                paypalych
                course
            }
            getUpgradeConfig {
                upgradeMinSum
            },
            title { ru en }
            description { ru en }
            keywords
            siteUrl
            siteName
        }
    }
`
