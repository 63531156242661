import {apolloClient} from "@/vue-apollo";
import {GET_INVENTORY} from "@/queries";
import {SELL_ITEMS, WITHDRAW_ITEM} from "@/queries/mutations";
import { createToastInterface } from 'vue-toastification'

const toast = createToastInterface()

export default {
    namespaced: true,
    state: {
        isLoading: false,
        inventory: null,
        filters: {
            page: 0,
            take: 20,
            priceSort: null,
            skip: 0,
            priceFrom: 0,
            priceTo: 99999,
        },
        onlyAvailableToWithdraw: false,
        search: null
    },
    getters: {
        isLoading: state => state.isLoading,
        filters: state => state.filters,
        onlyAvailableToWithdraw: state => state.onlyAvailableToWithdraw,
        inventory: state => state.inventory,
        search: state => state.search,
        showMoreEnabled: (state) => {
            if (!state.inventory) {
                return false;
            }
            return state.inventory.total > state.inventory.items.length
        }
    },
    mutations: {
        setSearch (state, value) {
            state.search = value
        },
        setInventory (state, inventory) {
            state.inventory = inventory
        },
        setPage (state, page) {
            state.filters.page = page
        },
        setSkip (state, skip) {
            state.filters.skip = skip
        },
        pushInventory (state, inventory) {
            inventory.__typename = state.inventory.__typename
            state.inventory.items = [...state.inventory.items, ...inventory.items]
        },
        setOnlyAvailableToWithdraw (state, value) {
            state.filters.page = 0
            state.filters.skip = 0
            state.onlyAvailableToWithdraw = value
        },
        updateSkinsStatus (state, { status, ids }) {
            if (!state.inventory) {
                return;
            }
            if (!ids.length) {
                state.inventory.items = state.inventory.items.filter(item => item.status === 'process').map(item => {
                    return {...item, status: status}
                })
            }

            for (const item in ids) {
                const index = state.inventory.items.findIndex(i => i.id === ids[item])

                if (index > -1) {
                    state.inventory.items[index].status = status
                }
            }
        },
        assignWithdraw (state, {withdraw, id}) {
            const index = state.inventory.items.findIndex(i => i.id === id)

            if (index > -1) {
                state.inventory.items[index].withdraw = withdraw
            }
        },
        setLoading (state, value) {
            state.isLoading = value
        },
        resetLoad (state) {
            state.search = null
            state.filters = {
                page: 0,
                take: 20,
                priceSort: null,
                skip: 0,
                priceFrom: 0,
                priceTo: 99999,
            }
            state.onlyAvailableToWithdraw = false
        },
        setPriceDiapason (state, diapason) {
            state.filters.priceFrom = parseFloat(diapason.priceFrom)
            state.filters.priceTo = parseFloat(diapason.priceTo)
        }
    },
    actions: {
        async setPriceDiapason ({commit, dispatch, rootGetters}, sum) {
            await commit('setPriceDiapason', {
                priceFrom: parseFloat(sum * 0.05).toFixed(2),
                priceTo: parseFloat(sum * 0.80).toFixed(2)
            })

            await dispatch('getInventory', {steamId: rootGetters["user/user"].steamId, options: {status: 'process'}})
        },
        async resetLoad ({commit, dispatch, rootGetters}, options) {
            await commit('resetLoad')
            const params = Object.assign({}, { options: options }, { steamId: rootGetters["user/user"].steamId})
            await dispatch('getInventory', params)
        },
        async loadMore ({dispatch, getters, commit}, { steamId, isPrev = false, push = true, options = null }) {
            let page = getters.filters.page

            if (!isPrev) {
                page = getters.filters.page + 1
            } else {
                page = getters.filters.page - 1
            }

            await commit('setPage', page)
            await commit('setSkip', getters.filters.take * page)
            await dispatch('getInventory', { steamId, push, options })
        },
        async getInventory ({ commit, dispatch, getters }, { steamId, push = false, options }) {
            await commit('setLoading', true)
            await dispatch('upgrade/setFirstSkin', null, {root: true})
            // if (rootGetters["upgrade/secondSkin"]) {
            //     await dispatch('shop/resetLoad', null, {root: true})
            // }
            let variables = getters.filters
            variables.steamId = steamId

            if (getters.onlyAvailableToWithdraw) {
                variables.status = 'process'
            } else {
                delete variables.status
            }

            if (getters.search) {
                variables.name = getters.search
            } else {
                delete variables.name
            }
            if (options) {
                variables = Object.assign({}, options, variables)
            }

            const { data } = await apolloClient.query({ fetchPolicy: 'network-only', query: GET_INVENTORY, variables })

            if (data.inventory) {
                if (push) {
                    await commit('pushInventory', data.inventory)
                } else {
                    await commit('setInventory', data.inventory)
                }
            } else {
                if (data.error) {
                    data.error.graphQLErrors.map(item => {
                        toast.error(item.message);
                    })
                }

                return false;
            }

            await commit('setLoading', false)
        },
        async sellSkins ({commit, dispatch}, ids) {
            const variables = {}

            if (Array.isArray(ids) && ids.length > 0) {
                variables.openIds = ids;
            }

            try {
                const { data } = await apolloClient.mutate({ mutation: SELL_ITEMS, variables })

                let sum = 0

                data.inventoryItemSell.forEach(item => {
                    sum += parseFloat(item.price)
                })

                if (data.inventoryItemSell.length > 1) {
                    toast(`Вы успешно продали ${data.inventoryItemSell.length} предметов на сумму ${parseFloat(sum).toFixed(2)} $`);
                } else {
                    toast.success(`Вы успешно продали предмет за ${parseFloat(sum).toFixed(2)} $`);
                }

                if (ids.length === 0) {
                    dispatch('resetLoad');
                } else {
                    commit('updateSkinsStatus', {status: 'sold', ids})
                }

            } catch (error) {
                if (!error.graphQLErrors.length) {
                    toast.error(`Произошла ошибка, возможно ${ids.length > 1 ? 'предметы не доступны' : 'предмет не доступен'} для продажи.`);
                } else {
                    error.graphQLErrors.map(item => {
                        toast.error(item.message);
                    })
                }
                return false
            }

            return true;
        },
        async withdrawSkin ({commit}, openId) {
            await apolloClient.mutate({ mutation: WITHDRAW_ITEM, variables: {openId} }).then((data) => {
                toast.success('Запрос на вывод предмета отправлен.');
                commit('assignWithdraw', { withdraw: data.data.withdrawCreate, id: openId })
                commit('updateSkinsStatus', {status: 'withdrawing', ids: [openId]})
            }).catch(error => {
                //console.log(error)
                //console.log(error.graphQLErrors)
                if (!error.graphQLErrors.length) {
                    toast.error('Произошла ошибка, возможно предмет недоступен для вывода.');
                } else {
                    error.graphQLErrors.map(item => {
                        toast.error(item.message);
                    })
                }
            })
        },
        async buySkin ({commit}, openId) {
            await apolloClient.mutate({ mutation: WITHDRAW_ITEM, variables: {openId} }).then((data) => {
                toast.success('Запрос на покупку предмета отправлен.');
                commit('assignWithdraw', { withdraw: data.data.withdrawCreate, id: openId })
                commit('updateSkinsStatus', {status: 'withdrawing', ids: [openId]})
            }).catch(error => {
                //console.log(error)
                //console.log(error.graphQLErrors)
                if (!error.graphQLErrors.length) {
                    toast.error('Произошла ошибка, возможно предмет недоступен для покупки.');
                } else {
                    error.graphQLErrors.map(item => {
                        toast.error(item.message);
                    })
                }
            })
        }
    },
}