<template>
  <div class="upgrade">
    <ControlUpgrade />
    <UpgradeGame />
    <BottomUpgrade  />
    <UpgradeInventories />
  </div>
</template>
<script >
import ControlUpgrade from "@/components/upgrade/ControlUpgrade.vue";
import UpgradeGame from "@/components/upgrade/UpgradeGame.vue";
import BottomUpgrade from "@/components/upgrade/BottomUpgrade.vue";
import UpgradeInventories from "@/components/upgrade/UpgradeInventories.vue";
import {mapActions, mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters({
      status: 'upgrade/status'
    })
  },
  methods: {
    ...mapActions({
      reset: 'upgrade/reset'
    })
  },
  components: {
    ControlUpgrade,
    UpgradeGame,
    BottomUpgrade,
    UpgradeInventories
  },
  async mounted () {
    await this.reset()
  }
}
</script>
<style scoped lang="scss">
.upgrade {
  padding: 0 200px;
  margin: 57px 0;
  @media (max-width: 575px) {
    margin: 20px 0;
    margin-bottom: 0;
  }
  @media (max-width: 1750px) {
    padding: 0 100px;
  }
  @media (max-width: 1400px) {
    padding: 0 15px;
  }
}
</style>