<template>
  <div class="case__open">
    <div class="roulettes-wrapper">
      <RoulettesCase />
    </div>
    <CaseControlPanel></CaseControlPanel>
  </div>
</template>
<script>
import RoulettesCase from "@/components/open-case/RoulettesCase.vue";
import {mapGetters, mapMutations} from "vuex";
import {OPEN_CASE} from "@/queries/mutations";
import {eventBus} from "@/helpers/eventBus";

export default {
  watch: {
    'status': async function () {
      if (this.status === 'prepare') {
        await this.$apollo.mutate({
          mutation: OPEN_CASE,
          variables: {
            caseId: this.caseInstance.id,
            count: this.count
          }
        }).then(async (response) => {
          for (const item in response.data.caseOpen) {
            this.addPrize(response.data.caseOpen[item])
          }
        }).catch((error) => {
          error.graphQLErrors.map(item => {
            this.$toast.error(item.message);
          })
          this.setStatus('wait')
          // this.loading = false
        })
      }
    },
    'prizes.length': function () {
      if (this.prizes.length === this.count) {
        eventBus.$emit('case.load-results')
        this.$nextTick(() => {
          if (!this.isFast) {
            this.$root.$emit('SoundPlay', 'scroll')
          }
        })
        setTimeout(() => {
          this.$root.$emit('SoundPlay', 'open')
          this.setStatus('finish')
        }, this.isFast ? 0 : 5200)
      }
    }
  },
  methods: {
    ...mapMutations({
      setStatus: 'open/setStatus',
      addPrize: 'open/addPrize'
    })
  },
  computed: {
    ...mapGetters({
      status: 'open/status',
      isFast: 'open/isFast',
      count: 'open/count',
      prizes: 'open/prizes',
      caseInstance: 'open/caseInstance'
    }),

  },
  components: {
    RoulettesCase,
    CaseControlPanel: () => import('./CaseControlPanel.vue')
  }
}
</script>
<style scoped lang="scss">
.case__open {
}
.roulettes-wrapper {
  padding: 15px 0;
  position: relative;
}
</style>