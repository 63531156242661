<template>
  <div class="sounds"></div>
</template>
<script>
export default {
  data () {
    return {
      cashout: new Audio('/sounds/cashout.wav'),
      open: new Audio('/sounds/open.mp3'),
      upgrade_spin: new Audio('/sounds/upgrade_spin.mp3'),
      upgrade_win: new Audio('/sounds/upgrade_win.mp3'),
      upgrade_lose: new Audio('/sounds/upgrade_lose.mp3'),
      scroll: new Audio('/sounds/scroll.mp3')
    }
  },
  mounted () {
    this.$root.$on('SoundPlay', (sound) => {
      //console.log(sound)
      const isDisabled = localStorage.getItem('soundDisabled') ? parseInt(localStorage.getItem('soundDisabled')) : 0
      if (!isDisabled) {
        this[sound].volume = 0.2
        this[sound].play()
      }
    })
  }
}
</script>

