<template>
  <div class="unauthorization-chat">
    <p>
      Чтобы писать в чат необходимо <a @click="login">авторизоваться</a>
    </p>
  </div>
</template>
<script>
import {LOGIN_URI} from "@/const/ENDPOINTS";

export default {
  methods: {
    login () {
      window.location.href = LOGIN_URI
    }
  }
}
</script>
<style scoped lang="scss">
.unauthorization-chat {
  padding: 30px 30px;
  p {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Inter', sans-serif;
    color: #717380;
    width: 100%;
    a {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      color: #ED174A;
    }
  }
}
</style>