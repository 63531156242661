<template>
<div class="wrapper">
  <h1>Ежедневные розыгрыши</h1>
  <PrizeNow :prizes="prizes" v-if="$route.name === 'prizes'"></PrizeNow>
  <LastPrize :lastPrizes="lastPrizes" v-else-if="$route.name === 'winners'"></LastPrize>
  <button class="link" @click="$route.name === 'winners' ? $router.push('/prizes') : $router.push('/winners')">{{ getBtnText }}</button>

  <div class="faq_list">
    <div class="accordion" role="tablist">
      <div v-for="faq in faq_list" :key="faq.id" class="faq__item">
        <div @click.prevent.stop="toggleOpen(faq.id)" class="faq__item-preview">
          <template v-if="!opens.includes(faq.id)">
            <svg data-v-b9d007fe="" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle data-v-b9d007fe="" cx="24" cy="24" r="23.5" stroke="#717380"></circle>
              <line data-v-b9d007fe="" x1="16" y1="24" x2="32" y2="24" stroke="white" stroke-width="2"></line>
              <line data-v-b9d007fe="" x1="24" y1="32" x2="24" y2="16" stroke="white" stroke-width="2"></line>
            </svg>
          </template>
          <template v-else>
            <svg data-v-b9d007fe="" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle data-v-b9d007fe="" cx="24" cy="24" r="23.5" stroke="#717380"></circle>
              <line data-v-b9d007fe="" x1="16" y1="24" x2="32" y2="24" stroke="white" stroke-width="2"></line>
            </svg>
          </template>

          {{ faq.title }}
        </div>
        <transition name="slide-fade">
          <div class="faq__item-content" v-if="opens.includes(faq.id)">
            {{ faq.text }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import PrizeNow from "@/components/prizes/PrizeNow.vue";
import LastPrize from "@/components/prizes/LastPrize.vue";

export default {
  data () {
    return {
      opens: [],
      prizes: [
        {
          caseLink: "/case/milspec",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          users: [
            {
              name: "Juicy Drop_User",
              count: 549,
              link: "/profile",
              topUser: true
            },
            {
              name: "Juicy Drop_User 2",
              link: "/profile",
              count: 356
            },
            {
              name: "Juicy Drop_User 3",
              link: "/profile",
              count: 234
            }
          ]
        },
        {
          caseLink: "/case/classified",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          users: [
            {
              name: "Juicy Drop_User",
              count: 549,
              link: "/profile",
              "topUser": true
            }
          ]
        },
        {
          caseLink: "/case/restricted",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          users: [
            {
              name: "Juicy Drop_User",
              count: 549,
              link: "/profile",
              topUser: true
            },
            {
              name: "Juicy Drop_User 2",
              link: "/profile",
              count: 356
            }
          ]
        },
        {
          caseLink: "/case/covert",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          users: [
            {
              name: "Juicy Drop_User",
              count: 549,
              link: "/profile",
              topUser: true
            },
            {
              name: "Juicy Drop_User 2",
              link: "/profile",
              count: 356
            },
            {
              name: "Juicy Drop_User 3",
              link: "/profile",
              count: 234
            },
            {
              name: "Juicy Drop_User 4",
              link: "/profile",
              count: 127
            }
          ]
        }
      ],
      lastPrizes: [
        {
          caseLink: "/case/milspec",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          user: {
            name: "Juicy Drop_User",
            link: '/profile'
          }
        },
        {
          caseLink: "/case/classified",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          user: {
            name: "Juicy Drop_User",
            link: '/profile'
          }
        },
        {
          caseLink: "/case/restricted",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          user: {
            name: "Juicy Drop_User",
            link: '/profile'
          }
        },
        {
          caseLink: "/case/covert",
          skin: {
            color: "4b69ff",
            getImage: "https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq4yekPD1NL7ug3hBpdZOh-zF_Jn4xgTlqEc5YW2lIteQcVJqaF3Tr1a9w7rq1sS5uJrNynoxvXN04SyPnBWpwUYbNdd-GLk",
            id: 77810,
            name: "USP-S | Business Class",
            price: 19.87
          },
          user: {
            name: "Juicy Drop_User",
            link: '/profile'
          }
        },
      ],
      faq_list: [
        {
          id: 0,
          title: "Как победить?",
          text: "Чтобы получить предмет, необходимо открыть армейский, запрещённый, засекреченный, или тайный кейс больше остальных пользователей за сутки."
        },
        {
          id: 1,
          title: "Хочу тайное!",
          text: "Например, чтобы получить предмет качества 'Тайное', откройте сегодня с 00:01 до 23:59 наибольшее количество тайных кейсов."
        },
        {
          id: 2,
          title: "Как забрать?",
          text: "Предметы нужно вывести в профиле! Удачи!"
        },
      ]
    }
  },
  methods: {
    toggleOpen (id) {
      if (this.opens.includes(id)) {
        const index = this.opens.findIndex(item => item === id)
        this.opens.splice(index, 1)
      } else {
        this.opens.push(id)
      }
    }
  },
  computed: {
    getBtnText () {
      if(this.$route.name === 'prizes') { return 'Победители' }
      else if(this.$route.name === 'winners') { return 'Текущий розыгрыш' }
      return '';
    }
  },
  components: {
    PrizeNow,
    LastPrize
  },
  mounted() {
    if(!this.$root.user.isAdmin) {
      location.replace('/');
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
  }
  .link {
    position: relative;
    width: 210px;
    height: 58px;
    align-items: center;
    justify-content: center;
    padding: 18px 32px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    background: #d06538;
    white-space: nowrap;
    border-radius: 6px;
    left: calc(50% - 105px);
    transition: .2s;
    margin-bottom: 80px;
    &:hover {
      background: #f1682d;
    }
  }
  .faq_list {
    max-width: 1690px;
    margin: 0 auto;
    .accordion {
      display: flex;
      flex-direction: column;
      .faq__item {
        margin-bottom: 12px;

        .faq__item-content {
          background: linear-gradient(90deg, rgba(32, 34, 38, 0.6) 0%, rgba(32, 34, 38, 0) 100%);
          padding: 20px 15px;
          font-size: 16px;
        }
        .faq__item-preview {
          cursor: pointer;
          transition: 0.2s;
          border-radius: 5px;
          background: #17181C;
          padding: 20px;
          &:hover {
            opacity: 0.8;
          }
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          line-height: 15px;
        }
        svg {
          margin-right: 20px;
        }
        .collapse {
          margin: 15px 15px 0 65px;
        }
        .header {
          position: relative;
          cursor: pointer;
          .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 15px;
            display: flex;
            align-items: center;
            svg {
              margin-right: 20px;
            }
            &.not-collapsed svg {
              line {
                stroke: #D06538;
              }
              line:nth-child(3) {
                display: none;
              }
              circle {
                stroke: #D06538;
              }
            }
          }
        }
        .body {
          .text {
            font-weight: 400;
            font-size: 15px;
            line-height: 15px;
            color: #717380;
          }
        }
      }
    }
  }
}
</style>