<template>
  <div class="upgrade-game">
    <SelectedItem :first="true" v-if="status !== 'finish'" :skin="firstSkin" />
    <UpgradeCircle />
    <SelectedItem v-if="status !== 'finish'" :skin="secondSkin" />
  </div>
</template>
<style scoped lang="scss">
.upgrade-game {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  grid-gap: 140px;
  @media (max-width: 1300px) {
    grid-gap: 100px;
  }
  @media (max-width: 1200px) {
    grid-gap: 50px;
  }
  @media (max-width: 1100px) {
    grid-gap: 20px;
  }
  @media (max-width: 992px) {
    grid-gap: 5px;
  }
  @media (max-width: 650px) {
    padding-bottom: 130px;
  }
  ::v-deep {
    .selected__item {
      bottom: 0px;
      @media (max-width: 650px) {
        &:first-child {
          left: calc(50% - 160px)
        }
        &:last-child {
          right: calc(50% - 160px)
        }
      }
    }
  }
}
</style>
<script>
import SelectedItem from "@/components/upgrade/SelectedItem.vue";
import UpgradeCircle from "@/components/upgrade/UpgradeCircle.vue";
import {mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters({
      status: 'upgrade/status',
      firstSkin: 'upgrade/firstSkin',
      secondSkin: 'upgrade/secondSkin'
    })
  },
  components: {
    SelectedItem,
    UpgradeCircle
  }
}
</script>