<template>
  <div class="case" v-if="caseInstance">
    <div class="case-container">
      <div class="go-back" @click="$router.push('/')">
        <button>
          <img src="@/assets/icons/back.svg" alt="">
        </button>
        <span>{{ caseInstance.name.ru }}</span>
      </div>

      <PrepareOpenCase v-if="status !== 'finish' && caseLoaded" />
      <FinishOpenCase v-else />

      <div class="skins">
        <SubTitle title="СОДЕРЖИМОЕ КЕЙСА" />
        <div class="list">
          <BaseSkin :skin="skin" v-for="skin in filterCases()" :key="skin.id"></BaseSkin>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import BaseSkin from "@/components/BaseSkin.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SubTitle from "@/components/SubTitle.vue";
import PrepareOpenCase from "@/components/open-case/PrepareOpenCase.vue";
import FinishOpenCase from "@/components/open-case/FinishOpenCase.vue";

export default {
  props: ['slug'],
  data () {
    return {
      caseLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      status: 'open/status',
      caseInstance: 'open/caseInstance'
    })
  },
  methods: {
    ...mapActions({
      loadCaseData: 'open/loadCaseData'
    }),
    ...mapMutations({
      setTitle: 'config/setTitle',
      resetGame: 'open/resetGame'
    }),
    filterCases() {
      const skins = this.caseInstance.items.slice();
      const skinQuantity = { 'eb4b4b': 6, 'd32ce6': 5, '8847ff': 4, '4b69ff': 3, '5e98d9': 2, 'b0c3d9': 1 };

      return skins.sort((a, b) => {
        const quantityA = skinQuantity[a.color];
        const quantityB = skinQuantity[b.color];

        if (quantityA < quantityB) {
          return -1;
        }
        if (quantityA > quantityB) {
          return 1;
        }

        return 0;
      });
    },
  },
  components: {
    FinishOpenCase,
    PrepareOpenCase,
    SubTitle,
    BaseSkin
  },
  async mounted () {
    await this.loadCaseData(this.slug).then(() => {
      this.caseLoaded = true
    })
    await this.resetGame()
    this.$nextTick(() => {
      this.setTitle(this.caseInstance.seo.title.ru)
    })
  },
  watch: {
    async $route() {
      this.caseLoaded = false
      await this.loadCaseData(this.slug).then(() => {
        this.caseLoaded = true
      })
      await this.resetGame()
      this.$nextTick(() => {
        this.setTitle(this.caseInstance.seo.title.ru)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.case {
  padding: 40px 0;

  @media (max-width: 1420px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (max-width: 575px) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .case-container {
    width: 1400px;
    margin: 0 auto;
    max-width: 100%;
    @media (max-width: 575px) {

    }
    .go-back {
      button {
        width: 58px;
        height: 58px;
        min-width: 58px;
        margin-right: 58px;
        display: flex;
        background: #141518;
        border-radius: 6px;
        transition: 0.2s;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.8;
        }
        @media (max-width: 575px) {
          height: 27px;
          width: 46px;
          background: #212226;
        }
      }
      @media (max-width: 575px) {
        padding: 17px;

      }
      span {
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        @media (max-width: 575px) {
          font-size: 20px;
          line-height: 27px;
        }
      }
      display: flex;
      align-items: center;
    }
    .main {
      margin-top: 3px;
      display: flex;
      overflow: hidden;
      align-items: flex-start;
      @media (max-width: 575px) {
        margin-top: -7px;
      }
      .skin-image {
        margin-top: 15px;
        background: #141518;
        border-radius: 6px;
        width: 336px;
        height: 340px;
        padding: 13px;
        min-width: 336px;
        margin-right: 32px;
        img {
          width: 100%;
          max-height: 80%;
        }
      }
      .skin-wrapper {
        flex-grow: 1;
        overflow: hidden;
      }
    }
    .skins {
      .list {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 18px;
        @media (max-width: 1300px) {
          grid-template-columns: repeat(6, 1fr);
        }
        @media (max-width: 1040px) {
          grid-template-columns: repeat(5, 1fr);
        }
        @media (max-width: 992px) {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 700px) {
          grid-gap: 4px;
          grid-template-columns: repeat(3, 1fr);
          ::v-deep {
            .inventory-item {
              height: 140px;
              .inventory-item__body .image {
                height: 60px;
                margin-top: 20px;
              }
            }
          }
        }
        @media (max-width: 575px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
</style>
