import gql from 'graphql-tag'

export const NEW_CHAT_MESSAGE = gql`subscription ChatMessageStream {
    ChatMessageStream {
        type
        message {
            id
            chatLang
            text
            createdAt
            user {
                isModerator
                name
                avatar
                chatMuted
                isBanned
            }
        }
    }
}`;


export const BALANCE_UPDATE = gql`subscription UserBalanceStream {
    UserBalanceStream {
        balance
    }
}`


export const USER_CHECKER = gql`subscription UserStatusCheckerStream {
    UserStatusCheckerStream {
        date
    }
}`

export const USERS_ONLINE = gql`subscription UsersOnlineStream {
    UsersOnlineStream
}`

export const LIVE_DROP_STREAM = gql`subscription OpenStream {
    OpenStream {
        id
        price
        createdAt
        type
        item {
            name
            getImage
            color
        }
        case {
            slug
            image
            price
            name {ru}
        }
        user {
            avatar
            name
            steamId
        }
    }
}`


export const WITHDRAW_UPDATES = gql`subscription WithdrawUpdatesStream {
    WithdrawUpdatesStream {
        id
        buyId
        tradeofferId
        status
    }
}`

export const NOTIFY_STREAM = gql`subscription NotifyStream {
    NotifyStream {
        id
        type
        createdAt
        text {
            en
            ru
        }
        title {
            en
            ru
        }
    }
}`
