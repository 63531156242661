<template>
  <div class="layout-prizes">
    <p>Разыгрываем сегодня</p>
    <div class="prizes_list">
      <div class="prize" v-for="(prize, i) in prizes" :key="i">
        <router-link :to="prize.caseLink" class="item">
          <BaseSkin :skin="prize.skin" />
        </router-link>
        <div class="users" v-for="(user, i) in prize.users" :key="i">
          <PrizeUsers :user="user"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSkin from "@/components/BaseSkin";
import PrizeUsers from "@/components/prizes/PrizeUsers";

export default {
  props: {
    prizes: {
      type: Array,
      required: true
    }
  },
  components: {
    BaseSkin,
    PrizeUsers
  }
}
</script>

<style scoped lang="scss">
.layout-prizes {
  p {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #C2AC5B;
  }
  .prizes_list {
    display: grid;
    grid-template-columns: 265px 265px 265px 265px;
    grid-gap: 40px;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 40px;
    .prize {
      background: rgba(23, 24, 28, 0.9);
      border: 1px solid rgba(58, 59, 69, 0.5);
      border-radius: 15px;
      min-height: 636px;
      .item {
        margin: 15px 40px;
        display: block;
      }
    }
  }
}
</style>