<template>
  <div class="deposit">
    <div class="head">
      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.37501 9.47917V9.47917C4.37501 8.87913 4.37501 8.57911 4.41779 8.32851C4.63204 7.07347 5.61514 6.09036 6.87019 5.87611C7.12079 5.83333 7.42081 5.83333 8.02084 5.83333L28.1667 5.83333C28.6381 5.83333 28.8738 5.83333 29.0202 5.97978C29.1667 6.12623 29.1667 6.36193 29.1667 6.83333V7.125C29.1667 9.95343 29.1667 11.3676 28.288 12.2463C27.4093 13.125 25.9951 13.125 23.1667 13.125H21.875M4.37501 9.47917V9.47917C4.37501 10.0792 4.37501 10.3792 4.41779 10.6298C4.63204 11.8849 5.61514 12.868 6.87019 13.0822C7.12079 13.125 7.4208 13.125 8.02084 13.125L28.625 13.125C29.5678 13.125 30.0392 13.125 30.3321 13.4179C30.625 13.7108 30.625 14.1822 30.625 15.125L30.625 18.9583M4.37501 9.47917L4.37501 26.625C4.37501 28.5106 4.37501 29.4534 4.9608 30.0392C5.54658 30.625 6.48939 30.625 8.37501 30.625L28.625 30.625C29.5678 30.625 30.0392 30.625 30.3321 30.3321C30.625 30.0392 30.625 29.5678 30.625 28.625L30.625 24.7917M30.625 24.7917H23.875C22.9322 24.7917 22.4608 24.7917 22.1679 24.4988C21.875 24.2059 21.875 23.7345 21.875 22.7917V20.9583C21.875 20.0155 21.875 19.5441 22.1679 19.2512C22.4608 18.9583 22.9322 18.9583 23.875 18.9583H30.625M30.625 24.7917L30.625 18.9583" stroke="white" stroke-width="2"/>
      </svg>
      <h1>Пополнение баланса</h1>
    </div>
    <div class="deposit__wrapper">
      <div class="deposit__methods">
        <div class="title">Способы оплаты</div>
        <div class="contents">
          <div class="payment-select" v-for="payment in getMethods" :key="payment.id" :class="{'active': selectMethod === payment.method, 'popular': payment.label === 'popular'}" @click="selectPayment = payment;selectMethod = payment.method">
            <img :src="payment.img">
            <span class="label" v-if="payment.label">{{ payment.label }}</span>
          </div>
        </div>
      </div>

      <div class="deposit__sum">
        <div class="skinback__wrapper" v-if="selectPayment.type === 'skinback'">
          <p>Для совершения обмена вы будете перенаправлены на skinsback.com. Баланс будет зачислен после успешного завершения обмена.</p>
        </div>
        <div class="title">Выберите сумму</div>
        <div class="contents">
          <div class="sum-val" v-for="sum in getSum" :key="sum.id" :class="{'active': selectSum === sum.id, 'disabled': selectPayment.disableMinSum && disabledSum.includes(sum.id)}" @click="changeSum(sum)">
            <span>$</span>{{ sum.value }}
          </div>
        </div>
        <div class="inputs">
          <div class="input-group">
            <label>Введите сумму</label>
            <span class="course">1 USD = {{ course }} RUB</span>
            <div class="input-wrap">
              <input v-model.number="amount" type="number" @input="changeAmount = amount">
              <div class="exchange"><div class="icon"></div></div>
              <input type="number" readonly v-model.number="amountRub">
            </div>
          </div>
          <div class="input-group">
            <label>Вы получите</label>
            <div class="input-wrap bonus">
              <span class="game-cur">$</span>{{ amount }}
              <template v-if="$root.user && $root.user.promocode">
                <span class="plus">+</span>
                <span class="free-cur" >$</span>{{ (amount * ($root.user.promocode.prize / 100)).toFixed(2) }} бонус
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="deposit__result">
        <div class="title referral" v-if="$root.user && $root.user.referral_code">
          <p>Активен реферальный код <span>“{{ $root.user.referral_code }}”</span></p>
          <div class="referral_bonus">+ 5% бонус</div>
        </div>
        <div class="title referral" v-else>
<!--          <p>Активируйте реферальный код для дополнительного бонуса</p>-->
          <p>&nbsp;</p>
        </div>
        <div class="res_block result-method">
          <div class="left">
            <span>ВЫБРАННЫЙ СПОСОБ ОПЛАТЫ</span>
            <p>{{ selectPayment.name }}</p>
          </div>
          <div class="right">
            <img :src="selectPayment.img">
          </div>
        </div>
        <div class="res_block result-fields">
          <div class="promocode">
            <div class="input-wrap">
              <input type="text" :disabled="$root.user && $root.user.promocode" placeholder="Промокод" v-model="promocode">
              <div class="percent" v-if="$root.user && $root.user.promocode"> +{{ $root.user.promocode.prize }}% к пополнению</div>
            </div>
<!--            <div class="input-wrap referral-wrap" v-if="$root.user && !$root.user.referral_code">-->
<!--              <input type="text" placeholder="Реферальный код" v-model="referral_code">-->
<!--              <button class="referral_btn" @click="setReferralCode()">-->
<!--                Применить-->
<!--              </button>-->
<!--            </div>-->
            <div class="input-wrap">
              <input v-model="email" type="email" placeholder="Email">
            </div>
          </div>
        </div>
        <div class="result-btn">
          <button class="pay" @click="pay(selectMethod)" :class="{'disabled': !selectPayment || (!amount && selectPayment !== 'skinback') || (selectPayment && selectPayment !== 'skinback' && !validEmail) || (selectPayment && selectPayment === 'freekassa' && amount < 5)}" :disabled="!selectPayment || (!amount && selectPayment !== 'skinback') || (selectPayment && selectPayment !== 'skinback' && !validEmail) || (selectPayment && selectPayment === 'freekassa' && amount < 5)">
            ПОПОЛНИТЬ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CREATE_FREEKASSA, CREATE_PAYPALICH, CREATE_SKINBACK} from "@/queries/mutations";
import {mapMutations} from "vuex";

export default {
  data () {
    return {
      email: null,
      validEmail: false,
      amount: 1,
      amountRub: 0,
      bonus: 0,
      course:  0,
      promik: null,
      referral: null,
      ArSum: [
        {
          id: 0,
          value: 1
        },
        {
          id: 1,
          value: 2
        },
        {
          id: 2,
          value: 5
        },
        {
          id: 3,
          value: 10
        },
        {
          id: 4,
          value: 25
        },
        {
          id: 5,
          value: 50
        },
        {
          id: 6,
          value: 100
        },
        {
          id: 7,
          value: 500
        }
      ],
      methods: [
        {
          id: 0,
          img: require('@/assets/icons/skinback.svg'),
          type: 'skinback',
          name: 'SKINSBACK',
          label: 'skins',
          method: 0,
          disableMinSum: true,
          category: 'skins'
        },
        {
          id: 1,
          img: require('@/assets/icons/VisaMastercard.svg'),
          type: 'freekassa',
          name: 'CARDS',
          method: 8,
          disableMinSum: true,
          category: 'money'
        },
        {
          id: 2,
          img: require('@/assets/icons/mir.svg'),
          type: 'paypalych',
          name: 'CARDS',
          label: 'popular',
          method: 50,
          category: 'money'
        },
        {
          id: 3,
          img: require('@/assets/icons/Qiwi.svg'),
          type: 'freekassa',
          name: 'QIWI',
          method: 10,
          disableMinSum: true,
          category: 'money'
        },
        {
          id: 4,
          img: require('@/assets/icons/fk.svg'),
          type: 'freekassa',
          name: 'FK WALLET',
          method: 1,
          disableMinSum: true,
          category: 'money'
        }
      ],
      selectPayment: [],
      selectMethod: 'skinback',
      changeAmount: 1,
      selectSum: 2,
      disabledSum: [0,1],
      selectCategory: 'all',
    }
  },
  watch: {
    email(newVal) {
      this.validEmail = !!newVal.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    selectCategory () {
      this.selectPayment = null;
    },
    changeAmount() {
      if(this.amount > 1000) { this.amount = 1000; }
      this.amountRub = this.amount * this.course;
    },
    selectMethod() {
      switch (this.selectMethod) {
        case 1:
          this.amount = 5
          this.selectSum = 2
          this.amountRub = this.amount * this.course;
          break;
        case 10:
          this.amount = 5
          this.selectSum = 2
          this.amountRub = this.amount * this.course;
          break;
        case 8:
          this.amount = 5
          this.selectSum = 2
          this.amountRub = this.amount * this.course;
          break;
        case 0:
          this.selectSum = 2
          break;
        default:
          this.amount = 1
          this.selectSum = 0
          this.amountRub = this.amount * this.course;
          break;
      }
    }
  },
  methods: {
    ...mapMutations({
      toggleDeposit: 'config/toggleDeposit',
    }),
    async pay (method) {
      await this[this.selectPayment.type](method);
    },
    changeSum(sum) {
      this.selectSum = sum.id
      this.amount = sum.value
      this.amountRub = this.amount * this.course;
    },
    setReferralCode() {
      this.$root.user.referral_code = this.referral;
    },
    async paypalych () {
      if (!this.amount) {
        return;
      }

      const data = {
        sum: this.amount,
      }

      if (this.promik) {
        data.promo = this.promik
      }

      await this.$apollo.mutate({
        variables: data,
        mutation: CREATE_PAYPALICH,
      }).then((data) => {
        window.open(data.data.paypalychPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
    async skinback () {
      this.loading = true

      const data = {}

      if (this.promik) {
        data.promo = this.promik
      }

      await this.$apollo.mutate({
        mutation: CREATE_SKINBACK,
        variables: data,
      }).then((data) => {
        window.open(data.data.skinsbackPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
      this.loading = false
    },
    async freekassa (method = 1) {
      if (!this.amount) {
        return;
      }
      if (!this.email) {
        return this.$toast.error('Введите Ваш Email.')
      }
      const data = {
        sum: this.amount,
        email: this.email
      }

      if(this.selectPayment === 'freekassa') {
        data.method = method
      }

      if (this.promik) {
        data.promo = this.promik
      }

      await this.$apollo.mutate({
        variables: data,
        mutation: CREATE_FREEKASSA,
      }).then((data) => {
        window.open(data.data.freekassaPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
  },
  computed: {
    promocode: {
      get () {
        if (this.$root.user.promocode && this.$root.user.promocode.code) {
          return this.$root.user.promocode.code
        }

        return this.promik;
      },
      set (val) {
        this.promik = val
      }
    },
    referral_code: {
      get () {
        if (this.$root.user.referral_code) {
          return this.$root.user.referral_code
        }

        return null;
      },
      set (val) {
        this.referral = val
      }
    },
    getMethods () {
      return this.methods
    },
    getSum () {
      return this.ArSum
    }
  },
  mounted() {
    this.course = this.amountRub = this.$store.getters["config/config"].getPaymentConfig.course
    if(!this.$root.isAuth) {
      location.replace('/');
    }
    this.selectPayment = this.methods[0]
    this.selectMethod = 0
  }
}
</script>

<style scoped lang="scss">
.deposit {
  width: 100%;
  padding: 40px;
  .head {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    h1 {
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    svg {
      margin-right: 10px;
    }
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    @media (max-width: 1440px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__methods {
    .contents {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
      @media (max-width: 768px) {
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .payment-select {
        display: flex;
        border: 2px solid transparent;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        position: relative;
        &.popular {
          background: linear-gradient(243.93deg, rgba(218, 157, 40, 0.16) -80.25%, rgba(222, 146, 0, 0.09) 25.71%, rgba(255, 204, 106, 0.16) 46.81%, rgba(223, 146, 0, 0.09) 67.7%, rgba(224, 147, 0, 0.16) 113.78%), #282F37;
          &:hover {
            background-position: 60px 0;
            transition: all 0.4s;
          }
        }
        .label {
          position: absolute;
          top: 9px;
          right: 0;
          padding: 6px 13px;
          color: #22272E;
          font-weight: 900;
          font-size: 10px;
          line-height: 1;
          background: linear-gradient(130.43deg, #EFE05A 0%, #FFA450 100%);
          border-radius: 3px 0 0 3px;
          text-transform: uppercase;
        }
        img {
          max-width: 80%;
          max-height: 70%;
        }
        background: #262932;
        border-radius: 12px;
        height: 119px;
        transition: all 0.4s;
        @media (max-width: 768px) {
          height: 100px;
        }
        &:hover:not(.popular) {
          background-color: #323B45;
        }
        &.active {
          border: 2px solid #009A7E
        }
      }
    }
  }
  &__sum {
    position: relative;
    .skinback__wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(20, 21, 23, 0.6);
      backdrop-filter: blur(20px);
      -webkit-box-shadow: 0px 0px 20px 30px rgba(18, 19, 21, 1) inset;
      -moz-box-shadow: 0px 0px 20px 30px rgba(18, 19, 21, 1) inset;
      box-shadow: 0px 0px 20px 30px rgba(18, 19, 21, 1) inset;
      z-index: 10;
      display: flex;
      text-align: center;
      align-items: center;
      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        margin: 0 50px;
      }
    }
    .contents {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
      @media (max-width: 768px) {
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .sum-val {
        display: flex;
        border: 2px solid transparent;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        background: #262932;
        border-radius: 12px;
        height: 119px;
        transition: 0.2s;
        font-weight: 400;
        font-size: 24px;
        line-height: 25px;
        span {
          font-weight: 400;
          font-size: 24px;
          line-height: 25px;
          color: #009A7E;
          margin-right: 2px;
        }
        @media (max-width: 768px) {
          height: 80px;
        }
        &:hover:not(.disabled):not(.active) {
          background-color: #323B45;
        }
        &.active {
          border: 2px solid #009A7E
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
    .inputs {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
      &.big {
        grid-template-columns: repeat(1, 1fr);
        .percent {
          margin-right: 20px;
        }
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .input-group {
        overflow: hidden;
        margin-top: 40px;
        position: relative;
        @media (max-width: 575px) {
          display: block;
        }
        input {
          background: #171921;
          border: none;
          border-radius: 10px;
          height: 52px;
          outline: none;
          flex-grow: 1;
          padding-left: 24px;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          font-family: 'Inter', sans-serif;
          overflow: hidden;
          @media (max-width: 575px) {
            width: 100%;
            margin-top: 10px;
          }
        }
        .input-wrap {
          display: flex;
          align-items: center;
          background: #171921;
          border: 1px dashed #2A2D38;
          &.bonus {
            height: 52px;
            outline: none;
            padding-left: 24px;
            font-weight: 500;
            font-size: 13px;
            letter-spacing: 1px;
            line-height: 16px;
            font-family: "Inter", sans-serif;
            .game-cur {
              color: #009A7E;
            }
            .plus {
              margin: 0 5px;
            }
            .free-cur {
              color: #C2AC5B;
            }
          }
        }
        .exchange {
          background: #252E35;
          height: 30px;
          width: 30px;
          min-width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            background: url(@/assets/icons/exchange.svg);
            height: 14px;
            width: 14px;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        &.email {
          margin: 0;
        }
      }
      label {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        font-family: 'Inter', sans-serif;
        margin-bottom: 10px;
        display: block;
        margin-left: 5px;
      }
      .course {
        position: absolute;
        right: 5px;
        top: 0;
      }
    }
  }
  &__result {
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #717380;
      text-transform: none;
      display: flex;
      align-items: center;
      justify-content: end;
      position: relative;
      top: -16px;
      p {
        font-size: 16px;
        line-height: 19px;
        color: #717380;
        span {
          margin: 0 10px 0 5px;
          font-size: 16px;
          line-height: 19px;
          color: #FFF;
        }
      }
      .referral_bonus {
        padding: 8px;
        background: #629E6F;
        border-radius: 6px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 700;
        color: #121315;
      }
    }
    .res_block {
      background: #1B1C24;
      border-radius: 15px;
      padding: 30px;
      +.res_block {
        margin-top: 16px;
      }
      img {
        max-width: 80%;
        max-height: 70%;
        width: 100%;
      }
    }
    .result {
      &-method {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          span {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #717380;
          }
          p {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #DEDFE8;
          }
        }
        .right {
          text-align: right;
        }
      }
      &-fields {
        .promocode {
          .input-wrap {
            input {
              background: #171921;
              border: none;
              border-radius: 10px;
              height: 52px;
              outline: none;
              flex-grow: 1;
              padding-left: 24px;
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              font-family: 'Inter', sans-serif;
              overflow: hidden;
              @media (max-width: 575px) {
                width: 100%;
                margin-top: 10px;
              }
            }
            display: flex;
            align-items: center;
            background: #171921;
            border: 1px dashed #2A2D38;
            white-space: nowrap;
            .percent {
              margin-right: 20px;
            }
            &+.input-wrap {
              margin-top: 16px;
            }
            &.referral-wrap {
              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
              }
            }
          }
          .referral_btn {
            background: #D06538;
            border-radius: 6px;
            padding: 18.5px 0;
            width: 30%;
            transition: 0.2s;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
            &:hover:not(.disabled) {
              background: #F1682D;
            }
            @media (max-width: 768px) {
              width: 100%;
              margin-top: 5px;
            }
          }
        }
      }
      &-btn {
        .pay {
          background: #629E6F;
          border-radius: 10px;
          margin-top: 32px;
          padding: 18.5px 0;
          width: 100%;
          transition: 0.2s;
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          font-family: 'Inter', sans-serif;
          &:hover:not(.disabled) {
            background: #3F8D50;
          }
          &[disabled="disabled"] {
            cursor: not-allowed;
            background: #262932;
          }
        }
      }
    }
  }
}
</style>