<template>
  <div class="market">

      <div class="market-wrapper">
        <ShopFilter @qualities="qualities = $event" @types="types = $event" @rarity="rarity = $event" @priceFrom="priceFrom = $event.target.value" @priceTo="priceTo = $event.target.value"></ShopFilter>
          <div class="market__wrapper">
            <ShopPanel @search="search = $event.target.value" @order="order = $event"></ShopPanel>
            <template v-if="!$apollo.loading && !loading && items.length > 0">
              <div class="market__item" v-for="skin in items" :key="skin.item.id">
                <div class="market__item-count">{{ skin.count }} X</div>
                <div @click.prevent.stop="selectBuyItem = skin" class="market__buy">
                  <svg width="20" height="20" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.58824 1L4.35294 4.35294M14.4118 1L16.6471 4.35294M8.82353 11.0588L7.70588 7.14706M12.1765 11.0588L13.2941 7.14706M18.8824 4.35294C18.2293 4.35294 17.6639 4.8065 17.5223 5.44397L16.3074 10.911C15.9344 12.5893 15.7479 13.4284 15.135 13.9201C14.5221 14.4118 13.6625 14.4118 11.9432 14.4118H9.05677C7.33753 14.4118 6.47792 14.4118 5.86499 13.9201C5.25207 13.4284 5.06559 12.5893 4.69264 10.911L3.47775 5.44397C3.33608 4.8065 2.77067 4.35294 2.11765 4.35294M1 4.35294H20" stroke="white" stroke-linecap="round"/>
                  </svg>
                </div>
                <BaseSkin :hidePrice="false" :key="skin.item.id" :skin="{internal_id: skin.item.id, ...skin.item}"></BaseSkin>
              </div>
            </template>
            <div class="market__no-items" v-else>
              <p>Предметы не найдены.</p>
            </div>
            <transition name="fade" v-if="$apollo.loading">
              <LoaderBlock  />
            </transition>
        </div>
      </div>
    <ShopPagination v-if="sortItems && sortItems.length > 42" @changePage="page = $event" :activePage="page" :total="sortItems.length" />

    <BuyModal @close="selectBuyItem = null" :item="selectBuyItem" v-if="selectBuyItem" />
    </div>
</template>

<script>
// import {debounce} from "vue-debounce";
import BaseSkin from "@/components/BaseSkin.vue";
import ShopFilter from "@/components/shop/ShopFilter.vue";
import LoaderBlock from "@/components/LoaderBlock.vue";
import ShopPagination from "@/components/shop/ShopPagination.vue";
import ShopPanel from "@/components/shop/ShopPanel.vue";
import {MARKET_PRICE_LIST} from "@/queries";
import BuyModal from "@/components/shop/BuyModal.vue";
const TAKE = 42;
export default {
  data () {
    return {
      selectBuyItem: null,
      qualities: {
        FN: false,
        MW: false,
        FT: false,
        WW: false,
        BS: false
      },
      types: {
        weapon: false,
        gloves: false,
        agent: false,
        knife: false,
        music: false,
        graffiti: false,
        sticker: false
      },
      rarity: {
        consumer: false,
        industrial: false,
        milSpec: false,
        restricted: false,
        classified: false,
        covert: false
      },
      priceFrom: null,
      priceTo: null,
      search: '',
      order: 'desc',
      quality: null,
      loading: false,
      page: 1,
    }
  },
  computed: {
    items () {
      if (!this.sortItems) return []

      return this.sortItems.slice(TAKE * this.page, (TAKE * this.page) + TAKE)
    },
    sortItems () {
      let items = this.searchItems
      if (this.order === 'asc') {
        items = items.sort((a,b) => {
          return a.item.price - b.item.price;
        })
      } else {
        items = items.sort((a,b) => {
          return b.item.price - a.item.price;
        })
      }

      if (this.priceFrom) {
        items = items.filter(item => {
          return item.item.price >= this.priceFrom
        })
      } if (this.priceTo) {
        items = items.filter(item => {
          return item.item.price <= this.priceTo
        })
      }

      const keysQualities = Object.keys(this.qualities)
      const qualitiesSelect = []
      for (const item in keysQualities) {
        if (this.qualities[keysQualities[item]] === true) {
          qualitiesSelect.push(keysQualities[item])
        }
      }
      if (qualitiesSelect.length) {
        const keys = qualitiesSelect
        items = items.filter(item => {
          return keys.includes(item.item.quality)
        })
      }


      const keysTypes = Object.keys(this.types)
      const typesSelect = []
      for (const item in keysTypes) {
        if (this.types[keysTypes[item]] === true) {
          typesSelect.push(keysTypes[item])
        }
      }
      if (typesSelect.length) {
        const keys = typesSelect
        items = items.filter(item => {
          return keys.includes(item.item.type)
        })
      }

      const keysRarity = Object.keys(this.rarity)
      const raritySelect = []
      for (const item in keysRarity) {
        if (this.rarity[keysRarity[item]] === true) {
          raritySelect.push(keysRarity[item])
        }
      }
      if (raritySelect.length) {
        const keys = raritySelect
        items = items.filter(item => {
          return keys.includes(item.item.rarity)
        })
      }

      return items
    },

    searchItems () {
      if (!this.shopItems) return [];

      return this.shopItems.filter(obj => String(obj.item.name).toLocaleLowerCase().includes(String(this.search).toLocaleLowerCase()));
    }
  },
  watch: {
    search () {
      this.page = 1
    },
    qualities () {
      this.page = 1
    },
    types () {
      this.page = 1
    },
    rarity () {
      this.page = 1
    },
    priceFrom () {
      this.page = 1
    },
    priceTo () {
      this.page = 1
    }
  },
  apollo: {
    shopItems: {
      fetchPolicy: 'network-only',
      query: MARKET_PRICE_LIST,
      update: data => data.marketPrices
    }
  },
  components: {
    BuyModal,
    LoaderBlock,
    ShopPagination,
    ShopPanel,
    ShopFilter,
    BaseSkin
  }
}
</script>

<style scoped lang="scss">
.market {

  .market-wrapper {
    display: grid;
    grid-template-columns: 310px auto;
    align-items: flex-start;
    @media (max-width: 900px) {
      grid-template-rows: auto auto;
      grid-template-columns: none;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 10px;
    padding: 90px 35px 35px;
    position: relative;
    @media (min-width: 2000px) {
      grid-template-columns: repeat(10, 1fr);
    }
    @media (max-width: 1440px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__item {
    position: relative;
    z-index: 10;
    &:first-child {
      @media (max-width: 575px) {
        margin-top:50px;
      }
    }
    &:hover .market__buy{
      opacity: 1;
      transition: opacity .3s cubic-bezier(.1,.7,.5,1);
    }
    &-count {
      position: absolute;
      left: 14px;
      top: 14px;
      padding: 5px;
      background: #2a2c32;
      border-radius: 4px;
      color: #b1b1b1;
      font-size: 12px;
      font-weight: 700;
    }
  }
  &__buy {
    position: absolute;
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 30px);
    width: 60px;
    text-align: center;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #C80A38;

    z-index: 20;
    border-radius: 5px;
    opacity: 0;
    cursor: pointer;
    transition: all .3s cubic-bezier(.1,.7,.5,1);
    svg {
    }
    &:hover {
      opacity: 0.7 !important;
    }
  }
}
</style>