<template>
  <div class="multi-prize" :class="[hexToColor(prize.item.color)]" v-if="prize">
    <div class="multi-prize__price">
      {{ parseFloat(prize.price).toFixed(2) }}$
    </div>
    <div class="multi-prize__image">
      <img :src="prize.item.getImage" alt="">
    </div>
    <div class="multi-prize__info">
      <strong>{{ getNameSkin(prize.item.name) }}</strong>
      <span>{{ getQualitySkin(prize.item.name) }}</span>
    </div>

    <button @click="sellSkinsMethod([prize.id])">ПРОДАТЬ</button>
  </div>
</template>
<script>
import getNameSkin from "../../helpers/getNameSkin";
import getQualitySkin from "../../helpers/getQualitySkin";
import hexToColor from "@/filters/hexToColor";
import {mapActions, mapMutations} from "vuex";

export default {
  methods: {
    ...mapActions({
      sellSkins: 'inventory/sellSkins'
    }),
    ...mapMutations({
      removePrize: 'open/removePrize'
    }),
    async sellSkinsMethod (ids) {
      const isSell = await this.sellSkins(ids)

      if (isSell) {
          this.removePrize(ids[0])
      }
    },
    hexToColor, getNameSkin, getQualitySkin
  },
  props: {
    prize: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.multi-prize {
  height: 211px;
  width: 214px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &.yellow {
    background: url(@/assets/images/prize-bg-yellow.png);
    background-size: 100% 100%;
  }
  &.purple {
    background: url(@/assets/images/prize-bg-purple.png);
    background-size: 100% 100%;
  }
  &.grey {
    background: url(@/assets/images/prize-bg-grey.png);
    background-size: 100% 100%;
  }
  &.light-blue {
    background: url(@/assets/images/prize-bg-light-blue.png);
    background-size: 100% 100%;
  }
  &.pink {
    background: url(@/assets/images/prize-bg-pink.png);
    background-size: 100% 100%;
  }
  &.red {
    background: url(@/assets/images/prize-bg-red.png);
    background-size: 100% 100%;
  }
  &.blue {
    background: url(@/assets/images/prize-bg-blue.png);
    background-size: 100% 100%;
  }
  button {
    z-index: 8;
    padding: 8px 14px;
    background: #1C843F;
    border-radius: 6px;
    font-weight: 800;
    font-size: 11px;
    transition: 0.2s;
    line-height: 15px;
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: 575px) {
      font-size: 10px;
      padding: 6px 12px;
    }
  }
  .multi-prize__image {
    position: absolute;
    width: 100%;
    img {
      width: 100%;
      max-height: 155px;
    }
  }
  .multi-prize__price {
    position: absolute;
    top: 7px;
    right: 0;
    background: rgba(70, 109, 70, 0.4);
    backdrop-filter: blur(6px);
    border-radius: 6px;
    padding: 7.5px 13.5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    @media (max-width: 575px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  .multi-prize__info {
    position: absolute;
    bottom: 1px;
    width: fit-content;
    background: rgba(65, 65, 65, 0.4);
    backdrop-filter: blur(6px);
    padding: 6px 20px;
    border-radius: 6px;
    strong,span {
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      display: block;
      text-align: center;
    }
    strong {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #D4CEDE;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
    span {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      margin: 0 auto;
      line-height: 15px;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}
</style>