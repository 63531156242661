<template>
  <div class="infuncient-info">
    <strong>Вы не авторизованы</strong>
    <span>Авторизуйтесь чтобы открыть этот кейс.</span>
  </div>
</template>
<script>

export default {

}
</script>
<style scoped lang="scss">
.infuncient-info {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 18, 21, 0.7);
  backdrop-filter: blur(7.5px);
  z-index: 2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  strong,span {
    display: block;
    text-align: center;
  }
  strong {
    margin-bottom: 2px;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    color: #CC7F67;
  }
  span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #868280;
  }
}
</style>