<template>
  <div class="unauthorization-contract">
    <div class="icon">
      <img src="@/assets/icons/warning.svg" alt="">
    </div>
    <div class="text">
      <h1>Вы не авторизированы!</h1>
      <span><a @click="login">Авторизируйтесь,</a> чтобы создать контракт!</span>
    </div>
  </div>
</template>
<script>
import {LOGIN_URI} from "@/const/ENDPOINTS";

export default {
  methods: {
    login () {
      window.location.href = LOGIN_URI
    }
  }
}
</script>
<style scoped lang="scss">
.unauthorization-contract {
  background: rgba(195, 33, 70, 0.2);
  border: 2px solid #C32146;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 400px) {
    padding: 25px 15px;
  }
  .icon {
    margin-right: 44px;
    img {
      width: 60px;
    }
    @media (max-width: 768px) {
      margin-right: 14px;
      img {
        width: 40px;
      }
    }
    @media (max-width: 400px) {
      margin-right: 10px;
      img {
        width: 30px;
      }
    }
  }
  .text {
    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      margin: 0;
      margin-bottom: 7px;
      color: #C32146;
      display: block;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    span {
      width: 100%;
      display: block;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 15px;
      }
      a {
        text-decoration: underline;
        color: #C32146;
        cursor: pointer;
        font-size: 14px;
        transition: 0.2s;
        line-height: 19px;
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 15px;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>