<template>
  <div class="modal">
    <div class="modal__body" ref="body">
      <div class="body">
        <div class="close" @click="$emit('close')">
          <img src="@/assets/icons/close.svg" alt="">
        </div>
        <h1>Пополнить баланс</h1>
        <div class="tabs">
          <div class="tab" :class="{'active': selectCategory === 'all'}" @click="selectCategory = 'all'">Все</div>
          <div class="tab" :class="{'active': selectCategory === 'money'}" @click="selectCategory = 'money'">Деньги</div>
          <div class="tab" :class="{'active': selectCategory === 'skins'}" @click="selectCategory = 'skins'">Скины</div>
        </div>

        <div class="contents">
          <div class="payment-select" v-for="payment in getMethods" :key="payment.id" :class="{'active': selectMethod === payment.method}" @click="selectPayment = payment.type;selectMethod = payment.method">
            <img :src="payment.img" alt="">
          </div>
        </div>

        <div class="inputs">
          <div class="input-group" v-if="selectPayment !== 'skinback'">
            <label>Введите сумму</label>
            <span class="course">1 USD = {{ course }} RUB</span>
            <div class="input-wrap">
              <input v-model.number="amount" type="number" @input="changeAmount = amount">
              <div class="exchange"><div class="icon"></div></div>
              <input type="number" readonly v-model.number="amountRub">
            </div>
          </div>
          <div class="input-group" v-if="selectPayment !== 'skinback'">
            <label>Вы получите</label>
            <div class="input-wrap bonus">
              <span class="game-cur">$</span>{{ amount }}
              <template v-if="$root.user && $root.user.promocode">
                <span class="plus">+</span>
                <span class="free-cur" >$</span>{{ (amount * ($root.user.promocode.prize / 100)).toFixed(2) }} бонус
              </template>
            </div>
          </div>
          <div class="input-group email" v-if="selectPayment && selectPayment !== 'skinback'">
            <div class="input-wrap">
              <input v-model="email" type="email" placeholder="Email">
            </div>
          </div>
        </div>

        <div class="inputs big">
          <div class="input-group">
            <div class="input-wrap">
              <input type="text" :disabled="$root.user && $root.user.promocode" placeholder="Промокод" v-model="promocode">
              <div class="percent" v-if="$root.user && $root.user.promocode"> +{{ $root.user.promocode.prize }}% к пополнению</div>
            </div>
          </div>

        </div>

        <button class="pay" @click="pay(selectMethod)" :class="{'disabled': !selectPayment || (!amount && selectPayment !== 'skinback') || (selectPayment && selectPayment !== 'skinback' && !email) || (selectPayment && selectPayment === 'freekassa' && amount < 5)}" :disabled="!selectPayment || (!amount && selectPayment !== 'skinback') || (selectPayment && selectPayment !== 'skinback' && !email) || (selectPayment && selectPayment === 'freekassa' && amount < 5)">
          ПОПОЛНИТЬ
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import closeModal from "@/mixins/closeModal";
import {CREATE_FREEKASSA, CREATE_PAYPALICH, CREATE_SKINBACK} from "@/queries/mutations";
import {mapMutations} from "vuex";

export default {
  mixins: [closeModal],
  data () {
    return {
      email: null,
      amount: 1,
      amountRub: 81,
      bonus: 0,
      course:  81,
      promik: null,
      methods: [
        {
          id: 0,
          img: require('@/assets/icons/skinback.svg'),
          type: 'skinback',
          method: 0,
          category: 'skins'
        },
        {
          id: 1,
          img: require('@/assets/icons/VisaMastercard.svg'),
          type: 'freekassa',
          method: 8,
          category: 'money'
        },
        {
          id: 2,
          img: require('@/assets/icons/Qiwi.svg'),
          type: 'freekassa',
          method: 10,
          category: 'money'
        },
        {
          id: 3,
          img: require('@/assets/icons/fk.svg'),
          type: 'freekassa',
          method: 1,
          category: 'money'
        },
        {
          id: 4,
          img: require('@/assets/icons/mir.svg'),
          type: 'paypalych',
          method: 50,
          category: 'money'
        }
      ],
      selectPayment: null,
      selectMethod: null,
      changeAmount: 1,
      selectCategory: 'all',
    }
  },
  watch: {
    selectCategory () {
      this.selectPayment = null;
    },
    changeAmount() {
      if(this.amount > 1000) { this.amount = 1000; }
      this.amountRub = this.amount * this.course;
    },
    selectMethod() {
      switch (this.selectMethod) {
        case 1:
          this.amount = 5
          this.amountRub = this.amount * this.course;
          break;
        case 10:
          this.amount = 5
          this.amountRub = this.amount * this.course;
          break;
        case 8:
          this.amount = 5
          this.amountRub = this.amount * this.course;
          break;
        default:
          this.amount = 1
          this.amountRub = this.amount * this.course;
            break;
      }
    }
  },
  methods: {
    ...mapMutations({
      toggleDeposit: 'config/toggleDeposit'
    }),
    async pay (method) {
      await this[this.selectPayment](method);
    },
    async paypalych () {
      if (!this.amount) {
        return;
      }

      const data = {
        sum: this.amount,
      }

      if (this.promik) {
        data.promo = this.promik
      }

      await this.$apollo.mutate({
        variables: data,
        mutation: CREATE_PAYPALICH,
      }).then((data) => {
        window.open(data.data.paypalychPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
    async skinback () {
      this.loading = true

      const data = {}

      if (this.promik) {
        data.promo = this.promik
      }

      await this.$apollo.mutate({
        mutation: CREATE_SKINBACK,
        variables: data,
      }).then((data) => {
        window.open(data.data.skinsbackPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
      this.loading = false
    },
    async freekassa (method = 1) {
      if (!this.amount) {
        return;
      }
      if (!this.email) {
        return this.$toast.error('Введите Ваш Email.')
      }
      const data = {
        sum: this.amount,
        email: this.email
      }

      if(this.selectPayment === 'freekassa') {
        data.method = method
      }

      if (this.promik) {
        data.promo = this.promik
      }

      await this.$apollo.mutate({
        variables: data,
        mutation: CREATE_FREEKASSA,
      }).then((data) => {
        window.open(data.data.freekassaPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
  },
  computed: {
    promocode: {
      get () {
        if (this.$root.user.promocode && this.$root.user.promocode.code) {
          return this.$root.user.promocode.code
        }

        return null;
      },
      set (val) {
        this.promik = val
      }
    },
    getMethods () {
      if (this.selectCategory === 'all') {
        return this.methods
      }

      return this.methods.filter(item => item.category === this.selectCategory)
    }
  }
}
</script>
<style scoped lang="scss">
.body {
  width: 928px;
  position: relative;
  max-width: 100%;
  min-width: 928px;
  @media (max-width: 992px) {
    min-width: 100%;
  }
  .close {
    position: absolute;
    padding: 10px;
    top: 18px;
    right: 18px;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 575px) {
      top: 10px;
      right: 20px;
      width: 35px;
      img {
        width: 35px;
      }
    }
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: 'Inter', sans-serif;
    margin: 0;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  .tabs {
    margin-top: 16px;
    display: flex;
    .tab {
      padding: 13px 34px;
      border: 1px solid #3F4350;
      border-radius: 10px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      transition: 0.2s;
      cursor: default;
      &:not(.active) {
        cursor: pointer;
      }
      @media (max-width: 600px) {
        padding: 12px 12px;
        font-size: 13px;
      }
      font-family: 'Inter', sans-serif;
      &.active,&:hover {
        background: #009A7E;
        border-color: #009A7E;
      }
    }
    .tab + .tab {
      margin-left: 15px;
    }
  }
  .contents {
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;
    @media (max-width: 768px) {
      grid-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .payment-select {
      display: flex;
      border: 2px solid transparent;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      img {
        max-width: 80%;
        max-height: 70%;
      }
      background: #262932;
      border-radius: 12px;
      height: 119px;
      transition: 0.2s;
      @media (max-width: 768px) {
        height: 80px;
      }
      &:hover {
        opacity: 0.8;
      }
      &.active {
        border: 2px solid #009A7E
      }
    }
  }
  .inputs {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    &.big {
      grid-template-columns: repeat(1, 1fr);
      .percent {
        margin-right: 20px;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .input-group {
      overflow: hidden;
      margin-top: 40px;
      position: relative;
      @media (max-width: 575px) {
        display: block;
      }
      input {
        background: #171921;
        border: none;
        border-radius: 10px;
        height: 52px;
        outline: none;
        flex-grow: 1;
        padding-left: 24px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        font-family: 'Inter', sans-serif;
        overflow: hidden;
        @media (max-width: 575px) {
          width: 100%;
          margin-top: 10px;
        }
      }
      .input-wrap {
        display: flex;
        align-items: center;
        background: #171921;
        border: 1px dashed #2A2D38;
        &.bonus {
          height: 52px;
          outline: none;
          padding-left: 24px;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 1px;
          line-height: 16px;
          font-family: "Inter", sans-serif;
          .game-cur {
            color: #009A7E;
          }
          .plus {
            margin: 0 5px;
          }
          .free-cur {
            color: #C2AC5B;
          }
        }
      }
      .exchange {
        background: #252E35;
        height: 30px;
        width: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          background: url(@/assets/icons/exchange.svg);
          height: 14px;
          width: 14px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      &.email {
        margin: 0;
      }
    }
    label {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-family: 'Inter', sans-serif;
      margin-bottom: 10px;
      display: block;
      margin-left: 5px;
    }
    .course {
      position: absolute;
      right: 5px;
      top: 0;
    }
  }
  .pay {
    background: #629E6F;
    border-radius: 10px;
    margin-top: 32px;
    padding: 18.5px 0;
    width: 100%;
    transition: 0.2s;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    font-family: 'Inter', sans-serif;
    &:hover:not(.disabled) {
      background: #3F8D50;
    }
    &[disabled="disabled"] {
      cursor: not-allowed;
      background: #262932;
    }
  }
}
</style>