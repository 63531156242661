<template>
  <div class="chat">
    <div class="chat-header">
      <button class="hide" @click="toggleChatMethod">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.73342 12.6667C8.60157 12.6661 8.47285 12.6265 8.36353 12.5528C8.25421 12.4791 8.16919 12.3746 8.11924 12.2526C8.06929 12.1305 8.05665 11.9965 8.0829 11.8672C8.10916 11.738 8.17314 11.6195 8.26676 11.5267L11.3334 8.47333C11.3959 8.41135 11.4455 8.33762 11.4794 8.25638C11.5132 8.17514 11.5306 8.088 11.5306 7.99999C11.5306 7.91199 11.5132 7.82485 11.4794 7.74361C11.4455 7.66237 11.3959 7.58863 11.3334 7.52666L8.26676 4.47332C8.20427 4.41135 8.15467 4.33761 8.12083 4.25637C8.08698 4.17514 8.06956 4.088 8.06956 3.99999C8.06956 3.91198 8.08698 3.82484 8.12083 3.74361C8.15467 3.66237 8.20427 3.58863 8.26676 3.52666C8.39166 3.40249 8.56063 3.33279 8.73676 3.33279C8.91288 3.33279 9.08185 3.40249 9.20676 3.52666L12.2668 6.58666C12.6413 6.96166 12.8517 7.46999 12.8517 7.99999C12.8517 8.53 12.6413 9.03833 12.2668 9.41333L9.20676 12.4733C9.14447 12.5351 9.07059 12.584 8.98936 12.6172C8.90814 12.6504 8.82116 12.6672 8.73342 12.6667Z" fill="#F98585"/>
          <path d="M4.06646 12.6667C3.93461 12.6661 3.80589 12.6265 3.69656 12.5528C3.58724 12.4791 3.50223 12.3746 3.45228 12.2526C3.40233 12.1305 3.38968 11.9965 3.41594 11.8672C3.4422 11.738 3.50618 11.6195 3.59979 11.5267L7.12646 7.99999L3.59979 4.47332C3.53731 4.41135 3.48771 4.33761 3.45387 4.25637C3.42002 4.17514 3.40259 4.088 3.40259 3.99999C3.40259 3.91198 3.42002 3.82484 3.45387 3.74361C3.48771 3.66237 3.53731 3.58863 3.59979 3.52666C3.7247 3.40249 3.89367 3.33279 4.06979 3.33279C4.24592 3.33279 4.41488 3.40249 4.53979 3.52666L8.53979 7.52666C8.60228 7.58863 8.65188 7.66237 8.68572 7.74361C8.71957 7.82485 8.73699 7.91199 8.73699 7.99999C8.73699 8.088 8.71957 8.17514 8.68572 8.25638C8.65188 8.33762 8.60228 8.41135 8.53979 8.47333L4.53979 12.4733C4.4775 12.5351 4.40362 12.584 4.3224 12.6172C4.24117 12.6504 4.1542 12.6672 4.06646 12.6667Z" fill="#F98585"/>
        </svg>
      </button>
      <span>ЧАТ</span>

<!--      <div class="online-chat">-->
<!--        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M8 7.99999C8.79113 7.99999 9.56448 7.7654 10.2223 7.32587C10.8801 6.88635 11.3928 6.26163 11.6955 5.53073C11.9983 4.79983 12.0775 3.99556 11.9231 3.21964C11.7688 2.44371 11.3878 1.73098 10.8284 1.17157C10.269 0.612164 9.55629 0.231201 8.78036 0.0768606C8.00444 -0.0774801 7.20017 0.00173314 6.46927 0.304483C5.73836 0.607234 5.11365 1.11992 4.67412 1.77772C4.2346 2.43552 4 3.20887 4 4C4.00106 5.06054 4.42283 6.07734 5.17274 6.82725C5.92266 7.57717 6.93946 7.99894 8 7.99999ZM8 1.33333C8.52742 1.33333 9.04299 1.48973 9.48152 1.78275C9.92005 2.07576 10.2618 2.49224 10.4637 2.97951C10.6655 3.46678 10.7183 4.00296 10.6154 4.52024C10.5125 5.03752 10.2586 5.51267 9.88562 5.88561C9.51268 6.25855 9.03752 6.51253 8.52024 6.61542C8.00296 6.71832 7.46678 6.66551 6.97951 6.46367C6.49224 6.26184 6.07577 5.92005 5.78275 5.48152C5.48973 5.04299 5.33333 4.52741 5.33333 4C5.33333 3.29275 5.61428 2.61448 6.11438 2.11438C6.61448 1.61429 7.29276 1.33333 8 1.33333Z" fill="#53A75B"/>-->
<!--          <path d="M8 9.33334C6.40924 9.33511 4.88414 9.96782 3.75931 11.0927C2.63447 12.2175 2.00176 13.7426 2 15.3333C2 15.5102 2.07024 15.6797 2.19526 15.8047C2.32029 15.9298 2.48986 16 2.66667 16C2.84348 16 3.01305 15.9298 3.13807 15.8047C3.2631 15.6797 3.33333 15.5102 3.33333 15.3333C3.33333 14.0957 3.825 12.9087 4.70017 12.0335C5.57534 11.1583 6.76232 10.6667 8 10.6667C9.23768 10.6667 10.4247 11.1583 11.2998 12.0335C12.175 12.9087 12.6667 14.0957 12.6667 15.3333C12.6667 15.5102 12.7369 15.6797 12.8619 15.8047C12.987 15.9298 13.1565 16 13.3333 16C13.5101 16 13.6797 15.9298 13.8047 15.8047C13.9298 15.6797 14 15.5102 14 15.3333C13.9982 13.7426 13.3655 12.2175 12.2407 11.0927C11.1159 9.96782 9.59076 9.33511 8 9.33334Z" fill="#53A75B"/>-->
<!--        </svg>-->
<!--        825-->
<!--      </div>-->
    </div>
    <div ref="chat__messages" class="chat-body">
      <div v-for="(message, i) in messages" :key="i" class="chat-message">
        <div class="avatar">
          <img v-if="message.user.isModerator" src="@/assets/images/avatar.png">
          <img v-else :src="message.user.avatar">
        </div>
        <div class="info">
          <div class="nickname">
            <div class="role" v-if="message.user.isModerator">ADMIN</div>
            <span v-if="!message.user.isModerator">{{ message.user.name }}</span>

            <div class="chat__messages-message-settings" v-if="$root.user.isAdmin || $root.user.isModerator" @click.prevent.stop="openSettingsMessage = openSettingsMessage === message.id ? null : message.id">
              <div class="settings__body" :class="{'bottom': [0,1].includes(i)}" v-if="openSettingsMessage === message.id">
                <div class="settings__body-item" @click="muteUser(message.user)">
                  <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M17.5854 12L16.1712 10.5858C15.7807 10.1953 15.7807 9.5621 16.1712 9.17157C16.5617 8.78105 17.1949 8.78105 17.5854 9.17157L18.9996 10.5858L20.4138 9.17157C20.8044 8.78105 21.4375 8.78105 21.8281 9.17157C22.2186 9.5621 22.2186 10.1953 21.8281 10.5858L20.4138 12L21.8281 13.4142C22.2186 13.8047 22.2186 14.4379 21.8281 14.8284C21.4375 15.219 20.8044 15.219 20.4138 14.8284L18.9996 13.4142L17.5854 14.8284C17.1949 15.219 16.5617 15.219 16.1712 14.8284C15.7807 14.4379 15.7807 13.8047 16.1712 13.4142L17.5854 12Z" fill="#84858E"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7 16L3.60777 15.3215C2.67292 15.1346 2 14.3137 2 13.3604V10.6396C2 9.68622 2.67292 8.86539 3.60777 8.67842L7 7.99997L10.2929 4.70708C10.6834 4.31655 11.3166 4.31655 11.7071 4.70708C11.8946 4.89461 12 5.14897 12 5.41418V18.5858C12 19.138 11.5523 19.5858 11 19.5858C10.7348 19.5858 10.4804 19.4804 10.2929 19.2929L7 16Z" fill="#84858E"/>
                    </svg>
                  </div>
                  <span>
                      <BlockLoader v-if="actionLoading === message.user.steamId"></BlockLoader>
                      <template v-else>
                        <template v-if="message.user.chatMuted">Снять мут</template>
                        <template v-else>
                          Мут
                        </template>
                      </template>
                      </span>
                </div>
                <div class="settings__body-item" @click="banUser(message.user)">
                  <div class="icon">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7V5C3 2.23858 5.23858 0 8 0C10.7614 0 13 2.23858 13 5V7H14C15.1046 7 16 7.89543 16 9V15C16 16.1046 15.1046 17 14 17H2C0.89543 17 0 16.1046 0 15V9C0 7.89543 0.89543 7 2 7H3ZM8 2C6.34315 2 5 3.34315 5 5V7H11V5C11 3.34315 9.65685 2 8 2Z" fill="#84858E"/>
                    </svg>
                  </div>
                  <span>
                      <BlockLoader v-if="actionLoading === `${message.user.steamId}_banned`"></BlockLoader>
                      <template v-else>
                        <template v-if="message.user.isBanned">Разбанить</template>
                        <template v-else>
                          Забанить
                        </template>
                      </template>
                    </span>
                </div>
                <div class="settings__body-item" @click="deleteMessage(message)">
                  <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 8H18L17.1065 19.615C17.0464 20.3965 16.3948 21 15.611 21H8.38905C7.60524 21 6.95358 20.3965 6.89347 19.615L6 8ZM8 10L8.45438 14.0894L15.5518 14.0339L16 10H8Z" fill="#84858E"/>
                      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M14 4.5V3.5C14 3.22386 13.7761 3 13.5 3H10.5C10.2239 3 10 3.22386 10 3.5V4.5H5.5C5.22386 4.5 5 4.72386 5 5V5.5C5 5.77614 5.22386 6 5.5 6H18.5C18.7761 6 19 5.77614 19 5.5V5C19 4.72386 18.7761 4.5 18.5 4.5H14Z" fill="#84858E"/>
                    </svg>
                  </div>
                  <span>
                      <BlockLoader v-if="actionLoading === `${message.id}_delete`"></BlockLoader>
                      <template v-else>
                        Удалить
                      </template>
                    </span>
                </div>
              </div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z" fill="#31333B"/>
                <path d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z" fill="#31333B"/>
                <path d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z" fill="#31333B"/>
              </svg>
            </div>

          </div>
          <div class="text">
            {{ message.text }}
          </div>
          <div class="date">
            {{ getTime(message.createdAt) }}
          </div>
        </div>
      </div>
    </div>
    <UnauthorizationChat v-if="!$root.isAuth" />
    <div class="chat-input" v-else>
      <ChatMuted v-if="$root.user.isBanned || $root.user.chatMuted"></ChatMuted>
      <template v-else>
        <textarea maxlength="128" @keydown.enter="sendMessage" name="" v-model="message" id="" cols="30" rows="10" placeholder="Введите сообщение"></textarea>
        <div class="limits">
          <span>Максимальный лимит текста</span> <em>{{ message.length }}/128</em>
        </div>
        <button :class="{'disabled': !message}" @click="sendMessage" :disabled="!message">
          Отправить
          <img src="@/assets/icons/send.svg" alt="">
        </button>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import { GET_CHAT_MESSAGES, GET_USER_ONLINE } from "@/queries";
import { NEW_CHAT_MESSAGE, USERS_ONLINE } from "@/queries/subscriptions";
import {BANNED_USER, MUTE_USER, DELETE_MESSAGE, SEND_CHAT_MESSAGE} from "@/queries/mutations";
import UnauthorizationChat from "@/components/chat/UnauthorizationChat.vue";
import BlockLoader from "@/components/BlockLoader.vue";
import ChatMuted from "@/components/chat/ChatMuted.vue";

export default {
  components: {ChatMuted, BlockLoader, UnauthorizationChat},
  data () {
    return {
      openSettingsMessage: null,
      go: false,
      actionLoading: false,
      message: '',
    }
  },
  watch: {
    isChatActive () {
      setTimeout(() => {
        this.toBottom()
      }, 0)
    },
    'chat_messages.length': function () {
      setTimeout(() => {
        this.toBottom()
      }, 0)
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideAdminMenu)
  },
  mounted() {
    document.addEventListener('click', this.hideAdminMenu)
  },
  computed: {
    ...mapGetters({
      isChatActive: 'config/isChatActive'
    }),
    messages () {
      return [...this.chat_messages ?? []].reverse()
    }
  },
  methods: {
    toggleChatMethod() {
      if (window.innerWidth < 993) {
        return this.$router.push('/')
      }

      this.toggleChat(false)
    },
    async banUser (user) {
      this.actionLoading = `${user.steamId}_banned`
      const isBanned = !user.isBanned
      await this.$apollo.mutate({
        mutation: BANNED_USER,
        variables: {
          steamId: user.steamId,
          banned: isBanned
        }
      }).then(() => {
        if (isBanned) {
          this.$toast.success('Пользователь забанен.');
        } else {
          this.$toast.success('Пользователь разбанен.');
        }
        this.messages.forEach((item) => {
          if (item.user.steamId === user.steamId) {
            item.user.isBanned = isBanned
          }
        })
        user.isBanned = isBanned
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.actionLoading = null
        }, 500)
      })
    },
    async deleteMessage (message) {
      this.actionLoading = `${message.id}_delete`

      await this.$apollo.mutate({
        mutation: DELETE_MESSAGE,
        variables: {
          id: message.id
        }
      }).then(() => {

        if(message.user.steamId === this.$root.user.steamId) {
          const index = this.chat_messages.findIndex(item => item.id === message.id)
          if (index > -1) {
            this.chat_messages.splice(index, 1)
          }
        }

        this.$toast.success('Сообщение удалено.');
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.actionLoading = null
        }, 500)
      })
    },
    async muteUser (user) {
      this.actionLoading = user.steamId
      const isMuted = !user.chatMuted
      await this.$apollo.mutate({
        mutation: MUTE_USER,
        variables: {
          steamId: user.steamId,
          muted: isMuted
        }
      }).then(() => {
        if (isMuted) {
          this.$toast.success('Мут выдан.');
        } else {
          this.$toast.success('Мут снят.');
        }
        this.messages.forEach((item, key) => {
          if (item.user.steamId === user.steamId) {
            this.messages[key].user.chatMuted = isMuted
          }
        })
        user.chatMuted = isMuted
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.actionLoading = null
        }, 500)
      })
    },
    ...mapMutations({
      toggleChat: 'config/toggleChat'
    }),
    hideAdminMenu () {
      this.openSettingsMessage = null
    },
    getTime (date) {
      date = new Date(date)

      let day = date.getDate()
      let month = date.getMonth()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      return `${day}.${month < 10 ? '0' + month : month} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    },
    async sendMessage () {
      if (!this.message) return;
      let response;

      try {
        response = await this.$apollo.mutate({
          mutation: SEND_CHAT_MESSAGE,
          variables: {
            text: this.message
          }
        })

        this.chat_messages.unshift(response.data.sendChatMessage)
        setTimeout(() => {
          this.toBottom()
        }, 0)
        this.message = ''
      } catch (e) {
        e.graphQLErrors.forEach(error => {
          this.$toast.error(this.$t(error.message));
        })
      }
    },
    toBottom () {
      const body = this.$refs.chat__messages
      body.scrollTop = body.scrollHeight + 500
    },
  },
  apollo: {
    chat_messages: {
      fetchPolicy: 'network-only',
      query: GET_CHAT_MESSAGES,
      update: data => data.chatMessages
    },
    usersOnline: {
      query: GET_USER_ONLINE,
      update: data => data.usersOnline
    },
    $subscribe: {
      ChatMessageStream: {
        query: NEW_CHAT_MESSAGE,
        result ({ data }) {
          if (data.ChatMessageStream.type === 'del') {
            const index = this.chat_messages.findIndex(item => item.id === data.ChatMessageStream.message.id)
            if (index > -1) {
              this.chat_messages.splice(index, 1)
            }
          } else {
            this.chat_messages.unshift(data.ChatMessageStream.message)
          }
        },
      },
      UsersOnlineStream: {
        query: USERS_ONLINE,
        result ({ data }) {
          this.usersOnline = data.UsersOnlineStream
        }
      }
    }
  },
}
</script>
<style scoped lang="scss">
.chat {
  position: fixed;
  right: 0;
  top: 0;
  width: 412px;
  height: 100dvh;
  background: #141518;
  z-index: 100;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    bottom: 0;
    top: unset;
    width: 100%;
    background: rgba(30, 32, 37, 0.8);
    backdrop-filter: blur(6.5px);
  }
  @media (max-width: 768px) {
  }
  .chat-header {
    display: flex;
    height: 102px;
    min-height: 102px;
    align-items: center;
    padding-right: 43px;
    border-bottom: 1px solid #111111;
    @media (max-width: 992px) {
      background: #141518;
      position: relative;
      height: 62px;
      border-bottom: none;
      min-height: 62px;
    }
    .hide {
      height: 100%;
      width: 50px;
      cursor: pointer;
      display: flex;
      transition: 0.2s;
      background: #24272C;
      align-items: center;
      justify-content: center;
      @media (max-width: 992px) {
        position: absolute;
        right: 0;
        transform: rotate(180deg);
      }
      svg {
        path {
          transition: 0.2s;
        }
      }
      &:hover {
        svg {
          path {
            fill: #FC4949
          }
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 13px;
      padding-left: 17px;
      line-height: 18px;
    }
    .online-chat {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      font-family: 'Inter', sans-serif;
      color: #DDDFEC;
      svg {
        margin-right: 10px;
      }
    }
  }
  .chat-body {
    margin-top: 20px;
    flex-grow: 1;
    overflow: hidden;
    padding: 0 27px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 5px;
    }
    .chat-message {
      align-items: flex-start;
      display: flex;
      .avatar {
        margin-right: 22px;
        width: 54px;
        height: 54px;
        min-width: 54px;
        overflow: hidden;
        border-radius: 100%;
        @media (max-width: 575px) {
          width: 46px;
          height: 46px;
          min-width: 46px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        flex-grow: 1;
        position: relative;
        .date {
          position: absolute;
          top: 0px;
          right: 5px;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #717380;
        }
        .nickname {
          position: relative;
          display: flex;
          align-items: center;
          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #717380;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 65px;
            white-space: nowrap;
          }
          .role {
            background: #E12143;
            border-radius: 3px;
            padding: 3px 7px;
            font-weight: 700;
            font-size: 11px;
            line-height: 15px;
            margin-right: 6px;
          }
          .chat__messages-message-settings {
            margin-left: 10px;
            cursor: pointer;
            .settings__body {
              position: absolute;
              background: #18191D;
              z-index: 99;
              border-radius: 8px;
              top: 25px;
              left: 0;
              border: 1px solid #31333B;
              width: 214px;
              padding: 0 15px;
              &.bottom {
                bottom: unset;
                top: 100%;
              }
              .settings__body-item {
                display: flex;
                align-items: center;
                height: 45px;
                .icon {
                  width: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                border-top: 1px solid #26272A;
                span {
                  margin-left: 10px;
                  position: relative;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  transition: 0.2s;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  color: #84858E;
                  .block__loader {
                    position: static;
                    width: 23px;
                    height: 23px;
                  }
                }
                svg {
                  path {
                    transition: 0.2s;
                  }
                }
                &:hover {
                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                  span {
                    color: #fff
                  }
                }
              }
            }
            & > svg {
              path {
                transition: 0.2s;
              }
            }
            &:hover {
              & > svg {
                path {
                  fill: #fff;
                }
              }
            }
          }

        }
        .text {
          margin-top: 7px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          word-break: break-word;
          color: #DDDFEC;
          font-family: 'Inter', sans-serif;
        }
      }
    }
    .chat-message + .chat-message {
      margin-top: 18px;
    }
  }
  .chat-input {
    padding: 41px;
    @media (max-width: 992px) {
      padding: 20px;
    }
    .limits {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14px;
      }
      em {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }
    textarea {
      resize: none;
      width: 100%;
      outline: none;
      border: none;
      background: #24272C;
      border-radius: 6px;
      height: 84px;
      padding: 20px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-family: 'Inter', sans-serif;
      ::-webkit-scrollbar {
        width: 0;
      }
      &::placeholder {
        color: #82838A;
        font-family: 'Inter', sans-serif;
      }
    }
    button {
      display: flex;
      align-items: center;
      background: #2D5F5C;
      border-radius: 6px;
      margin-top: 16px;
      width: 100%;
      height: 58px;
      justify-content: center;
      font-weight: 800;
      font-size: 15px;
      font-family: "Manrope", sans-serif;
      line-height: 19px;
      img {
        margin-left: 15px;
      }
      transition: 0.2s;
      &:hover:not(.disabled) {
        background: #3B7E7A;
      }
      &.disabled {
        cursor: not-allowed;
        filter: grayscale(1);
      }
    }
  }
}
</style>