<template>
  <div class="bottom-upgrade" :class="{'without-bg': status !== 'wait'}">
    <button @click="startGame" v-if="status !== 'finish'" :disabled="!availableUpgrade || isLoading" :class="{'disabled': !availableUpgrade || isLoading}">
      <BlockLoader v-if="isLoading" />
      <span>Апгрейд</span>
<!--      <template v-if="status === 'process'">Апгрейдим...</template>-->
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6667 7.26655C12.6661 7.39839 12.6265 7.52712 12.5528 7.63644C12.4791 7.74576 12.3746 7.83078 12.2526 7.88073C12.1306 7.93068 11.9965 7.94332 11.8673 7.91707C11.7381 7.89081 11.6195 7.82683 11.5267 7.73321L8.47336 4.66654C8.41138 4.60405 8.33765 4.55446 8.25641 4.52061C8.17517 4.48676 8.08803 4.46934 8.00002 4.46934C7.91202 4.46934 7.82488 4.48676 7.74364 4.52061C7.6624 4.55446 7.58867 4.60405 7.52669 4.66654L4.47335 7.73321C4.41138 7.7957 4.33764 7.8453 4.25641 7.87914C4.17517 7.91299 4.08803 7.93041 4.00002 7.93041C3.91201 7.93041 3.82488 7.91299 3.74364 7.87914C3.6624 7.8453 3.58866 7.7957 3.52669 7.73321C3.40252 7.60831 3.33282 7.43934 3.33282 7.26321C3.33282 7.08709 3.40252 6.91812 3.52669 6.79321L6.58669 3.7332C6.96169 3.35867 7.47002 3.14829 8.00002 3.14829C8.53003 3.14829 9.03836 3.35867 9.41336 3.7332L12.4734 6.79321C12.5351 6.8555 12.584 6.92938 12.6172 7.01061C12.6504 7.09183 12.6672 7.17881 12.6667 7.26655Z" fill="white"/>
        <path d="M12.6667 11.9335C12.6661 12.0654 12.6265 12.1941 12.5528 12.3034C12.4791 12.4128 12.3746 12.4978 12.2526 12.5477C12.1306 12.5977 11.9965 12.6103 11.8673 12.5841C11.7381 12.5578 11.6195 12.4938 11.5267 12.4002L8.00002 8.87354L4.47335 12.4002C4.41138 12.4627 4.33764 12.5123 4.25641 12.5461C4.17517 12.58 4.08803 12.5974 4.00002 12.5974C3.91201 12.5974 3.82488 12.58 3.74364 12.5461C3.6624 12.5123 3.58866 12.4627 3.52669 12.4002C3.40252 12.2753 3.33282 12.1063 3.33282 11.9302C3.33282 11.7541 3.40252 11.5851 3.52669 11.4602L7.52669 7.46021C7.58867 7.39772 7.6624 7.34812 7.74364 7.31428C7.82488 7.28043 7.91202 7.26301 8.00002 7.26301C8.08803 7.26301 8.17517 7.28043 8.25641 7.31428C8.33765 7.34812 8.41138 7.39772 8.47336 7.46021L12.4734 11.4602C12.5351 11.5225 12.584 11.5964 12.6172 11.6776C12.6504 11.7588 12.6672 11.8458 12.6667 11.9335Z" fill="white"/>
      </svg>
    </button>
    <div class="buttons" v-else>
      <button class="reset" @click="resetGame">
        Попробовать ещё раз
        <img src="@/assets/icons/load.svg" alt="">
      </button>
      <button class="sell" @click="sellSkin">
        Продать за {{ parseFloat(winItem.open.price).toFixed(2) }}$
        <BlockLoader v-if="loader" />
        <img src="@/assets/icons/dollar.svg" alt="">
      </button>
    </div>

    <div class="guarantee" v-if="guarante && status === 'wait'">
      ГАРАНТИРОВАННЫЙ ПРИЗ - СТОИМОСТЬЮ ОТ
      {{ guarante }}
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
import {CREATE_BALANCE_UPGRADE, CREATE_ITEM_UPGRADE} from '@/queries/mutations'
import BlockLoader from "@/components/BlockLoader.vue";
export default {
  data () {
    return {
      loader: false
    }
  },
  components: {BlockLoader},
  methods: {
    ...mapMutations({
      setLoading: 'upgrade/setLoading',
      setWinItem: 'upgrade/setWinItem',
      setStatus: 'upgrade/setStatus'
    }),
    async sellSkin () {
      this.loader = true
      if (await this.sellSkins([this.winItem.open.id])) {
        await this.resetGame()
      }
      this.loader = false
    },
    ...mapActions({
      sellSkins: 'inventory/sellSkins',
      resetGame: 'upgrade/reset'
    }),
    async startGame () {
      if (!this.availableUpgrade) return;

      if (this.betType === 'balance') {
        await this.sendBalanceUpgrade()
      } else {
        await this.sendUpgrade()
      }
    },
    async sendBalanceUpgrade () {
      this.setLoading(true)

      await this.$apollo.mutate({
        mutation: CREATE_BALANCE_UPGRADE,
        variables: {
          sum: this.useSelectBalance,
          itemId: this.secondSkin.internal_id
        }
      }).then(async (data) => {
        await this.setWinItem(data.data.upgradeBalance)
        await this.setStatus('process')
      }).catch((error) => {
        // this.clearUpgradeState()
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
        this.setLoading(false)
      })
    },
    async sendUpgrade () {
      this.setLoading(true)
      await this.$apollo.mutate({
        mutation: CREATE_ITEM_UPGRADE,
        variables: {
          openId: this.firstSkin.internal_id,
          itemId: this.secondSkin.internal_id
        }
      }).then(async (data) => {
        await this.setWinItem(data.data.upgradeItem)
        await this.setStatus('process')
      }).catch((error) => {
        // this.clearUpgradeState()
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
        this.setLoading(false)
      })
    },
  },
  computed: {
    guarante () {
      const sum = this.betCost
      if (!sum) {
        return false
      }
      const from = parseFloat(sum * 0.1).toFixed(2)
      const to = parseFloat(sum * 3).toFixed(2)
      return `${from}$ ДО ${to}$`
    },
    ...mapGetters({
      betCost: 'upgrade/betCost',
      isLoading: 'upgrade/isLoading',
      useSelectBalance: 'upgrade/useSelectBalance',
      winItem: 'upgrade/winItem',
      status: 'upgrade/status',
      firstSkin: 'upgrade/firstSkin',
      secondSkin: 'upgrade/secondSkin',
      betType: 'upgrade/betType',
      availableUpgrade: 'upgrade/availableUpgrade'
    })
  }
}
</script>
<style scoped lang="scss">
.bottom-upgrade {
  margin-top: 30px;
  background: url(@/assets/images/upgrade-bottom-bg.png);
  background-size: cover;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  &.without-bg {
    background: transparent;
  }
  .guarantee {
    position: absolute;
    bottom: 20px;
    font-family: 'Inter', sans-serif;
    right: 80px;
    font-weight: 400;
    font-size: 15px;
    width: 360px;
    line-height: 18px;
    text-align: center;
    color: #717380;
    opacity: 0.5;
    @media (max-width: 1100px) {
      right: 0;
      font-size: 14px;
    }
    @media (max-width: 992px) {
      position: static;
      width: 100%;
      margin-top: 15px;
    }
  }
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
  min-height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background: #265FE7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    transition: 0.2s;
    position: relative;
    padding: 18px 32px;
    @media (max-width: 575px) {
      padding: 15px 25px;
      font-size: 14px;
    }
    svg {
      margin-left: 18px;
      min-width: 16px;
    }
    &:hover:not(.disabled) {
      background: #5781E3;
    }
    &.disabled {
      cursor: not-allowed;
      background: #262626;
    }
  }
  .buttons {
    display: flex;
    @media (max-width: 575px) {
      flex-wrap: wrap;
      margin-top: -100px;
      z-index: 999;
    }
    button.reset {
      transition: 0.2s;
      background: #AFA362;
      &:hover {
        background: #897F4B;
      }
    }
    button {
      @media (max-width: 575px) {
        padding: 13px 25px;
        font-size: 14px;
        justify-content: center;
        width: 100%;
      }
      img {
        margin-left: 16px;
      }
    }
    button.sell {
      position: relative;
      white-space: nowrap;
      background: #629E6F;
      &:hover {
        background: #3F8D50;
      }
    }
    button + button {
      margin-left: 47px;
      @media (max-width: 575px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

}
</style>