<template>
  <div class="home">
    <CaseGroup :key="category.id" :id="category.id" :cases="category.cases" v-for="category in categories" :title="category.name.ru" :color="category.color"></CaseGroup>
  </div>
</template>
<script>
import { GET_CATEGORIES } from "@/queries";

export default {
  apollo: {
    categories: {
      query: GET_CATEGORIES,
      update: data => data.categories
    }
  },
  components: {
    'CaseGroup': () => import('../components/case/CaseGroup.vue')
  }
}
</script>
<style scoped lang="scss">
.home {
  max-width: 100%;
  overflow: hidden;
  padding: 40px 120px;
  @media (max-width: 1600px) {
    padding: 40px;
  }
  @media (max-width: 768px) {
    padding: 15px;
    padding-bottom: 40px;
  }
  @media (max-width: 575px) {
    padding-top: 0;
  }
}
</style>