<template>
  <div class="sum-title">
    <span>{{ title }}</span>
  </div>
</template>
<script>
export default {
  props: ['title']
}
</script>
<style scoped lang="scss">
.sum-title {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  margin: 40px 0;
  @media (max-width: 575px) {
    margin: 30px 0;
  }
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media (max-width: 575px) {
      font-size: 14px;
    }
    &::before {
      width: 27px;
      margin-right: 23px;
      content: "";
      display: block;
      height: 1px;
      background: #26282F;
    }
    &::after {
      width: 3000px;
      margin-left: 23px;
      content: "";
      display: block;
      height: 1px;
      background: #26282F;
    }
  }
}
</style>