<template>
  <div class="contracts">
    <EmptyInventory title="Нет контрактов" subtitle="Начните создавать контракты" link="/contract" linktext="Создать" v-if="!contracts || !contracts.items.length" :is-public="false"></EmptyInventory>
    <div class="profile-container" v-else>
      <div class="contracts-wrapper">
        <div class="contract-item" v-for="contract in contracts.items" :key="contract.id">
          <div class="contract-item_top">
            <div class="bet">
              <BaseSkin :skin="{price: contract.open.price, internal_id: contract.open.id, ...contract.open.item}" />
            </div>
            <div class="items">
              <div class="item" :class="{'empty': !contract.items[step - 1]}" v-for="step in 10" :key="step">
                <BaseSkin :hide-info="true" v-if="contract.items[step - 1]" :hide-price="true" :skin="{price: contract.items[step - 1].open.price, internal_id: contract.items[step - 1].open.id, ...contract.items[step - 1].open.item}" />
              </div>
            </div>
          </div>
          <div class="contract-item_bottom">
            <div class="price">
              Цена контракта: <strong>{{ parseFloat(contract.sum).toFixed(2) }} $</strong>
            </div>
            <div class="count">
              Количество предметов: <strong>{{ contract.items.length }}</strong>
            </div>
          </div>
        </div>
      </div>

      <div class="inventory__load" @click="loadMore" :class="{'hide': !showMoreEnabled}">
        Показать ещё
        <img src="@/assets/icons/load.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  methods:{
    ...mapActions({
      loadMore: 'contracts/loadMore',
      getContracts: 'contracts/getContracts'
    })
  },
  computed: {
    ...mapGetters({
      showMoreEnabled: 'contracts/showMoreEnabled',
      contracts: 'contracts/contracts'
    })
  },
  mounted () {
    this.getContracts()
  }
}
</script>
<style scoped lang="scss">
.contracts {
  .contracts-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .contract-item {
      background: #17181C;
      border-radius: 10px;
      padding: 20px;
      .contract-item_top {
        width: 100%;
        display: flex;
        @media (max-width: 575px) {
          flex-wrap: wrap;
        }
        .bet {
          width: 130px;
          min-width: 130px;
          margin-right: 15px;
          @media (max-width: 575px) {
            min-width: 100%;
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
          }
          @media (min-width: 1100px) {
            ::v-deep {
              .inventory-item {
                height: 100%;
                .inventory-item__body {
                  .image {
                    height: 60px;
                  }
                }
              }
            }
          }
        }
        .items {
          flex-grow: 1;
          overflow: hidden;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 5px;
          @media (max-width: 1100px) {
            grid-template-columns: repeat(4, 1fr);
          }
          @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
          }
          @media (max-width: 850px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
          }
          @media (max-width: 400px) {
            grid-template-columns: repeat(3, 1fr);
          }
          .item {
            height: 84px;
            background: #1B1D21;
            border-radius: 6px;
            &.empty {
              display: flex;
              align-items: center;
              justify-content: center;
              background: url(@/assets/images/empty-contract.png) #1B1D21;
              background-size: 61px 31px;
              background-position: center center;
              background-repeat: no-repeat;
            }
            ::v-deep {
              .inventory-item {
                height: 100%;

                .inventory-item__body {
                  .image {
                    padding: 0 10px;
                    height: 100%;
                    margin-top: -5px;
                  }
                }
              }
            }
          }
        }
      }
      .contract-item_bottom {
        margin-top: 15px;
        background: #1B1D21;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        padding: 22px 20px;
        @media (max-width: 575px) {
          flex-wrap: wrap;
        }
        .price {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: #717380;
          strong {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: rgba(0, 184, 118, 1);
          }
        }
        .count {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: #717380;
          strong {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: rgba(194, 172, 91, 1)
          }
        }
        @media (max-width: 575px) {
          .count, .price {
            width: 100%;
            text-align: center;
          }
          .count {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .inventory__load {
    background: rgba(36, 39, 44, 0.8);
    border-radius: 6px;
    padding: 18px 23px;
    display: flex;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    font-size: 16px;
    margin: 0 auto;
    width: fit-content;
    transition: 0.2s;
    line-height: 22px;
    margin-top: 30px;
    img {
      margin-left: 15px;
    }
    &:hover {
      background: #24272C;
    }
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  }
}
</style>
<script setup>
import BaseSkin from "@/components/BaseSkin.vue";
import EmptyInventory from "@/components/profile/EmptyInventory.vue";
</script>